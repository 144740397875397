<template>
	<div>
		<!-- Actions -->
		<template v-if="cellField === 'actions'">
			<v-menu anchor="bottom">
				<template #activator="{ props }">
					<!--  TODO: Use AButtonIcon component -->
					<v-btn v-bind="props" variant="text" icon :disabled="loading">
						<v-icon :icon="icons['table_options']" />
					</v-btn>
				</template>
				<AList :items="actionItems" @click="onClick" />
			</v-menu>

			<!-- ID -->
			<!-- <div v-if="items.length" :id="'documents_uuid_' + (items.length - getItemIndex(item))" class="d-none" v-text="item.uuid" /> -->
		</template>

		<!-- Name -->
		<template v-else-if="cellField === 'file_name'">
			{{ item.file_name || item.document_name }}
		</template>

		<!-- Status -->
		<template v-else-if="cellField === 'status'">
			<v-tooltip location="bottom">
				<template v-slot:activator="{ props }">
					<v-icon v-bind="props" size="large" :icon="icons[item[cellField]] || 'mdi-file'" :color="getFileStatusColor(item[cellField])" />
				</template>
				{{ UTIL.valuesTranslation('status', item[cellField] || 'without_signature') }}
			</v-tooltip>
		</template>

		<!-- Dates -->
		<template v-else-if="['last_status_at', 'last_email_sent_at'].includes(cellField)">
			{{ UTIL.formatDateLong(item[cellField]) }}
		</template>

		<!-- Values -->
		<template v-else>
			{{ item[cellField] }}
		</template>
	</div>
</template>

<script>
	import AList from '@/components/atoms/AList.vue'

	import * as UTIL from '@/util'

	import { defineComponent, getCurrentInstance, reactive, computed } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'DocumentationCellRenderer',
		components: {
			AList
		},
		setup() {
			const instance = getCurrentInstance()
			const props = instance.props

			const state = reactive({
				tabSelected: 'documentation',
				item: {}
			})

			const { $t, $store, icons, loading } = useGeneral()
			const {
				SEND_EMAIL_COPY_VISIBLE,
				SEND_EMAIL_COPY_DISABLED,
				SEND_FOR_SIGN_VISIBLE,
				SEND_FOR_SIGN_DISABLED,
				ATTACH_VISIBLE,
				ATTACH_DISABLED,
				DOWNLOAD_VISIBLE,
				DOWNLOAD_DISABLED,
				DELETE_VISIBLE,
				DELETE_DISABLED
			} = useRole(state.tabSelected)

			const params = computed(() => props.params || {})
			// const tabSelected = computed(() => params.value?.tabSelected || '')
			const cellField = computed(() => params.value.colDef?.field || '')
			const editable = computed(() => params.value.colDef?.editable || false)
			const item = computed(() => params.value.data || {})
			const documentStatus = computed(() => item.value?.status || '')
			const documentType = computed(() => params.value?.documentType || 'additional')
			const tabSelectedItem = computed(() => params.value?.tabSelected || '')

			const entityId = computed(() => params.value?.entityId || '')
			const person = computed(() => params.value?.person || {})
			const personEmail = computed(() => person.value?.email || '')
			const personPhoneNumber = computed(() => person.value?.phone_number || '')

			const showSendEmailCopy = computed(() => documentStatus.value === 'signed')
			const showSendForSign = computed(() => documentStatus.value === 'pending_signature')
			const showSendForRead = computed(() => ['pending_signature', 'without_signature'].includes(documentStatus.value))
			const showAttach = computed(() => !['signed', 'without_signature'].includes(documentStatus.value) && documentType.value != 'additional')
			const showDownload = computed(() => !!item.value.associated_file_id && documentStatus.value !== 'pending_upload')
			const showDelete = computed(() => !!item.value.associated_file_id && documentStatus.value !== 'pending_upload')

			function getFileStatusColor(status) {
				const status_color = $store.state.associated_files.status_color
				return status_color[status] || ''
			}

			function init() {
				if (params.value.init instanceof Function) {
					params.value.init()
				}
			}

			function attachDocument(document) {
				if (params.value.attachDocument instanceof Function) {
					params.value.attachDocument(document)
				}
			}

			async function sendDocumentEmailCopy() {
				loading.value = true
				try {
					const body = {
						title: item.value.document_name,
						message: item.value.document_name,
						person_ids: [entityId.value],
						associated_files_ids: [item.value.associated_file_id]
					}
					await $store.dispatch('associated_files/sendEmail', body)
				} catch (error) {
					$store.dispatch('alert/SET_ALERT', { text: $t('Document_sent_succesfully'), error })
				} finally {
					loading.value = false
				}
			}

			async function sendSignDocumentEmail() {
				loading.value = true
				try {
					const body = {
						personId: entityId.value,
						documentId: item.value.document_definition_id || item.value.associated_file_id,
						documentName: item.value.document_name || item.value.file_name
					}
					await $store.dispatch('persons/sendSignDocument', body)
					init()
				} catch (error) {
					$store.dispatch('alert/SET_ALERT', { text: $t('Document_sent_succesfully'), error })
				} finally {
					loading.value = false
				}
			}

			async function sendSignDocumentWhatsApp() {
				loading.value = true
				try {
					const body = {
						phone_prefix: person.value.phone_prefix,
						phone_number: person.value.phone_number,
						person_id: person.value.person_id,
						person_name: person.value.person_name,
						documentId: item.value.document_definition_id
					}
					await $store.dispatch('associated_files/sendWhatsApp', body)
					init()
				} catch (error) {
					$store.dispatch('alert/SET_ALERT', { text: $t('Document_sent_succesfully'), error })
				} finally {
					loading.value = false
				}
			}

			function sendReadDocument() {
				$store.dispatch('persons/sendReadDocument', item.value)
			}

			async function downloadDocument() {
				loading.value = true
				try {
					const body = {
						tabSelected: tabSelectedItem.value,
						itemId: entityId.value,
						documentId: item.value.associated_file_id
					}
					await $store.dispatch('associated_files/download', body)
					init()
				} catch (error) {
					$store.dispatch('alert/SET_ALERT', { tab: 'documents', action: 'download', error })
				} finally {
					loading.value = false
				}
			}

			async function deleteDocument() {
				loading.value = true
				try {
					const body = {
						tabSelected: tabSelectedItem.value,
						itemId: entityId.value,
						documentId: item.value.associated_file_id
					}
					await $store.dispatch('associated_files/delete', body)
					init()
				} catch (error) {
					$store.dispatch('alert/SET_ALERT', { tab: 'documents', action: 'delete', error })
				} finally {
					loading.value = false
				}
			}

			const actionItems = computed(() => {
				const items = []
				// Send Email Copy
				if (showSendEmailCopy.value && SEND_EMAIL_COPY_VISIBLE.value) {
					items.push({
						text: 'Action_send_email_copy',
						icon: icons['email'],
						disabled: !personEmail.value || SEND_EMAIL_COPY_DISABLED.value,
						value: sendDocumentEmailCopy
					})
				}
				// Send For Sign Email
				if (showSendForSign.value && SEND_FOR_SIGN_VISIBLE.value) {
					items.push({
						text: 'Action_send_for_sign',
						icon: icons['email'],
						disabled: !personEmail.value || SEND_FOR_SIGN_DISABLED.value,
						value: sendSignDocumentEmail
					})
				}
				// Send For Sign WhatsApp
				if (showSendForSign.value && SEND_FOR_SIGN_VISIBLE.value) {
					items.push({
						text: 'Action_send_for_sign',
						icon: icons['whatsapp'],
						disabled: !personPhoneNumber.value || SEND_FOR_SIGN_DISABLED.value,
						value: sendSignDocumentWhatsApp
					})
				}
				// Send For Sign Read
				// if (showSendForRead.value && SEND_FOR_READ_VISIBLE.value) {
				// 	items.push({
				// 		text: 'Action_send_for_read',
				// 		icon: icons['email'],
				// 		disabled: !personEmail.value || SEND_FOR_READ_DISABLED.value,
				// 		value: sendSignDocumentWhatsApp
				// 	})
				// }
				// Attach
				if (showAttach.value && ATTACH_VISIBLE.value) {
					items.push({
						text: 'Action_attach',
						icon: icons['attachment'],
						disabled: ATTACH_DISABLED.value,
						value: attachDocument
					})
				}
				// Download
				if (showDownload.value && DOWNLOAD_VISIBLE.value) {
					items.push({
						text: 'Action_download',
						icon: icons['download'],
						disabled: DOWNLOAD_DISABLED.value,
						value: downloadDocument
					})
				}
				// Delete
				if (showDelete.value && DELETE_VISIBLE.value) {
					items.push({
						text: 'Action_delete',
						icon: icons['delete'],
						disabled: DELETE_DISABLED.value,
						value: deleteDocument
					})
				}
				return items
			})

			function onClick(event) {
				event(item.value)
			}

			return {
				UTIL,
				icons,
				loading,
				item,
				cellField,
				personEmail,
				personPhoneNumber,
				getFileStatusColor,
				showSendEmailCopy,
				sendDocumentEmailCopy,
				SEND_EMAIL_COPY_VISIBLE,
				SEND_EMAIL_COPY_DISABLED,
				showSendForSign,
				SEND_FOR_SIGN_VISIBLE,
				SEND_FOR_SIGN_DISABLED,
				sendSignDocumentEmail,
				sendSignDocumentWhatsApp,
				showAttach,
				ATTACH_VISIBLE,
				ATTACH_DISABLED,
				attachDocument,
				showDownload,
				DOWNLOAD_VISIBLE,
				downloadDocument,
				showDelete,
				DELETE_VISIBLE,
				deleteDocument,
				actionItems,
				onClick
			}
		}
	})
</script>
