import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_TheAlert = _resolveComponent("TheAlert")!
  const _component_TheLoader = _resolveComponent("TheLoader")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, { name: "drawer" }),
      _createVNode(_component_router_view, { name: "header" }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_router_view, { name: "footer" }),
      _createVNode(_component_TheAlert),
      _createVNode(_component_TheLoader)
    ]),
    _: 1
  }))
}