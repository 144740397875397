<template>
	<v-switch
		:label="$t(label)"
		v-model="state.item"
		:color="baseColor"
		:density="$props.density"
		:disabled="$props.disabled"
		hide-details
		@change="onUpdateValue()"
	></v-switch>
</template>

<script setup>
	import { defineEmits, defineProps, reactive, watch } from 'vue'
	import { useGeneral, useInput } from '@/hooks'

	const $emit = defineEmits(['update:modelValue'])
	const $props = defineProps({
		modelValue: { type: [Boolean, Object], default: null },
		keyName: { type: String, default: '' },
		label: { type: String, default: '' },
		density: { type: String, default: 'default' },
		disabled: { type: Boolean, default: false }
	})

	const state = reactive({
		item: null
	})

	const { $t, checkProxyValue } = useGeneral()
	const { label, baseColor } = useInput($props, state)

	function onUpdateValue() {
		$emit('update:modelValue', state.item)
	}

	watch(
		() => $props.modelValue,
		val => (state.item = checkProxyValue(val, null))
	)

	function init() {
		const value = checkProxyValue($props.modelValue, null)
		state.item = value
	}
	init()
</script>
