'use strict'
import type { ObjectContext } from '@/global'

// import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { i18n } from '@/plugins/vuetify-i18n'
const $t = i18n.global.t

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const config = {
	baseURL: process.env.VUE_APP_BASE_URL || ''
	// timeout: 60 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

function getToken() {
	return localStorage.getItem('token') || ''
}

_axios.interceptors.request.use(
	(config: ObjectContext) => {
		const allowed_urls = ['login', 'signup', 'signup_v2', 'notify']
		// Do something before request is sent
		const token = getToken()

		if (token || allowed_urls.includes(config.url)) {
			config.headers.common['authorization'] = getToken()
			// TODO: Send locale and time_zone headers
			// config.headers.common['locale'] = i18n.locale
			// config.headers.common['time_zone'] = moment.tz.guess()
			return config
		} else {
			const CancelToken = axios.CancelToken
			return {
				...config,
				cancelToken: new CancelToken((cancel: any) => cancel('No token found'))
			}
		}
	},
	error => {
		// Do something with request error
		const message: string = error?.message
		if (message === 'USER_DISABLED') {
			store.dispatch('auth/logout')
			store.dispatch('alert/SET_ALERT', { text: $t('Error_user_not_active_html') })
		}

		return Promise.reject(error)
	}
)

// Add a response interceptor
_axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response
	},
	function (error) {
		// Do something with response error
		return Promise.reject(error)
	}
)

// Plugin.install = function(Vue, options) {
//   Vue.axios = _axios
//   window.axios = _axios
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios
//       }
//     },
//     $axios: {
//       get() {
//         return _axios
//       }
//     },
//   })
// }

// Vue.use(Plugin)

export default _axios
