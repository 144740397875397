<template>
	<v-list :density="currentDensity">
		<v-list-item
			v-for="(item, idx) in $props.items"
			:key="idx"
			:title="$t(item.text)"
			:prepend-icon="item.icon"
			:prepend-avatar="item.avatar"
			:disabled="item.disabled"
			:value="item.value || idx"
			@click="onClick(item.value)"
		/>
	</v-list>
</template>

<script setup>
	import { defineEmits, defineProps, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	const { $t, density } = useGeneral()

	const $emit = defineEmits(['click'])
	const $props = defineProps({
		density: { type: String, default: 'comfortable' },
		items: { type: Array, default: () => [] }
	})

	const currentDensity = computed(() => $props.density || density.value)

	function onClick(val) {
		if (val) $emit('click', val)
	}
</script>
