
	import TheAlert from '@/components/TheAlert.vue'
	import TheLoader from '@/components/TheLoader.vue'

	import { defineComponent } from 'vue'

	export default defineComponent({
		name: 'App',
		// setup() {}
		components: { TheAlert, TheLoader }
	})
