import type { ObjectContext } from '@/global'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'
import useGeneral from './general'

export default function (state?: ObjectContext) {
	const { $store, $router, $route, tabSelected, loading } = useGeneral()

	const pagination: ComputedRef = computed(() => $store.getters['GET_PAGINATION'])

	const items: ComputedRef = computed(() => $store.getters['GET_ITEMS'])
	const menuItems: ComputedRef = computed(() => {
		let menu = ''
		if ($route.name === 'records') menu = 'MENU_RECORDS'
		if ($route.name === 'sustainability') menu = 'MENU_SUSTAINABILITY'
		if ($route.name === 'config') menu = 'MENU_CONFIG'
		return $store.getters[`roles/${menu}`]
	})
	const menuTabs: ComputedRef = computed(() => {
		return menuItems.value.map((e: ObjectContext) => {
			return { text: e.title, value: e.tab, icon: e.icon }
		})
	})

	async function getData(initPaginationAndSort?: boolean) {
		loading.value = true
		if (initPaginationAndSort) {
			$store.commit('INIT_PAGINATION')
			$store.commit('filters/INIT_SORT_COLUMNS')
		}
		try {
			const body = {
				return_total_rows_count: true
			}
			await $store.dispatch('getData', body)
		} finally {
			loading.value = false
		}
	}

	function getValidTab(val: string | undefined) {
		let tab = ''
		if (menuItems.value) {
			tab = menuItems.value[0]?.tab
			if (val) {
				const isValidTab = menuItems.value.some((e: ObjectContext) => e.tab === val)
				if (isValidTab) tab = val
			}
		}
		return tab
	}

	function setTab(val: string, setTabSelected?: boolean, initPaginationAndSort?: boolean) {
		// @ts-ignore
		const routeName: string = $route.name || 'home'
		$router.push({ name: routeName, params: { tab: val } })
		if (state) state.tab = val
		if (setTabSelected) {
			tabSelected.value = val
			getData(initPaginationAndSort)
		}
	}

	async function changeTab(val: string, setTabSelected?: boolean, initPaginationAndSort?: boolean) {
		const tab: string = await getValidTab(val)
		setTab(tab, setTabSelected, initPaginationAndSort)
	}

	function init(initPaginationAndSort?: boolean) {
		// @ts-ignore
		const tab: string = $route.params.tab
		changeTab(tab, true, initPaginationAndSort)
	}

	return {
		pagination,
		items,
		menuItems,
		menuTabs,
		getData,
		getValidTab,
		setTab,
		changeTab,
		init
	}
}
