<template>
	<ALoader v-if="state.loading" />
	<template v-else>
		<div v-if="!state.items.length || loading" class="pa-4">
			{{ $t('General_no_data_available') }}
		</div>
		<v-expand-transition>
			<AgDatatable v-if="state.items.length" :headers="headers" :items="state.items" auto-height @gridReady="onGridReady($event)" />
		</v-expand-transition>
	</template>

	<!-- <v-expand-transition>
		<v-row v-if="state.items.length && !loading">
			<v-col cols="3">
				<AgDatatable :headers="headersInput" :items="itemsInput" auto-height />
			</v-col>
		</v-row>
	</v-expand-transition> -->

	<AButtonGridAdd v-if="EDIT_VISIBLE" element="General_machine" :disabled="state.loading || EDIT_DISABLED" @click="onAddItem()" />

	<ODialog v-model="state.dialog" small icon-color="success" :icon="icons['create_alt']" :title="dialogTitle" @submit="onAddItem(true)">
		<v-form ref="refForm">
			<MDynamicForm :modelValue="state.newItem" :cols="12" :xl="12" :keys="state.columns" :tab-selected="state.table_name" />
		</v-form>
	</ODialog>
</template>

<script setup>
	import ALoader from '@/components/atoms/ALoader.vue'
	import AButtonGridAdd from '@/components/atoms/AButtonGridAdd.vue'
	import MDynamicForm from '@/components/molecules/MDynamicForm.vue'
	import ODialog from '@/components/organism/ODialog.vue'
	import AgDatatable from '@/components/datatable/AgDatatable.vue'
	import DeleteCellRenderer from '@/components/datatable/cellRenderers/DeleteCellRenderer.vue'
	import DefaultCellRenderer from '@/components/datatable/cellRenderers/DefaultCellRenderer.vue'
	import DefaultCellEditor from '@/components/datatable/cellEditors/DefaultCellEditor.vue'

	import { defineProps, ref, reactive, computed, watch } from 'vue'
	import { useGeneral, useRole, useDatatable } from '@/hooks'

	const $props = defineProps({
		item: { type: Object, default: null, required: true }
	})

	const state = reactive({
		gridApi: null,
		loading: false,
		dialog: false,
		tabSelected: 'warehouse_evaluations',
		item: {},
		items: [],
		table_name: 'warehouse_evaluation_machines',
		columns: ['warehouse_evaluation_machine_name', 'warehouse_evaluation_machine_power', 'warehouse_evaluation_machine_usage'],
		newItem: {}
	})

	const { $t, $store, icons, mainClick } = useGeneral()
	const { suppressEnter, valueSetter, isCellEditable } = useDatatable()
	const { EDIT_VISIBLE, EDIT_DISABLED } = useRole(state.tabSelected)

	function onGridReady(params) {
		state.gridApi = params.gridApi
	}
	watch(mainClick, () => {
		if (state.gridApi) state.gridApi.stopEditing()
	})

	const warehouse_evaluation_id = computed(() => $props.item.warehouse_evaluation_id)

	const dialogTitle = computed(() => $t('Action_add') + ' ' + $t('General_machine'))

	async function init() {
		if ($props.item) {
			state.loading = true
			try {
				const items = await $store.dispatch(`${state.table_name}/getList`, warehouse_evaluation_id.value)
				state.items = JSON.parse(JSON.stringify(items))
			} finally {
				state.loading = false
			}
		}
	}
	init()

	watch(
		() => $props.item,
		() => init()
	)

	const refForm = ref(null)

	async function onAddItem(submit) {
		if (!submit) {
			state.newItem = {}
			state.dialog = !state.dialog
		} else {
			// @ts-ignore
			const res = await refForm.value.validate()
			let isValid = res.valid
			state.columns.forEach(key => {
				if (!state.newItem[key]) isValid = false
			})
			if (!isValid) $store.dispatch('alert/SET_ALERT', { text: $t('Form_missing_required') })
			else {
				state.loading = true
				try {
					const body = Object.assign({}, state.newItem)
					body.warehouse_evaluation_id = warehouse_evaluation_id.value
					body.warehouse_evaluation_application_type = $props.type
					await $store.dispatch(`${state.table_name}/create`, body)
					init()
				} catch (error) {
					state.loading = false
					$store.dispatch('alert/SET_ALERT', { error })
				} finally {
					state.dialog = false
				}
			}
		}
	}

	async function deleteClick(index) {
		const item = state.items[index]
		console.log(`🚀 ~ deleteClick ~ item`, item)
		state.loading = true
		try {
			await $store.dispatch(`${state.table_name}/delete`, item)
			init()
		} catch (error) {
			state.loading = false
			$store.dispatch('alert/SET_ALERT', { error })
		}
	}

	const columns = computed(() => {
		const items = JSON.parse(JSON.stringify(state.columns))
		if (EDIT_VISIBLE.value) items.unshift('actions')
		return items
	})

	const headers = computed(() => {
		// @ts-ignore
		return columns.value.map(key => {
			const obj = {
				field: key,
				headerName: key,
				editable: isCellEditable(key, state.table_name),
				sortable: false,
				resizable: false,
				suppressMovable: true,
				width: key === 'actions' ? 100 : '',
				pinned: key === 'actions' ? 'left' : '',
				cellEditor: DefaultCellEditor,
				cellRenderer: key === 'actions' ? DeleteCellRenderer : DefaultCellRenderer,
				cellEditorParams: {
					tabSelected: state.table_name
				},
				cellRendererParams: {
					items: state.items,
					deleteClick
				},
				suppressKeyboardEvent: params => suppressEnter(params),
				valueSetter: params => valueSetter(params, state.table_name)
			}
			if (key === 'is_outsourced') delete obj.suppressKeyboardEvent
			return obj
		})
	})

	const itemsInput = computed(() => [state.item])

	const headersInput = computed(() => {
		const keys = ['warehouse_evaluation_no_productive_year_percentage']
		// @ts-ignore
		return keys.map(key => {
			return {
				field: key,
				headerName: key,
				editable: isCellEditable(key, state.tabSelected),
				sortable: false,
				resizable: false,
				suppressMovable: true,
				width: key === 'actions' ? 100 : '',
				pinned: key === 'actions' ? 'left' : '',
				cellEditor: DefaultCellEditor,
				cellRenderer: key === 'actions' ? DeleteCellRenderer : DefaultCellRenderer,
				cellEditorParams: {
					tabSelected: state.table_name
				},
				cellRendererParams: {
					items: state.items,
					deleteClick
				},
				suppressKeyboardEvent: params => suppressEnter(params),
				valueSetter: params => valueSetter(params, state.tabSelected)
			}
		})
	})
</script>
