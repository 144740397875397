import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		itemSelected: {},
		step: 1,
		stepCount: 1,
		stepCountDefault: {
			warehouse_evaluations: 3
		},
		formTitle: '',
		formTitleKeys: {
			groups: 'group_name',
			persons: 'person_name',
			machines: 'machine_name',
			customers: 'customer_name',
			action_definitions: 'action_definition_name',
			warehouses: 'warehouse_name',
			warehouse_evaluations: 'warehouse_evaluation_name'
		}
	},
	getters: {
		GET_ITEM_SELECTED(state: VuexStateContext) {
			return Object.assign({}, state.itemSelected)
		},
		GET_STEP(state: VuexStateContext) {
			return state.step
		},
		GET_STEP_COUNT(state: VuexStateContext) {
			return state.stepCount
		},
		IS_LAST_STEP(state: VuexStateContext) {
			return state.step === state.stepCount
		},
		GET_FORM_TITLE(state: VuexStateContext) {
			return state.formTitle
		},
		GET_FORM_TITLE_KEYS(state: VuexStateContext) {
			return state.formTitleKeys
		}
	},
	mutations: {
		SET_ITEM_SELECTED(state: VuexStateContext, value: ObjectContext) {
			state.itemSelected = Object.assign({}, value)
		},
		INIT_STEPS(state: VuexStateContext, params: ObjectContext) {
			state.step = params.step
			state.stepCount = params.stepCount
		},
		SET_STEP(state: VuexStateContext, value: number) {
			state.step = value
		},
		SET_FORM_TITLE(state: VuexStateContext, value: string) {
			state.formTitle = value
		}
	},
	actions: {
		setItemSelected({ commit }: VuexActionContext, value: ObjectContext) {
			commit('SET_ITEM_SELECTED', value)
		},
		setFormTitle({ commit }: VuexActionContext, value: string) {
			commit('SET_FORM_TITLE', value)
		},
		setStep({ commit }: VuexActionContext, value: number) {
			commit('SET_STEP', value)
		},
		initSteps({ state, commit }: VuexActionContext, params: ObjectContext) {
			const tabSelected = params.tabSelected
			const obj = {
				step: 1,
				stepCount: 1
			}
			if (params.step) obj.step = params.step
			if (params.stepCount || state.stepCountDefault[tabSelected]) obj.stepCount = params.stepCount || state.stepCountDefault[tabSelected]
			commit('INIT_STEPS', obj)
		}
	}
}
