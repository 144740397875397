import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async get({ dispatch }: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params
			}
			delete body.tabSelected
			// @ts-ignore
			const res = await this.$axios.post(`report/${params.tabSelected}`, body)
			const data = res.data.response_json
			const url = data.download_url

			const a = document.createElement('a')
			a.href = url
			a.setAttribute('target', '_blank')
			a.click()

			return url
		}
	}
}
