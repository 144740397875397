import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-wrap",
  style: {"max-width":"250px"}
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_hover, null, {
    default: _withCtx(({ props }) => [
      _createElementVNode("span", _mergeProps(props, { style: {"width":"100%"} }), [
        (_ctx.cellField !== 'key' && _ctx.datetimeKeys.includes(_ctx.optionalKey))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.UTIL.formatDateLong(_ctx.item[_ctx.cellField])), 1)
            ], 64))
          : (_ctx.isKeyToParse)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.valueParsed, (e, idx) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (idx < 3)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        class: "my-1 mr-1",
                        label: "",
                        small: "",
                        light: "",
                        key: e.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.UTIL.valuesTranslation(_ctx.cellField, e)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (idx === 3)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        class: "mb-1",
                        label: "",
                        small: "",
                        light: "",
                        key: e.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString('+' + (_ctx.value.length - 3) + ' ' + _ctx.$t('General_others')), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ], 64))
              }), 256))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (typeof _ctx.value === 'boolean')
                  ? (_openBlock(), _createBlock(_component_v_chip, {
                      key: 0,
                      class: _normalizeClass(_ctx.value ? 'text-success' : 'text-error'),
                      label: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.UTIL.checkBoolean(_ctx.cellField, _ctx.value)), 1)
                      ]),
                      _: 1
                    }, 8, ["class"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.value === 0 ? 0 : _ctx.UTIL.formatTableValue(_ctx.cellField, _ctx.value)), 1))
              ]))
      ], 16)
    ]),
    _: 1
  }))
}