
	import * as UTIL from '@/util'

	import { ObjectContext } from '@/global'

	import { defineComponent, getCurrentInstance, reactive, computed } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'DefaultCellRenderer',
		setup() {
			const instance = getCurrentInstance()
			const props = instance?.props || {}

			const { $t, $store, icons, loading, dictionary, tabSelected, checkProxyValue } = useGeneral()
			const { COSTS_VISIBLE } = useRole(tabSelected.value)

			const state = reactive({
				show: false,
				items: []
			})

			const datetimeKeys = computed(() => Object.values($store.getters['dictionary/GET_DATETIME'][tabSelected.value] || {}))
			const optionalsKeys = computed(() => Object.values($store.getters['dictionary/GET_OPTIONALS'][tabSelected.value] || {}))

			const params: ObjectContext = computed(() => props.params || {})
			const isCellEditor = computed(() => params.value.colDef?.editable || false)
			const editable = computed(() => params.value.colDef?.editable || false)
			const item = computed(() => params.value.data || {})
			const cellField = computed(() => params.value.colDef?.field || '')
			const value = computed(() => item.value[cellField.value])

			const optionalKey = computed(() => item.value['key'])
			const mainItem = computed(() => params.value.mainItem || {})

			const keyToParse: ObjectContext = {
				role_id: 'role',
				customer_id: 'customer',
				customer_ids: 'customers',
				person_id: 'person',
				person_ids: 'persons',
				default_group_id: 'default_group',
				group_id: 'group',
				group_ids: 'groups',
				action_defintion_id: 'action_defintion',
				action_defintion_ids: 'action_defintions',
				machine_id: 'machine',
				machine_ids: 'machines',
				field_id: 'field',
				field_ids: 'fields'
			}

			const keyToParseLabel: ObjectContext = {
				role: 'role_name',
				customer: 'customer_name',
				customers: 'customer_name',
				person: 'person_name',
				persons: 'person_name',
				default_group: 'group_name',
				group: 'group_name',
				groups: 'group_name',
				action_defintion: 'action_defintion_name',
				action_defintions: 'action_defintion_name',
				machine: 'machine_name',
				machines: 'machine_name',
				field: 'field_label_id',
				fields: 'field_label_id'
			}

			const keysToParse = Object.keys(keyToParse)
			const isKeyToParse = computed(() => cellField.value !== 'key' && keysToParse.includes(optionalKey.value))

			const valueParsed = computed(() => {
				let result = []
				if (isKeyToParse.value) {
					const value = checkProxyValue(item.value[cellField.value], null)
					if (value !== null) {
						const key: string = keyToParse[optionalKey.value]
						const label: string = keyToParseLabel[key]
						const valueToParse = checkProxyValue(mainItem.value[key], null)
						if (valueToParse !== null) {
							result = Array.isArray(valueToParse) ? valueToParse.map(e => e[label]) : [valueToParse[label]]
						}
					}
				}
				return result
			})

			function deleteItem(item: ObjectContext) {
				if (params.value.deleteItem instanceof Function) {
					params.value.deleteItem(item)
				}
			}

			function getItemIndex(item: ObjectContext) {
				return UTIL.getItemIndex(item, state.items)
			}

			function startEditing() {
				params.value.api.startEditingCell({ rowIndex: params.value.rowIndex, colKey: cellField.value })
			}

			return {
				UTIL,
				datetimeKeys,
				t: $t,
				icons,
				loading,
				state,
				isCellEditor,
				cellField,
				editable,
				item,
				value,
				optionalKey,
				isKeyToParse,
				valueParsed,
				deleteItem,
				startEditing
			}
		}
	})
