// @ts-nocheck
import type { ObjectContext, VuexStateContext } from '@/global'
import { i18n } from '@/plugins/vuetify-i18n'
const $te: any = i18n.global.te

function getTabTitle(str: string) {
	const route = `Route_${str}`
	const general = `General_${str}`
	return $te(route) ? route : $te(general) ? general : str
}

function getTabElement(str: string) {
	const general = `General_${str}`
	return $te(general) ? general : str
}

export default {
	namespaced: true,
	state: {
		main_modules: {
			records: ['fields', 'controls', 'observations', 'harvest_planification', 'harvest', 'tasks', 'applications', 'irrigations', 'group_reports'],
			config: [
				'customers',
				'campaigns',
				'phytosanitaries',
				'fertilizers',
				'other_products',
				'machines',
				'alarms',
				'persons',
				'documentation',
				'action_definitions',
				'groups',
				'warehouses'
			],
			sustainability: ['warehouse_evaluations']
		},
		menu: {
			records: ['group_reports'],
			config: [
				// 'persons',
				// 'documents',
				// 'groups',
				// 'action_definitions',
				// 'machines',
				'warehouses',
				'customers'
			],
			sustainability: ['warehouse_evaluations'],
			sidebar: [
				// { route: 'records' },
				{ route: 'sustainability' },
				{ route: 'config' }
			],
			element: {
				// Records
				group_reports: 'group_report',
				// Config
				persons: 'person',
				documents: 'document',
				groups: 'group',
				action_definitions: 'action_definition',
				machines: 'machine',
				customers: 'customer',
				warehouses: 'warehouse',
				warehouse_evaluations: 'warehouse_evaluation'
			},
			icon: {
				// Records
				records: 'mdi-clipboard-edit',
				group_reports: 'mdi-face-recognition',
				// Config
				config: 'mdi-cog',
				persons: 'mdi-account',
				documents: 'mdi-bookmark',
				groups: 'mdi-account-group',
				action_definitions: 'mdi-clipboard-list',
				machines: 'mdi-tractor',
				customers: 'mdi-domain',
				// Sustainability
				sustainability: 'mdi-leaf',
				warehouses: 'mdi-warehouse',
				warehouse_evaluations: 'mdi-warehouse'
			}
		}
	},
	getters: {
		GET_MAIN_MODULES(state: VuexStateContext) {
			return state.main_modules
		},
		GET_TAB_ELEMENTS(state: VuexStateContext) {
			return state.menu.element
		},
		MENU_SIDEBAR(state: VuexStateContext) {
			return state.menu.sidebar.map((e: ObjectContext) => {
				const obj: ObjectContext = {
					...e
				}
				obj.title = getTabTitle(e.route)
				obj.element = getTabElement(state.menu.element[e.route] || e.route)
				obj.icon = state.menu.icon[e.tab || e.route] || ''
				return obj
			})
		},
		MENU_RECORDS(state: VuexStateContext) {
			return state.menu.records.map((e: string) => {
				const obj: ObjectContext = {
					tab: e
				}
				obj.title = getTabTitle(e)
				obj.element = getTabElement(state.menu.element[e] || e)
				obj.icon = state.menu.icon[e] || ''
				return obj
			})
		},
		MENU_SUSTAINABILITY(state: VuexStateContext) {
			return state.menu.sustainability.map((e: string) => {
				const obj: ObjectContext = {
					tab: e
				}
				obj.title = getTabTitle(e)
				obj.element = getTabElement(state.menu.element[e] || e)
				obj.icon = state.menu.icon[e] || ''
				return obj
			})
		},
		MENU_CONFIG(state: VuexStateContext) {
			return state.menu.config.map((e: string) => {
				const obj: ObjectContext = {
					tab: e
				}
				obj.title = getTabTitle(e)
				obj.element = getTabElement(state.menu.element[e] || e)
				obj.icon = state.menu.icon[e] || ''
				return obj
			})
		},
		// Roles
		roles(state, getters, rootState) {
			return (rootState.auth.user && rootState.auth.user.authorizations) || {}
		},
		ACTION_PERMISSIONS:
			(state, getters) =>
			(tabSelected = '', action = '', associatedFiles = false, mainModule = '') => {
				const currentTab = tabSelected === 'signings' ? 'group_reports' : tabSelected
				if (!mainModule) mainModule = currentTab
				Object.keys(state.main_modules).forEach(module_key => {
					const submodules = state.main_modules[module_key]
					if (submodules.includes(currentTab)) mainModule = module_key
				})
				let tab = mainModule + '_' + currentTab + '_'
				const roles = getters.roles
				if (associatedFiles) tab += 'associatedFiles_'
				tab += action.toLowerCase().trim()
				const type = (roles[tab] || '').toUpperCase().trim()
				return {
					VISIBLE: type !== 'INVISIBLE',
					DISABLED: type === 'DISABLED',
					PREMIUM: type === 'PREMIUM'
				}
			},
		MODULE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'MAIN', false, mainModule)
		},
		VIEW_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'VIEW', false, mainModule)
		},
		CREATE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'CREATE', false, mainModule)
		},
		EDIT_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'EDIT', false, mainModule)
		},
		DELETE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'DELETE', false, mainModule)
		},
		COSTS_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'COSTS', false, mainModule)
		},
		EXPORT_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'EXPORT', false, mainModule)
		},
		SHARE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'SHARE', false, mainModule)
		},
		CONFIRM_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'CONFIRM', false, mainModule)
		},
		VALIDATE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'VALIDATE', false, mainModule)
		},
		MANUAL_RECORD_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'MANUAL_RECORD', false, mainModule)
		},
		MODIFIED_RECORD_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'MODIFIED_RECORD', false, mainModule)
		},
		MASS_CLOSURE_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'MASS_CLOSURE', false, mainModule)
		},
		DOWNLOAD_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'DOWNLOAD', false, mainModule)
		},
		ATTACH_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'ATTACH', false, mainModule)
		},
		SEND_EMAIL_COPY_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'SEND_EMAIL_COPY', false, mainModule)
		},
		SEND_FOR_SIGN_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'SEND_FOR_SIGN', false, mainModule)
		},
		SEND_FOR_READ_PERMISSIONS: (state, getters) => (tabSelected, mainModule) => {
			return getters.ACTION_PERMISSIONS(tabSelected, 'SEND_FOR_READ', false, mainModule)
		},
		ASSOCIATED_FILES_ENABLED: (state, getters) => (action, tabSelected) => {
			return getters.ACTION_PERMISSIONS(tabSelected, action, true)
		}
	},
	mutations: {},
	actions: {}
}
