import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import TheDrawer from '@/components/TheDrawer.vue'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'

import ViewLogin from '@/views/ViewLogin.vue'
import ViewImport from '@/views/ViewImport.vue'
import ViewDefault from '@/views/ViewDefault/index.vue'
import ViewDefaultEdit from '@/views/ViewDefault/_id/index.vue'

import $store from '@/store'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		redirect: '/records',
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/import/:tab',
		name: 'import',
		components: {
			default: ViewImport,
			drawer: TheDrawer,
			header: TheHeader
			// footer: TheFooter
		},
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/records/:tab',
		name: 'records',
		components: {
			default: ViewDefault,
			drawer: TheDrawer,
			header: TheHeader
			// footer: TheFooter
		},
		meta: {
			requiresAuth: true
		},
		redirect: { name: 'sustainability' }
	},
	{
		path: '/config/:tab',
		name: 'config',
		components: {
			default: ViewDefault,
			drawer: TheDrawer,
			header: TheHeader
			// footer: TheFooter
		},
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/sustainability/:tab',
		name: 'sustainability',
		components: {
			default: ViewDefault,
			drawer: TheDrawer,
			header: TheHeader,
			footer: TheFooter
		},
		meta: {
			requiresAuth: true
		}
	}
	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const loginRoutes = ['login', 'signup', 'reset-password']
loginRoutes.forEach(e => {
	const obj = {
		path: `/${e}`,
		name: e,
		components: {
			default: ViewLogin
		}
	}
	routes.push(obj)
})

const mainModules = ['records', 'sustainability', 'config']
mainModules.forEach(e => {
	const baseObj = {
		path: `/${e}/:tab`,
		name: e,
		components: {
			default: {},
			drawer: TheDrawer,
			header: TheHeader
		},
		meta: {
			requiresAuth: true
		}
	}
	// @ts-ignore
	if (e === 'sustainability') baseObj.components.footer = TheFooter
	// Main Route
	const mainRoute = Object.assign({}, baseObj)
	mainRoute.components.default = ViewDefault
	// routes.push(mainRoute)

	// Redirect
	const redirect = {
		path: `/${e}`,
		redirect: baseObj.path
	}
	routes.push(redirect)

	// Action Route
	const actions = ['edit', 'copy']
	actions.forEach(action => {
		const actionRoute = Object.assign({}, baseObj)
		actionRoute.components.default = ViewDefaultEdit
		actionRoute.name += `_${action}`
		actionRoute.path += `/:id`
		if (action === 'copy') actionRoute.path += `/copy`
		routes.push(actionRoute)
	})
})

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async route => {
	if (route.meta.requiresAuth) {
		const currentRoute = router.currentRoute.value.name
		// @ts-ignore
		if (!['login', 'signup'].includes(currentRoute)) {
			$store.commit('SET_LOADING', true)
			await $store.dispatch('auth/getUser')
			$store.commit('SET_LOADING', false)
		}
		const isAuth = localStorage.getItem('user')
		if (!isAuth) return { name: 'login' }
	}
})

export default router
