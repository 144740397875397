import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		item_keys: {},
		item_tables: {},
		item_api_columns: {},
		subtables: {
			warehouses: ['warehouse_evaluation_applications', 'warehouse_evaluation_machines', 'warehouse_evaluation_packages', 'warehouse_evaluation_wastes']
		},
		allowed_values: {}
	},
	getters: {
		GET_ITEMS_KEYS(state: VuexStateContext) {
			return state.item_keys
		},
		GET_INVISIBLE(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].invisible))
			return dictionary
		},
		GET_REQUIRED(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].required))
			return dictionary
		},
		GET_OPTIONALS(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].optionals))
			return dictionary
		},
		GET_FORM_HIDDEN(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].form_hidden))
			return dictionary
		},
		GET_DATETIME(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].datetime))
			return dictionary
		},
		GET_FILTER(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].filter))
			return dictionary
		},
		GET_COLUMNS(state: VuexStateContext) {
			const dictionary: ObjectContext = {}
			const tables = state.item_tables
			Object.keys(tables).forEach((tab: string) => (dictionary[tab] = tables[tab].columns))
			return dictionary
		},
		GET_API_COLUMNS(state: VuexStateContext) {
			return state.item_api_columns
		},
		GET_ALLOWED_VALUES(state: VuexStateContext) {
			return state.allowed_values
		}
	},
	mutations: {
		async SET_ITEM(state: VuexStateContext, value: Array<object>) {
			// Keys
			const item_keys: ObjectContext = value.reduce((obj: ObjectContext, element: ObjectContext) => {
				obj[element.column_definition_name] = element
				return obj
			}, {})
			state.item_keys = Object.assign(state.item_keys, item_keys)

			// Tables
			const item_tables: ObjectContext = {}
			const item_api_columns: ObjectContext = {}
			const stateDictionary: Array<ObjectContext> = Object.values(state.item_keys)
			stateDictionary.forEach((e: ObjectContext) => {
				e.tables.forEach((tab: string) => {
					if (!item_api_columns[tab]) item_api_columns[tab] = []
					if (!item_tables[tab]) {
						item_tables[tab] = {
							invisible: [],
							required: [],
							optionals: [],
							datetime: [],
							form_hidden: [],
							filter: [],
							columns: []
						}
					}
					const defaultValidation = e.validation.default
					const tabValidation = e.validation[tab] || {}
					const keys = Object.keys(tabValidation)

					let isVisible = false

					if (tabValidation.type === 'datetime') item_tables[tab].datetime.push(e.column_definition_name)
					else if (defaultValidation.type === 'datetime') item_tables[tab].datetime.push(e.column_definition_name)

					if (tabValidation.required === null) item_tables[tab].form_hidden.push(e.column_definition_name)
					else if (defaultValidation.required === null) item_tables[tab].form_hidden.push(e.column_definition_name)

					if (keys.includes('visible')) {
						if (tabValidation.visible === false) item_tables[tab].invisible.push(e.column_definition_name)
						else if (tabValidation.visible === true) isVisible = true
					} else {
						if (defaultValidation.visible === false) item_tables[tab].invisible.push(e.column_definition_name)
						else if (defaultValidation.visible === true) isVisible = true
					}

					if (keys.includes('table_column')) {
						if (tabValidation.table_column === true) item_api_columns[tab].push(e.column_definition_name)
					} else if (defaultValidation.table_column === true) {
						item_api_columns[tab].push(e.column_definition_name)
					}

					if (isVisible) {
						if (keys.includes('table_column')) {
							if (tabValidation.table_column === true) item_tables[tab].columns.push(e.column_definition_name)
						} else if (defaultValidation.table_column === true) {
							item_tables[tab].columns.push(e.column_definition_name)
						}

						if (keys.includes('required')) {
							if (tabValidation.required === true) item_tables[tab].required.push(e.column_definition_name)
							else if (tabValidation.required === false) item_tables[tab].optionals.push(e.column_definition_name)
						} else {
							if (defaultValidation.required === true) item_tables[tab].required.push(e.column_definition_name)
							else if (defaultValidation.required === false) item_tables[tab].optionals.push(e.column_definition_name)
						}

						if (keys.includes('filter')) {
							if (tabValidation.filter === true) item_tables[tab].filter.push(e.column_definition_name)
						} else {
							if (defaultValidation.filter === true) item_tables[tab].filter.push(e.column_definition_name)
						}
					}
				})
			})

			state.item_tables = item_tables
			state.item_api_columns = item_api_columns
		},
		INIT_ALLOWED_VALUES(state: VuexStateContext, params: VuexParamsContext) {
			state.allowed_values = {}
		},
		SET_ALLOWED_VALUES(state: VuexStateContext, params: VuexParamsContext) {
			const key = params.key
			const items = params.items
			state.allowed_values[key] = JSON.parse(JSON.stringify(items))
		}
	},
	actions: {
		async get({ state, commit }: VuexActionContext, params: VuexParamsContext) {
			commit('INIT_ALLOWED_VALUES')
			const body: ObjectContext = {
				...params
			}
			const table = params.tables[0]
			const subtables = state.subtables[table] || []
			body.tables = body.tables.concat(subtables)
			// @ts-ignore
			const res = await this.$axios.post('dictionary', body)
			const data = res.data.response_json
			const items = data['dictionary']
			await commit('SET_ITEM', items)
		}
	}
}
