
	import TDialogPersonPicture from '@/components/templates/TDialogPersonPicture.vue'
	import MListEntity from '@/components//molecules/MListEntity.vue'
	import AButtonIcon from '@/components/atoms/AButtonIcon.vue'

	import * as UTIL from '@/util'

	import { ObjectContext } from '@/global'

	import { defineComponent, getCurrentInstance, reactive, computed } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'DefaultCellRenderer',
		components: {
			AButtonIcon,
			TDialogPersonPicture,
			MListEntity
		},
		setup() {
			const instance = getCurrentInstance()
			const props = instance?.props || {}

			const { $t, $store, icons, loading, dictionary, tabSelected, checkProxyValue } = useGeneral()
			const { COSTS_VISIBLE } = useRole(tabSelected.value)

			const state = reactive({
				show: false,
				items: []
			})

			const datetimeKeys = computed(() => Object.values($store.getters['dictionary/GET_DATETIME'][tabSelected.value] || {}))

			const params: ObjectContext = computed(() => props.params || {})
			const isCellEditor = computed(() => params.value.colDef?.editable || false)
			const cellField = computed(() => params.value.colDef?.field || '')
			const editable = computed(() => params.value.colDef?.editable || false)
			const item = computed(() => params.value.data || {})
			const value = computed(() => item.value[cellField.value])

			const keyToParse = computed(() => $store.getters['GET_KEY_TO_PARSE'])
			const keyToParseLabel = computed(() => $store.getters['GET_KEY_TO_PARSE_LABEL'])

			const keysToParse = computed(() => Object.keys(keyToParse.value))
			const isKeyToParse = computed(() => keysToParse.value.includes(cellField.value))

			const valueParsed = computed(() => {
				let result = []
				if (isKeyToParse.value) {
					const value = checkProxyValue(item.value[cellField.value], null)
					if (value !== null) {
						const key = keyToParse.value[cellField.value]
						const label = keyToParseLabel.value[key]
						const valueToParse = checkProxyValue(item.value[key], null)
						if (![undefined, null].includes(valueToParse)) {
							result = Array.isArray(valueToParse) ? valueToParse.map(e => e[label]) : [valueToParse[label]]
						}
					}
				}
				return result
			})

			function deleteItem(item: ObjectContext) {
				if (params.value.deleteItem instanceof Function) {
					params.value.deleteItem(item)
				}
			}

			function getItemIndex(item: ObjectContext) {
				return UTIL.getItemIndex(item, state.items)
			}

			function init() {
				if (cellField.value === 'actions') state.items = params.value.items || []
			}
			init()
			const isURL = computed(() => !!dictionary.value[cellField.value]?.is_url)

			function openPDF(url: string) {
				window.open(url, '_blank')
			}

			function entityName(item: ObjectContext | string) {
				let text = item
				if (item && typeof item === 'object') {
					text = item.entity_name || ''
					if (item.group_name) text = item.group_name
					else if (item.person_name) text = item.person_name
					else if (item.machine_name) text = item.machine_name
					else if (item.customer_name) text = item.customer_name
					else if (item.action_defintion_name) text = item.action_defintion_name
					let unit = ' €/h'
					// Cost
					if (COSTS_VISIBLE.value && item.cost > -1) {
						let cost = item.cost || 0
						if (item.cost_ha) {
							cost = item.cost_ha
							unit = ' €/ha'
						} else if (item.cost_per_hour) cost = item.cost_per_hour
						text += ' (' + cost + unit + ')'
					}
				}
				// @ts-ignore
				return $t(text, cellField.value)
			}

			const observations = computed(() => $store.getters['entities_type_config']?.observations.spanish)

			const menuItems: ObjectContext = computed(() => {
				const items: Array<ObjectContext> = []
				const obj = observations.value

				if (obj) Object.keys(obj).forEach(key => items.push({ value: obj[key], text: key }))

				return items.reduce((obj, element) => {
					obj[element.value] = element.text
					return obj
				}, {})
			})

			function startEditing() {
				params.value.api.startEditingCell({ rowIndex: params.value.rowIndex, colKey: cellField.value })
			}

			function openGoogleMaps(coordinates: Array<number>) {
				const link = `https://www.google.com/maps/search/?api=1&query=${coordinates[1]}%2C${coordinates[0]}`
				window.open(link, '_blank')
			}

			async function openWarehouseEvaluationPDF() {
				if (value.value) {
					loading.value = true
					try {
						const body = {
							warehouse_evaluation_id: item.value.warehouse_evaluation_id,
							tabSelected: tabSelected.value
						}
						const url = await $store.dispatch('report/get', body)
						const alert = {
							url,
							timeout: 0,
							tab: 'report',
							action: 'download',
							color: 'success'
						}
						$store.dispatch('alert/SET_ALERT', alert)
					} catch (error) {
						$store.dispatch('alert/SET_ALERT', { action: 'get', error })
					} finally {
						loading.value = false
					}
				}
			}

			// TODO: Finish this
			function editItem(item: ObjectContext) {
				console.log(`🚀 ~ editItem ~ item`, item)
			}

			return {
				UTIL,
				datetimeKeys,
				t: $t,
				icons,
				loading,
				state,
				isCellEditor,
				cellField,
				editable,
				item,
				value,
				isKeyToParse,
				valueParsed,
				deleteItem,
				isURL,
				openPDF,
				entityName,
				menuItems,
				startEditing,
				openGoogleMaps,
				openWarehouseEvaluationPDF,
				editItem
			}
		}
	})
