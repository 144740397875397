import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AInputLabel = _resolveComponent("AInputLabel")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mainTable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.displayName), 1))
      : (_openBlock(), _createBlock(_component_AInputLabel, {
          key: 1,
          location: "bottom",
          label: _ctx.displayName,
          required: _ctx.required,
          hint: _ctx.hint
        }, null, 8, ["label", "required", "hint"])),
    (_ctx.sortIcon)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 2,
          class: "mr-2",
          icon: _ctx.sortIcon,
          style: {"position":"absolute","right":"0px"}
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 64))
}