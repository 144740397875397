<template>
	<span>
		<AInputLabel v-if="label && !$props.cellEditor" :label="label" :required="$props.required" />
		<v-file-input
			:id="id"
			:color="baseColor"
			:bg-color="bgColor"
			:variant="variant"
			:density="density"
			:rules="rules"
			:loading="$props.loading"
			:disabled="$props.disabled"
			:hint="$props.hint"
			:persistent-hint="!!$props.hint"
			:placeholder="$props.placeholder"
			:prepend-inner-icon="$props.prependIcon"
			:append-inner-icon="$props.appendIcon"
			:autofocus="$props.celllEditor"
			:accept="$props.accept"
			:hide-details="hideDetails"
			@update:modelValue="onUpdateValue($event)"
			@click:clear="onClear()"
			@blur="onBlur()"
			@focus="onFocus()"
		></v-file-input>

		<!-- Value -->
		<div :id="$props.id + '_value'" class="d-none" v-text="state.item" />
	</span>
</template>

<script setup>
	import AInputLabel from './AInputLabel.vue'
	import { defineEmits, defineProps, reactive, watch } from 'vue'
	import { useGeneral, useInput } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'clear', 'enter'])
	const $props = defineProps({
		modelValue: { type: Array },
		keyName: { type: String, default: '' },
		id: { type: String, default: '' },
		label: { type: String, default: 'General_file' },
		hint: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		prependIcon: { type: String, default: '' },
		appendIcon: { type: String, default: '' },
		accept: { type: String, default: '' },
		required: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		cellEditor: { type: Boolean, default: false }
	})

	const state = reactive({
		item: null
	})

	watch(
		() => $props.modelValue,
		val => (state.item = val)
	)

	const { checkProxyValue } = useGeneral()
	const { id, label, focused, baseColor, bgColor, variant, density, rules } = useInput($props, state)

	// function init() {
	// 	const value = $props.modelValue
	// 	if (value) state.item = value
	// }
	// init()

	function onUpdateValue(event) {
		if (event) {
			state.item = checkProxyValue($props.multiple ? event : event[0], null)
			$emit('update:modelValue', state.item)
		}
	}
	function onClear() {
		state.item = null
		$emit('update:modelValue', state.item)
	}
	function onBlur() {
		focused.value = false
	}
	function onFocus() {
		focused.value = true
	}
</script>
