<template>
	<v-dialog v-model="state.dialog" scrollable :width="state.width">
		<template #activator="{ props }">
			<span v-if="item[$props.keyName] > 0" class="cursor-pointer font-weight-bold text-blue" v-bind="props" style="text-decoration: underline">
				{{ item[$props.keyName] || 0 }}
			</span>
			<span v-else>{{ item[$props.keyName] || 0 }}</span>
		</template>
		<v-card :min-width="state.width" :max-width="state.width">
			<v-card-title primary-title>
				{{ title }}
				<v-spacer></v-spacer>
				<!-- TODO: Use AButtonIcon -->
				<v-btn variant="text" icon @click="onClose()">
					<v-icon :icon="icons['close']" />
				</v-btn>
			</v-card-title>
			<v-card-text>
				<AList :items="items" density="default" @click="viewClick($event)" />
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script setup>
	import AList from '@/components/atoms/AList.vue'
	import AButtonIcon from '@/components/atoms/AButtonIcon.vue'

	import { defineEmits, defineProps, reactive, computed } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	const { $t, $route, $router, $store, icons, tabSelected } = useGeneral()
	const { EDIT_VISIBLE } = useRole(tabSelected)

	const $emit = defineEmits(['click'])

	const $props = defineProps({
		item: { type: Object, default: null, required: true },
		keyName: { type: String, default: '', required: true }
	})

	const state = reactive({
		dialog: false,
		width: 500
	})

	const title = computed(() => ($props.keyName === 'persons_count' ? $t('General_people') : $t('General_groups')))
	const text_key = computed(() => ($props.keyName === 'persons_count' ? 'person_name' : 'group_name'))
	const tab = computed(() => ($props.keyName === 'persons_count' ? 'persons' : 'groups'))

	function getElementPicture(e) {
		return e['main_picture_preview'] ? 'data:image/jpeg;base64,' + e['main_picture_preview'] : $store.state.default_picture_avatar
	}

	const items = computed(() => {
		let val = []
		if ($props.item) {
			val = $props.item[tab.value].map(e => {
				return {
					text: e[text_key.value],
					avatar: $props.keyName === 'persons_count' ? getElementPicture(e) : null,
					disabled: !EDIT_VISIBLE.value,
					value: e
				}
			})
		}
		return val
	})

	function onClose() {
		state.dialog = false
	}

	const itemIdKey = computed(() => $store.getters['GET_ITEM_ID_KEY'][tab.value] || '')

	function viewClick(item) {
		console.log(`🚀 ~ viewClick`, $props.item)
		const key = itemIdKey.value
		const routeName = 'config_edit'
		const route = $router.resolve({
			name: routeName,
			params: {
				tab: tab.value,
				id: item[key]
			}
		})
		const host = window.location.host
		const fullPath = route.fullPath
		const url = host + fullPath
		window.open(url, '_blank')
	}
</script>
