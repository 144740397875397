<template>
	<ODialog
		v-model="state.dialog"
		small
		:icon="icons['create_alt']"
		:title="tabSelectedElement"
		:action="action"
		:disabled="EDIT_DISABLED"
		:loading="loading"
		persistent
		@submit="onSubmit()"
	>
		<v-form ref="refForm" lazy-validation @submit.prevent>
			<v-row no-gutters>
				<v-col v-if="documentType !== 'additional'" cols="12">
					<AInputText :label="$t('document_name')" v-model="state.item.document_name" :disabled="!!documentId" required />
				</v-col>

				<v-col v-if="documentType !== 'additional'" cols="12">
					<AInputAutocomplete :label="$t('status')" v-model="state.item.status" :items="NEW_FILE_STATUS_ITEMS" required />
				</v-col>

				<v-col cols="12">
					<AInputFile v-if="state.dialog" v-model="state.item.rawFile" required @update:modelValue="state.item.rawFile = $event" />
				</v-col>
			</v-row>
		</v-form>
	</ODialog>
</template>

<script setup>
	import AInputText from '@/components/atoms/AInputText.vue'
	import AInputAutocomplete from '@/components/atoms/AInputAutocomplete.vue'
	import AInputFile from '@/components/atoms/AInputFile.vue'
	import ODialog from '@/components/organism/ODialog.vue'

	import { defineEmits, defineProps, ref, reactive, computed, watch } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'init', 'close'])
	const $props = defineProps({
		modelValue: { type: Boolean, default: false },
		tabSelected: { type: String, required: true },
		itemId: { type: Number, default: null, required: true },
		document: { type: Object, default: null }
	})

	const state = reactive({
		dialog: false,
		item: {}
	})

	const { $t, $store, icons, loading, checkProxyValue } = useGeneral()
	const { EDIT_DISABLED } = useRole($props.tabSelected)

	watch(
		() => $props.modelValue,
		val => (state.dialog = val)
	)

	const refForm = ref(null)

	const tabSelectedElement = computed(() => $t('General_file'))
	const documentId = computed(() => $props.document?.document_definition_id)
	const documentStatus = computed(() => $props.document?.status)
	const documentType = computed(() => $props.document?.document_required_type || 'additional')
	const action = computed(() => (documentType.value !== 'additional' ? 'Action_attach' : 'Action_create'))

	const NEW_FILE_STATUS_ITEMS = computed(() => {
		return $store.getters['associated_files/NEW_FILE_STATUS_ITEMS']
			.filter(e => (documentType.value != 'additional' ? e.value != 'without_signature' : true))
			.filter(e => (documentStatus.value === 'pending_signature' ? e.value != 'pending_signature' : true))
	})

	function reset() {
		state.item = {}
		if (refForm.value) refForm.value.resetValidation()
	}

	function onUpdateValue(val) {
		$emit('update:modelValue', val)
	}

	function onClose(init, item) {
		onUpdateValue(false)
		$emit('close')
		reset()
		if (init) $emit('init', item)
	}

	function init() {
		reset()
		if (documentId.value) state.item.document_name = $props.document.document_name
	}

	watch(
		() => state.dialog,
		val => (val ? init() : onClose())
	)

	async function onSubmit() {
		// @ts-ignore
		const res = await refForm.value.validate()
		const valid = res.valid
		if (valid) {
			loading.value = true
			try {
				const body = {
					tabSelected: $props.tabSelected,
					itemId: $props.itemId,
					file: checkProxyValue(state.item, {})
				}
				if (documentId.value) body.file.document_definition_id = documentId.value
				if (documentType.value === 'additional') body.file.status = 'without_signature'
				await $store.dispatch(`associated_files/uploadFile`, body)
				onClose(true)
			} catch (error) {
				$store.dispatch('alert/SET_ALERT', { error })
			} finally {
				loading.value = false
			}
		}
	}
</script>
