import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

import $moment from 'moment'

export default {
	namespaced: true,
	state: {
		item: {},
		list: []
	},
	getters: {
		IS_CLOSED(state: VuexStateContext) {
			return state.item.is_closed
		}
	},
	mutations: {
		SET_LIST(state: VuexStateContext, value: Array<object>) {
			state.list = JSON.parse(JSON.stringify(value))
		},
		SET_ITEM(state: VuexStateContext, value: ObjectContext) {
			state.item = Object.assign({}, value || {})
		}
	},
	actions: {
		async getList({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			const obj: ObjectContext = params || {}
			if (!obj.query) obj.query = {}
			try {
				const body: ObjectContext = {
					...obj,
					return_type: 'group_reports'
				}
				commit('SET_LIST', [])
				// @ts-ignore
				const res = await this.$axios.post('query/group_reports', body)
				const data = res.data.response_json
				const items = data['group_reports']
				commit('SET_LIST', items)
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { action: 'get', error }, { root: true })
			}
		},
		async get({ commit }: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			commit('SET_ITEM', {})
			// @ts-ignore
			const res = await this.$axios.get(`group_reports/${params.group_report_id}`)
			const item = res.data.response_json
			commit('SET_ITEM', item)
			return item
		},
		async create(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				group_report_start_at: $moment().format()
			}
			// @ts-ignore
			return await this.$axios.post('group_reports', body)
		},
		async update(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : item ? (body = Object.assign({}, item)) : (body = Object.assign({}, params))
			delete body._item
			delete body.associated_files
			// @ts-ignore
			return await this.$axios.patch(`group_reports/${params.group_report_id}`, body)
		},
		async delete(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/${params.group_report_id}`)
		},
		// Facts
		async createFact(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				group_report_fact_at: $moment().format()
			}
			delete body.group_report_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/facts`, body)
		},
		async updateFact(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/${item.group_report_id}/facts/${item.group_report_fact_id}`, body)
		},
		async deleteFact(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/${params.group_report_id}/facts/${params.group_report_fact_id}`)
		},
		// Productivity
		async createProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				group_report_productivity_at: $moment().format()
			}
			delete body.group_report_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/productivities`, body)
		},
		async updateProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/${item.group_report_id}/productivities/${item.group_report_productivity_id}`, body)
		},
		async deleteProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/${params.group_report_id}/productivities/${params.group_report_productivity_id}`)
		},
		// Issues
		async createIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				group_report_issue_at: $moment().format()
			}
			delete body.group_report_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/issues`, body)
		},
		async updateIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/${item.group_report_id}/issues/${item.group_report_issue_id}`, body)
		},
		async deleteIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/${params.group_report_id}/issues/${params.group_report_issue_id}`)
		},
		// Records
		async createPersonRecord(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const group_report_id = params.group_report_id
			const body: ObjectContext = {
				...params,
				is_manual: true,
				person_record_at: $moment().format()
			}
			delete body.group_report_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${group_report_id}/person_records`, body)
		},
		async updatePersonRecord(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/person_records/${item.person_record_id}`, body)
		},
		async deletePersonRecord(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/person_records/${params.person_record_id}`)
		},
		// Person Timetable
		async createPersonTimetable(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params
			}
			delete body.group_report_id
			delete body.person_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/person_timetables/${params.person_id}`, body)
		},
		async updatePersonTimetable(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/person_timetables/${item.person_timetable_id}`, body)
		},
		async deletePersonTimetable(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/person_timetables/${params.person_timetable_id}`)
		},
		// Person Productivity
		async createPersonProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				person_productivity_at: $moment().format()
			}
			delete body.group_report_id
			delete body.person_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/person_productivities/${params.person_id}`, body)
		},
		async updatePersonProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/person_productivities/${item.person_productivity_id}`, body)
		},
		async deletePersonProductivity(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/person_productivities/${params.person_productivity_id}`)
		},
		// Person Issues
		async createPersonIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = {
				...params,
				person_issue_at: $moment().format()
			}
			delete body.group_report_id
			delete body.person_id
			// @ts-ignore
			return await this.$axios.post(`/group_reports/${params.group_report_id}/person_issues/${params.person_id}`, body)
		},
		async updatePersonIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			// @ts-ignore
			return await this.$axios.patch(`group_reports/person_issues/${item.person_issue_id}`, body)
		},
		async deletePersonIssue(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`group_reports/person_issues/${params.person_issue_id}`)
		}
	}
}
