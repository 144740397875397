import moment from 'moment'
import store from '@/store'
import { Decimal } from 'decimal.js'
import { i18n } from '@/plugins/vuetify-i18n'
import wicket from 'wicket/wicket'
import XLSX from 'xlsx'

const $i18n = i18n.global

export const isTrackingEnabled = () => {
	const subdomain = window.location.host.toLowerCase().replace(process.env.VUE_APP_HOST, '').replaceAll('.', '')
	return subdomain !== process.env.VUE_APP_NO_TRACKING_SUBDOMAIN
}

export const exportExcel = ({ headers, items, name }) => {
	const data = []
	const headersRow = {}

	const exclude = ['actions']

	const dictionaryObject = store.getters['dictionaryObject']

	headers.forEach(e => {
		const key = e.value || e
		const text = e.text || e
		const translation = translationText(text)
		if (!exclude.includes(key)) {
			headersRow[key] = translation
		}
	})

	data.push(headersRow)

	items.forEach(e => {
		const obj = {}
		headers.forEach(x => {
			const key = x.value || x
			const text = dictionaryObject[key] || key
			obj[key] = formatTableValue(text, e[key])
			if (key === 'password') obj[key] = e[key]
		})
		data.push(obj)
	})

	const date = moment().format('DD_MM_YYYY')
	const excel_name = stringNormalizeHeader($i18n.t(name)) + '_' + date + '.xlsx'

	/* make the worksheet */
	const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true })

	/* add to workbook */
	const wb = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(wb, ws, '')

	/* generate an XLSX file */
	XLSX.writeFile(wb, excel_name)
}

export const customFilter = (item, queryText, itemText) => {
	const string = itemText
		? itemText
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		: ''
	const query = queryText
		? queryText
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.toLowerCase()
		: ''
	return queryText ? string.includes(query) : true
}

export const customTableFilter = (items, search) => {
	const stringSearch = stringNormalize(search)
	if (!search) return items
	else {
		return items.filter(e => {
			let value = e
			if (typeof e.value === 'string') value = e.value
			else if (typeof e.key === 'string') value = e.key
			else if (typeof e.key_name === 'string') value = e.key_name
			const text = e.text
			const stringValue = stringNormalize($i18n.t(value))
			const stringText = stringNormalize($i18n.t(value))
			return text ? stringValue.includes(stringSearch) || stringText.includes(stringSearch) : stringValue.includes(stringSearch)
		})
	}
}

export const keypressDisabled = (event = {}, keys = []) => {
	if (keys.includes(event.key)) {
		event.preventDefault()
		let alert = {
			text: $i18n.t('Error_keypress_disabled_html ', { key: event.key })
		}
		store.dispatch('alert/SET_ALERT', alert)
	}
}

export const copyToClipboard = text => {
	if (navigator.clipboard) {
		navigator.clipboard.writeText(text).then(() => {
			let alert = {
				text: $i18n.t('General_copied_to_clipboard'),
				color: 'success'
			}
			store.dispatch('alert/SET_ALERT', alert)
		})
	} else if (window.clipboardData) {
		window.clipboardData.setData('Text', text)
	}
}

export const toBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onload = e => resolve(window.btoa(e.target.result))
		reader.onerror = error => reject(error)
		reader.readAsBinaryString(file)
	})

export const getItemIndex = (item, array) => {
	let index = -1
	if (item) {
		index = item.uuid ? array.findIndex(e => e.uuid == item.uuid) : array.findIndex(e => JSON.stringify(e) == JSON.stringify(item))
		if (index < 0) {
			for (let i = 0; i < array.length; i++) {
				const element = array[i]
				const isSame = JSON.stringify(element) === JSON.stringify(item)
				if (isSame) {
					index = i
					break
				}
			}
		}
	}
	return index
}

export const arrayBufferError = error => {
	let message = null
	if (error && error.response && error.response.data) {
		message = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))
	}
	return message || error
}

export const getFiltersQuery = (filters = {}, sort = {}) => {
	const query = {}
	Object.keys(filters).forEach(key => {
		const value = filters[key] || []
		if (Array.isArray(value) && value.length) query[key] = { in: value }
		else if (key.includes('_at') && Object.keys(value).length) {
			query[key] = Object.assign({}, value)
			Object.keys(value).forEach(e => {
				const val = query[key][e]
				query[key][e] = e === 'le' ? moment(val).endOf('day').format() : moment(val).format()
			})
		}
	})
	Object.keys(sort).forEach(key => {
		if (!query[key]) query[key] = {}
		query[key]['sort'] = sort[key]
	})
	return query
}

export const variableDescription = key => {
	let description = null
	// TODO: Use $i18n
	const locale = $i18n.locale.split('-')[0]
	const varLocale = store.state.varLocales[locale] || 'spanish'
	const dictionaryObject = store.getters['dictionaryObject']
	const dictionaryElement = dictionaryObject[key]
	if (dictionaryElement) {
		const customer_description = dictionaryElement.customer_description && dictionaryElement.customer_description[varLocale]
		if (customer_description) description = customer_description
	}
	return description
}

export const translationText = item => {
	console.log(`🚀 ~ item`, item)
	let value = ''
	if (item) {
		// TODO: Use $i18n
		const text = item.text
		const name = item.name
		const translation = item.column_definition_name
		value = translationExist(text || name || translation || item)
	}
	return value
}

// Map
export const stringToGeometry = string => {
	const wkt = new wicket.Wkt()
	wkt.read(string)
	return wkt.toJson()
}

export const geometryToString = geometry => {
	const wkt = new wicket.Wkt()
	wkt.fromObject(geometry)
	return wkt.write()
}

export const coordinatesObjectsToArray = coords => {
	return coords.map(array => {
		if (Array.isArray(array[0])) {
			const newNestedArray = array.map(nestedArray => {
				const newArray = nestedArray.map(e => {
					const lat = e.lat
					const lng = e.lng
					const coord = [lng, lat]
					return coord
				})
				return newArray
			})
			return newNestedArray
		} else {
			const newArray = array.map(e => {
				const lat = e.lat
				const lng = e.lng
				const coord = [lng, lat]
				return coord
			})
			return newArray
		}
	})
}

export const completeGeometry = _latlngs => {
	const coordinates = Array.isArray(_latlngs) ? _latlngs : coordinatesObjectsToArray(_latlngs)
	for (let i = 0; i < coordinates.length; i++) {
		const cutIntoPieces = Array.isArray(coordinates[i][0]) && coordinates[i][0].length > 2
		// Default Function
		if (!cutIntoPieces) {
			const element = coordinates[i]
			const firstCoord = element[0]
			const lastCoord = element[element.length - 1]
			const equal = JSON.stringify(firstCoord) == JSON.stringify(lastCoord)
			if (!equal) coordinates[i].push(firstCoord)
		}
		// ! Polygon cut into pieces == Leaflet Error
		// else {
		// 	return null
		// 	const array = coordinates[i]
		// 	const newArray = array.map(element => {
		// 		const firstCoord = element[0]
		// 		const lastCoord = element[element.length - 1]
		// 		const equal = JSON.stringify(firstCoord) == JSON.stringify(lastCoord)
		// 		if (!equal) element.push(firstCoord)
		// 		return element
		// 	})
		// 	// ! Not best solution - But will get a leaflet error otherwise
		// 	coordinates[i] = newArray[0]
		// }
	}
	return coordinates
}

// Times
export const formatTime = date => {
	return date && date != 'NaT' ? moment(date).format('HH:mm:ss') : ''
}

// Dates
export const formatDate = date => {
	return date && date != 'NaT' ? moment(date).format('DD/MM/YYYY') : ''
}

export const formatDateExcel = date => {
	return date ? moment(date).format('DD_MM_YYYY') : ''
}

export const formatDateBack = date => {
	return date && date != 'NaT' ? moment(date).format('YYYY-MM-DD') : ''
}

export const formatDateLong = date => {
	return date && date != 'NaT' ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''
}

export const formatDateLongAlt = date => {
	return date && date != 'NaT' ? moment(date).format('MMM ddd DD, YYYY') : ''
}

export const formatDateTime = date => {
	return date && date != 'NaT' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
}

export const formatDateYear = date => {
	return date && date != 'NaT' ? moment(date).format('YYYY') : ''
}

export const formatWeek = date => {
	return date && date != 'NaT' ? moment(date).week() : ''
}

export const parseDate = date => {
	return date && date != 'NaT' ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : ''
}

export const parseDateUTC = date => {
	return date && date != 'NaT' ? moment(date).format() : ''
}

export const getDatesDiff = (
	type, // days - weeks
	datetime,
	start_datetime
) => {
	return moment(datetime).diff(moment(start_datetime), type) + 1
}

export const loadEntitiesSelects = ({
	temp = true,
	return_selected_only = true,
	irrigation = false,
	phytosanitary = [],
	fertilizer = [],
	other_product = [],
	machines = [],
	applicators = [],
	contacts = [],
	tasks = []
}) => {
	// Get Products Items
	if (phytosanitary && phytosanitary.length) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'phytosanitaries', value: false })
		const body = {
			temp,
			products: phytosanitary.map(e => e.product_id)
		}
		store.dispatch('fertilization/getQueryPhytosItems', body)
	}

	if (fertilizer && fertilizer.length) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'fertilizers', value: false })
		const body = {
			temp,
			products: fertilizer.map(e => e.product_id)
		}
		store.dispatch('fertilization/getQueryFertilizerItems', body)
	}

	if (other_product && other_product.length) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'otherProducts', value: false })
		const body = {
			temp,
			products: other_product.map(e => e.product_id)
		}
		store.dispatch('fertilization/getQueryOtherProductsItems', body)
	}

	// Get Machine Items
	if (machines && machines.length) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'machines', value: false })
		const body = {
			temp,
			return_selected_only,
			type: 'machines',
			return_from: 'elastic',
			selected: machines,
			columns: ['tags']
		}
		if (irrigation) {
			body.filters = {
				entity_sub_type: ['irrigation', 'irrigation_bomb']
			}
		}
		store.dispatch('fertilization/getQueryEntities', body)
	}

	// Get Applicator Items
	if (applicators && applicators.length) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'applicators', value: false })
		const body = {
			temp,
			return_selected_only,
			type: 'contacts',
			subType: 'applicators',
			return_from: 'elastic',
			selected: applicators,
			columns: ['tags']
		}
		store.dispatch('fertilization/getQueryEntities', body)
	}

	// Get Contacts Items
	if (contacts) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'contacts', value: false })
		const body = {
			temp,
			return_selected_only,
			type: 'contacts',
			return_from: 'elastic',
			selected: contacts,
			columns: ['tags']
		}
		store.dispatch('fertilization/getQueryEntities', body)
	}

	// Get Tasks Items
	if (tasks) {
		store.commit('fertilization/SET_VAR', { key: 'fullLoaded', subkey: 'tasks', value: false })
		const body = {
			temp,
			return_selected_only,
			type: 'tasks',
			return_from: 'elastic',
			selected: tasks,
			columns: ['tags']
		}
		store.dispatch('fertilization/getQueryEntities', body)
	}
}

export const getFullQueryListItems = ({ init = false, fullLoaded = false, selected = [], list = [] }) => {
	const listLength = list.length
	const selectedLength = selected.length
	//const differentLength = !fullLoaded && listLength !== selectedLength
	const itemAdded = listLength < selectedLength
	const itemRemoved = listLength > selectedLength
	//const cleared = init && listLength === selectedLength

	return fullLoaded ? false : !listLength || init || itemAdded || itemRemoved
}

export const initProcessListItems = ({
	key_id = '',
	fullLoaded = false,
	editMode = false,
	copyItem = false,
	selected = [],
	list = [],
	deletedOrNotAllowed = []
}) => {
	const items = []
	if (!fullLoaded && (editMode || copyItem)) {
		for (let i = 0; i < selected.length; i++) {
			const element = selected[i]
			const id = element[key_id]
			if (id) {
				const not_in_old_list = !list.some(e => e[key_id] === id)
				const not_in_new_list = !deletedOrNotAllowed.some(e => e[key_id] === id)
				if (not_in_old_list && not_in_new_list) {
					items.push(element)
				}
			}
		}
	}
	return items
}

export const processListItems = ({ items = [], tempItems = [], deletedOrNotAllowed = [] }) => {
	let mappedItems = []
	// Deleted or Not Allowed
	if (deletedOrNotAllowed.length) {
		mappedItems = deletedOrNotAllowed.map(e => {
			const obj = {
				...e,
				deletedOrNotAllowed: true
			}
			return obj
		})
	}
	// Return Processed List
	const list = mappedItems.concat(tempItems, items)
	return list
}

export const processItems = ({ firstLoad = false, item = null, product = null, selected = [], items = [], tempItems = [], deletedOrNotAllowed = '' }) => {
	const list = {
		items: items,
		tempItems: tempItems
	}
	// Deleted or Not Allowed
	if (firstLoad && selected.length) {
		store.state.fertilization.notAllowed[deletedOrNotAllowed] = selected
			.filter(e => {
				const entity_id = e && e.entity_id
				const product_id = e && e.product_id
				const notInTemp = !tempItems.some(x => x.entity_id === e.entity_id || x.product_id === e.product_id)
				const notInItems = !items.some(x => x.entity_id === e.entity_id || x.product_id === e.product_id)
				return (entity_id || product_id) && notInTemp && notInItems
			})
			.map(e => {
				const obj = {
					...e,
					deletedOrNotAllowed: true
				}
				return obj
			})

		list.items = items.filter(e => {
			return !tempItems.some(x => x.entity_id === e.entity_id || x.product_id === e.product_id)
		})
	}
	// Return Processed List
	// return items.length ? items : item && product ? [product] : store.state.fertilization[deletedOrNotAllowed].concat(list.tempItems, list.items)
	return items.length ? items : item && product ? [product] : store.state.fertilization.notAllowed[deletedOrNotAllowed].concat(list.tempItems, list.items)
}

export const getInitials = string => {
	const initials = string
		.split(' ')
		.map(e => e[0])
		.join('')
	return initials
}

export const getPhonePrefix = (phone_prefix = '') => {
	return phone_prefix && phone_prefix.includes('+34') ? 'ES (+34)' : phone_prefix || 'ES (+34)'
}

export const getPhoneNumber = ({ phone_prefix, phone_number, spacing }) => {
	let prefix = phone_prefix || 'ES (+34)'
	let number = prefix.replace(')', '').replace('-', '').split('(')[1]
	if (spacing) number += ' '
	number += phone_number
	return number
}

export const parseCustomerName = item => {
	const tabSelectedItem = store.state.config.tabSelectedItem
	const customersNamesObject = store.getters['roles/ENABLED_CUSTOMERS'].object

	let name = ''
	const customer = customersNamesObject[item]

	if (tabSelectedItem == 'campaigns') name = item
	else if (customer) name = customer.name

	return name
}

export const headerStatistic = key => {
	const statistics = store.state.headersStatistics
	const keys = Object.keys(statistics)
	if (keys.includes(key)) {
		const obj = statistics[key]
		const values = Object.keys(obj)
		if (values.length) {
			if (obj['unique_values']) return obj['unique_values']
			if (obj['sum']) return obj['sum']
			if (obj['min_date'] && obj['max_date']) {
				const min_date = formatDateYear(obj['min_date'])
				const max_date = formatDateYear(obj['max_date'])
				return min_date && max_date ? min_date + ' - ' + max_date : ''
			}
		}
	}
	return ''
}

export const checkBoolean = (keyName, value) => {
	const valueFormated = formatTableValue(keyName, value)
	const boolean = [true, false]
	return boolean.includes(value) ? $i18n.t(`Form_boolean_${value}`) : valueFormated
}

export const formatTableValue = (item, value) => {
	const dictionary = store.getters['dictionary/GET_ITEMS_KEYS']
	const element = (typeof item === 'string' && dictionary[item]) || item
	if (value) {
		if (Array.isArray(value)) return value.join(', ')
		else if (element.values_subtype == 'datetime' || element.values_subtype == 'date') {
			return element.show_as_datetime ? formatDateLong(value) : formatDate(value)
		}
		/**
		 * TODO: Mirar error si se usa el type del diccionario
		 * TODO Alguna variable debe estar guardando valores de string en harvest_planification)
		 */
		// else if (element.values_type === 'integer' || element.values_type === 'float') {
		else if (typeof value === 'number') {
			value = Number(new Decimal(value || 0).toDP(3, 0))
			value = formatedNumber(value)
		} else if (element.key_name == 'password' || element == 'password') value = '********'
	}
	return value === 0 ? 0 : valuesTranslation(element?.column_definition_name || element, value)
}

export const compareAlphaNum = (a, b) => {
	const reA = /[^a-zA-Z]/g
	const reN = /[^0-9]/g
	const aA = a.replace(reA, '')
	const bA = b.replace(reA, '')
	if (aA !== bA) return aA > bA ? 1 : -1
	else {
		const aN = parseInt(a.replace(reN, ''), 10)
		const bN = parseInt(b.replace(reN, ''), 10)
		return aN === bN ? 0 : aN > bN ? 1 : -1
	}
}

export const sortDynamicArray = (arr = [], idx = 0) => {
	const array = JSON.parse(JSON.stringify(arr))

	// Filter by letters
	const letters = array.filter(e => {
		if (e.uuid) {
			const number = Number(e.uuid.slice(0, 2))
			const isNumber = Number.isInteger(number)
			return !isNumber
		} else return true
	})

	// Filter by numbers
	const numbers = array.filter(e => {
		if (e.uuid) {
			const number = Number(e.uuid.slice(0, 2))
			const isNumber = Number.isInteger(number)
			return isNumber
		} else return false
	})

	// Sort
	const sortedLetters = sortArray(letters, true, 'uuid')
	const sortedNumbers = sortArray(numbers, true, 'uuid')

	// Numbers then letters
	const sorted = sortedNumbers.concat(sortedLetters)

	// Update Index
	let index = idx
	if (sorted.length) {
		for (let i = 0; i < sorted.length; i++) {
			const element = sorted[i]
			const uuid = element.uuid
			if (uuid) {
				const first2Chars = uuid.slice(0, 2)
				const first2CharsNumber = Number(first2Chars)
				const charsNumbers = Number.isInteger(first2CharsNumber)
				if (charsNumbers) {
					const number = Number(first2Chars)
					if (number > Number(index)) index = number
				}
			}
		}
	}

	// const uuids = sorted.map(e => e.uuid.slice(0, 2))

	return {
		sorted: sorted,
		index: index
	}
}

export const sortArray = (array, desc = false, key1, key2) => {
	const value = JSON.parse(JSON.stringify(array))

	const items = value.sort((a, b) => {
		const element = {
			A1: a,
			B1: a
		}
		if (key1) {
			element.A1 = a[key1]
			element.B1 = b[key1]
		}
		if (key2) {
			element.A2 = a[key2]
			element.B2 = b[key2]
		}
		return element.A1 == element.B1 && key2 ? (element.A2 > element.B2 ? 1 : -1) * (desc ? -1 : 1) : (element.A1 > element.B1 ? 1 : -1) * (desc ? -1 : 1)
	})

	return items
}

export const checkRequiredOptional = ({ item, notInclude, optionals, requireds, route, tabSelected }) => {
	const dictionary = store.getters.dictionary
	const dictionaryObject = store.getters.dictionaryObject
	const requiredItems = store.getters['requiredItems'](route, tabSelected)
	const optionalsItems = store.getters['optionalsItems'](route, tabSelected)

	const optionalsKeys = []
	const keys = Object.keys(item)

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i]

		const isRequired = requiredItems.some(e => {
			return e.key_name == key && e.required_by_user && e.required_by_user[tabSelected]
		})

		const isInDictionary = dictionary.some(e => e.key_name == key)

		// const isDictionaryOptional = dictionary.some(e => {
		//   return e.key_name === key
		//     && (
		//       !e.required_by_user
		//       || (e.required_by_user && !e.required_by_user[tabSelected])
		//     )
		// });

		const isDictionaryVisible = dictionary.some(e => {
			return e.key_name === key && e.visible && e.tables.includes(tabSelected)
		})

		const exist = requiredItems.some(e => e.key_name === key)

		let isOptional = true
		if (isInDictionary) isOptional = !exist && isDictionaryVisible

		const isInOptionalsItems = optionalsItems.some(e => e.key_name == key)
		if (isInOptionalsItems && !exist) isOptional = true

		if (!isRequired && isOptional && !notInclude.includes(key)) {
			// const index = optionalsItems.findIndex(e => e.key_name === key)
			// let obj = null
			// if (index > -1) {
			// 	obj = {
			// 		value: optionalsItems[index].key_name,
			// 		text: optionalsItems[index].key_translation || optionalsItems[index].key_name
			// 	}
			// }
			optionals.push({
				select: key, // ! obj || key
				value: item[key]
			})
			optionalsKeys.push(key)
		}
	}

	// Delete Optionals From Item
	for (let i = 0; i < optionalsKeys.length; i++) {
		const key = optionalsKeys[i]
		delete item[key]
	}

	// Required
	const requiredKeys = keys.filter(key => !optionalsKeys.includes(key))

	for (let i = 0; i < requiredKeys.length; i++) {
		const key = requiredKeys[i]
		const dictionaryElement = dictionaryObject[key]
		const isVisible = dictionaryElement && dictionaryElement.visible
		if (isVisible && keys.includes(key)) {
			if (key == 'datetime') item[key] = formatDateTime(item[key])
			requireds.push(dictionaryElement)
		} else {
			delete item[key]
		}
	}
}

export const checkCopyKeys = (item, route, tabSelected) => {
	const copyItems = store.getters.copyItems(route, tabSelected)
	const keys = Object.keys(item)
	const copyKeys = []
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i]
		if (copyItems.some(e => e.key_name === key)) copyKeys.push(key)
	}
	return copyKeys
}

export const removeObjectEmptyValues = (obj, allowNull) => {
	const keys = Object.keys(obj)
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i]
		if ((!allowNull && obj[key] === null) || obj[key] === '' || JSON.stringify(obj[key]) === '{}') {
			delete obj[key]
		}
	}
}

export const generateUUID = () => {
	// Public Domain/MIT
	let d = new Date().getTime()
	if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
		d += performance.now() //use high-precision timer if available
	}
	let newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = (d + Math.random() * 16) % 16 | 0
		d = Math.floor(d / 16)
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
	})

	return newGuid.replace(/-/g, '')
}

export const stringNormalizeHeader = string => {
	return string
		.toLowerCase()
		.replaceAll('.', '')
		.replaceAll(' ', '_')
		.replace(/[{()}]/g, '')
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/\s+/g, '')
}

export const stringNormalize = string => {
	return string
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.replace(/\s+/g, '')
		.replace(/_/g, '')
}

export const formatedNumber = item => {
	const locale = $i18n.locale
	let number = item
	// TODO: Asegurar que esto funcione bien para cualquier caso
	if (number) {
		const decimal_separator = locale.includes('es') ? ',' : '.'
		const thousand_separator = locale.includes('es') ? '.' : ','
		const value = number.toString().split('.') // Split by default decimal separator
		number = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousand_separator)
		if (value[1]) number += decimal_separator + value[1]
	}
	return number
}

export const formatedNumberBack = item => {
	const locale = $i18n.locale
	let str = item
	let number = 0
	// TODO: Asegurar que esto funcione bien para cualquier caso
	if (str) {
		const decimal_separator = locale.includes('es') ? ',' : '.'
		const thousand_separator = locale.includes('es') ? '.' : ','
		let value = str.replace(thousand_separator, '')
		value = value.replace(decimal_separator, '.')
		if (value) number = Number(value)
	}
	return number
}

export const isValidNumber = (number, toDP) => {
	let x = new Decimal(number)
	if (toDP) x = x.toDP(3, 0)
	return x.isNaN() || !x.isFinite() ? 0 : Number(x)
}

export const translationExist = string => {
	return $i18n.te(`${string}`)
		? $i18n.t(`${string}`)
		: // General
		$i18n.te(`General_${string}`)
		? $i18n.t(`General_${string}`)
		: // Action
		$i18n.te(`Action_${string}`)
		? $i18n.t(`Action_${string}`)
		: // Form
		$i18n.te(`Form_${string}`)
		? $i18n.t(`Form_${string}`)
		: string
}

export const valuesTranslation = (key, value) => {
	return $i18n.te(`_values_translation.${key}`) && $i18n.te(`_values_translation.${key}.${value}`)
		? $i18n.t(`_values_translation.${key}.${value}`)
		: translationExist(value)
}

export const entityName = (item, showCost = true) => {
	const customersNamesObject = store.getters['roles/ENABLED_CUSTOMERS'].object

	let text = item
	if (item !== null && typeof item === 'object') {
		const entity = item.entity_name
		text = entity.entity_name || entity
		let unit = ' €/h'
		// Cost
		if (showCost) {
			let cost = item.cost || 0
			if (item.cost_ha) {
				cost = item.cost_ha
				unit = ' €/ha'
			} else if (item.cost_per_hour) cost = item.cost_per_hour
			text += ' (' + cost + unit + ')'
		}
		// Customer
		let customerName = ''
		const customerObject = customersNamesObject[item.customer]
		if (customerObject) customerName = ' (' + customerObject.name + ')'
		// Result
		text += customerName
	}
	return text
}

export const mapTemplateTooltip = (data, highlight = true, defaultLayerKey) => {
	const fields = store.getters.dictionary.filter(e => e.show_in_tooltip)
	const customersNamesObject = store.getters['roles/ENABLED_CUSTOMERS'].object

	const getChip = (item, value) => {
		const color = value ? 'success' : 'error'
		const chip = `
				<span class="v-chip v-chip--no-color theme--light v-size--small ${color}--text ${color}-background">
					<span class="v-chip__content">
						${formatTableValue(item, value)}
					</span>
				</span>
			`
		return chip
	}

	let tooltip = ''
	// Required
	for (let i = 0; i < fields.length; i++) {
		const item = fields[i]
		const header = $i18n.t(item.key_name)
		let value = ''

		if (item.key_name == 'customer') {
			const customer = customersNamesObject[data.customer]
			if (customer) value = customer.name
		} else value = data[item.key_name] || ''

		if (typeof value === 'number') value = formatedNumber(value)

		const isBoolean = typeof data[item.key_name] == 'boolean'
		// if (isBoolean) value = value ? switch_on : switch_off
		if (isBoolean) value = getChip(item, data[item.key_name])

		if (value || isBoolean) {
			tooltip += defaultLayerKey === item.key_name && highlight ? '<tr class="success-background">' : '<tr>'
			tooltip += `
						<td class="pa-2 text-capitalize" style="width: 50%">${header}</td>
						<td class="pa-2" style="width: 50%">${value}</td>
					</tr>
				`
		}
	}

	const templateTooltip = `
			<div class="v-data-table v-data-table--dense theme--light">
				<div class="v-data-table__wrapper">
					<table style="width:100%">
						${tooltip}
					</table>
				</div>
			</div>
		`

	return templateTooltip
}

export const areaChange = async ({ tabs, item, broth, pulsesDay, fertilizationAreaSum, fertilizationNumPlantsSum, isIrrigation }) => {
	const area = new Decimal(fertilizationAreaSum || 0)

	// Tabs
	for (let t = 0; t < tabs.length; t++) {
		const name = tabs[t]
		const tab = item[name]
		if (tab) {
			for (let i = 0; i < tab.length; i++) {
				const element = tab[i]
				onChangeTabCalculations({
					input: 'area',
					item: element,
					broth: broth,
					pulsesDay: pulsesDay,
					fertilizationAreaSum: fertilizationAreaSum,
					fertilizationNumPlantsSum: fertilizationNumPlantsSum,
					isIrrigation: isIrrigation
				})
			}
		}

		//Application broth
		if (!isIrrigation) item.application_broth = isValidNumber(new Decimal(broth || 0).times(area || 0))
	}
}

export const brothOrPulsesDayChange = ({ tabs, item, broth, pulsesDay, fertilizationAreaSum, isIrrigation }) => {
	const area = new Decimal(fertilizationAreaSum || 0)

	if (!isIrrigation) item.brothFinal = broth

	// Tabs
	for (let t = 0; t < tabs.length; t++) {
		const name = tabs[t]
		const tab = item[name]
		if (tab) {
			for (let i = 0; i < tab.length; i++) {
				const element = tab[i]

				// Application
				if (!isIrrigation) {
					element.concentration = isValidNumber(new Decimal(element.kg_liters_per_hectare || 0).dividedBy(broth || 0).times(100))
				}

				// Irrigation
				if (isIrrigation) {
					element.irrigation_kg_l_ha_pulse = isValidNumber(new Decimal(element.kg_liters_per_hectare || 0).dividedBy(pulsesDay || 0))
				}
			}
		}
	}

	//Application broth
	if (!isIrrigation) item.application_broth = isValidNumber(new Decimal(broth || 0).times(area || 0))
}

export const irrigationChange = ({ input, item, fertilizationAreaSum, fertilizationDropperFlowSum }) => {
	const area = new Decimal(fertilizationAreaSum || 0)
	const dropper_flow = new Decimal(fertilizationDropperFlowSum || 0)

	if (input === 'water_per_ha') {
		item.total_water = isValidNumber(new Decimal(item.water_per_ha || 0).times(area || 0))

		item.water_per_pulse = isValidNumber(new Decimal(item.total_water || 0).dividedBy(item.days || 0).dividedBy(item.pulses_per_day || 0))

		if (dropper_flow) {
			item.irrigation_hours_per_day = isValidNumber(
				new Decimal(item.total_water || 0)
					.dividedBy(dropper_flow || 0)
					.dividedBy(item.pulses_per_day || 0)
					.dividedBy(item.days || 0)
					.times(1000)
			)
		}
	} else if (input === 'total_water') {
		item.water_per_ha = isValidNumber(new Decimal(item.total_water || 0).dividedBy(area || 0))

		item.water_per_pulse = isValidNumber(new Decimal(item.total_water || 0).dividedBy(item.days || 0).dividedBy(item.pulses_per_day || 0))

		if (dropper_flow) {
			item.irrigation_hours_per_day = isValidNumber(
				new Decimal(item.total_water || 0)
					.dividedBy(dropper_flow || 0)
					.dividedBy(item.pulses_per_day || 0)
					.dividedBy(item.days || 0)
					.times(1000)
			)
		}
	} else if (input === 'water_per_pulse') {
		item.total_water = isValidNumber(new Decimal(item.water_per_pulse || 0).times(item.days || 0).times(item.pulses_per_day || 0))

		item.water_per_ha = isValidNumber(new Decimal(item.total_water || 0).dividedBy(area || 0))

		if (dropper_flow) {
			item.irrigation_hours_per_day = isValidNumber(
				new Decimal(item.total_water || 0)
					.dividedBy(dropper_flow || 0)
					.dividedBy(item.pulses_per_day || 0)
					.dividedBy(item.days || 0)
					.times(1000)
			)
		}
	} else {
		if (dropper_flow) {
			const sum = dropper_flow

			item.total_water = isValidNumber(
				new Decimal(sum)
					.times(item.irrigation_hours_per_day || 0)
					.times(item.pulses_per_day || 0)
					.times(item.days || 0)
					.dividedBy(1000)
			)
		}

		item.water_per_ha = isValidNumber(new Decimal(item.total_water || 0).dividedBy(area || 0))

		item.water_per_pulse = isValidNumber(new Decimal(item.total_water || 0).dividedBy(item.days || 0).dividedBy(item.pulses_per_day || 0))
	}
}

export const onChangeTabCalculations = ({ input, item, broth, pulsesDay, fertilizationAreaSum, fertilizationNumPlantsSum, isIrrigation }) => {
	const area = new Decimal(fertilizationAreaSum || 0)
	const number_of_plants = new Decimal(fertilizationNumPlantsSum || 0)

	// Se calcula y modifica el item
	if (input === 'area') {
		//Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).times(area || 0))

		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.total_kg_l_dose).dividedBy(number_of_plants || 0).times(1000))

		// Irrigation
		if (isIrrigation) {
			//gr_cc_per_plant_per_pulse
			item.gr_cc_per_plant_per_pulse = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).dividedBy(pulsesDay || 0))
		}
	}
	if (input === 'kg_liters_per_hectare') {
		//Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).times(area || 0))

		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.total_kg_l_dose).dividedBy(number_of_plants || 0).times(1000))

		// Application
		if (!isIrrigation) {
			//Concentration %
			item.concentration = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(broth || 0).times(100))
		}

		// Irrigation
		if (isIrrigation) {
			//irrigation_kg_l_ha_pulse
			item.irrigation_kg_l_ha_pulse = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(pulsesDay || 0))

			//gr_cc_per_plant_per_pulse
			item.gr_cc_per_plant_per_pulse = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).dividedBy(pulsesDay || 0))
		}
	} else if (input === 'total_kg_l_dose') {
		//kg_liters_per_hectare
		item.kg_liters_per_hectare = isValidNumber(new Decimal(item.total_kg_l_dose || 0).dividedBy(area || 0))

		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.total_kg_l_dose || 0).dividedBy(number_of_plants || 0).times(1000))

		// Application
		if (!isIrrigation) {
			//Concentration %
			item.concentration = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(broth || 0).times(100))
		}

		// Irrigation
		if (isIrrigation) {
			//irrigation_kg_l_ha_pulse
			item.irrigation_kg_l_ha_pulse = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(pulsesDay || 0))

			//gr_cc_per_plant_per_pulse
			item.gr_cc_per_plant_per_pulse = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).dividedBy(pulsesDay || 0))
		}
	} else if (input == 'gr_cc_per_plant') {
		//Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).times(number_of_plants || 0).dividedBy(1000))

		//kg_liters_per_hectare
		item.kg_liters_per_hectare = isValidNumber(new Decimal(item.total_kg_l_dose || 0).dividedBy(area || 0))

		// Application
		if (!isIrrigation) {
			//Concentration %
			item.concentration = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(broth || 0).times(100))
		}

		// Irrigation
		if (isIrrigation) {
			//irrigation_kg_l_ha_pulse
			item.irrigation_kg_l_ha_pulse = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(pulsesDay || 0))

			//gr_cc_per_plant_per_pulse
			item.gr_cc_per_plant_per_pulse = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).dividedBy(pulsesDay || 0))
		}
	} else if (input == 'gr_cc_per_plant_per_pulse') {
		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.gr_cc_per_plant_per_pulse || 0).times(pulsesDay || 0))

		//Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).times(number_of_plants || 0).dividedBy(1000))

		//kg_liters_per_hectare
		item.kg_liters_per_hectare = isValidNumber(new Decimal(item.total_kg_l_dose || 0).dividedBy(area || 0))

		//gr_cc_per_plant_per_pulse
		item.irrigation_kg_l_ha_pulse = isValidNumber(new Decimal(item.kg_liters_per_hectare || 0).dividedBy(pulsesDay || 0))
	} else if (input === 'irrigation_kg_l_ha_pulse') {
		// Kg Liters
		item.kg_liters_per_hectare = isValidNumber(new Decimal(item.irrigation_kg_l_ha_pulse || 0).times(pulsesDay || 0))

		// Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.kg_liters_per_hectare).times(area || 0))

		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.total_kg_l_dose).dividedBy(number_of_plants || 0).times(1000))

		//gr_cc_per_plant_per_pulse
		item.gr_cc_per_plant_per_pulse = isValidNumber(new Decimal(item.gr_cc_per_plant || 0).dividedBy(pulsesDay || 0))
	} else if (input === 'concentration') {
		// Kg Liters
		item.kg_liters_per_hectare = isValidNumber(new Decimal(item.concentration || 0).times(broth || 0).dividedBy(100))

		// Total
		item.total_kg_l_dose = isValidNumber(new Decimal(item.kg_liters_per_hectare).times(area || 0))

		//gr_cc_per_plant
		item.gr_cc_per_plant = isValidNumber(new Decimal(item.total_kg_l_dose).dividedBy(number_of_plants || 0).times(1000))
	}
}

export const costTotal = ({ item, fertilizationAreaSum }) => {
	let costTotal = 0
	const tabs = ['fertilizer', 'phytosanitary', 'other_product']

	for (let t = 0; t < tabs.length; t++) {
		const name = tabs[t]
		const tab = item[name]
		if (tab) {
			for (let i = 0; i < tab.length; i++) {
				const element = tab[i]
				const total = isValidNumber(new Decimal(element.cost || 0).times(element.kg_liters_per_hectare || 0))
				costTotal = isValidNumber(new Decimal(costTotal).plus(total))
			}
		}
	}

	return isValidNumber(new Decimal(costTotal).times(fertilizationAreaSum || 0))
}
