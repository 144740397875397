<template>
	<div :style="gridStyle">
		<div class="ag-container">
			<AgGridVue
				class="ag-theme-alpine"
				animateRows
				hideOverlay
				quickFilter
				singleClickEdit
				:columnDefs="columnDefs"
				:rowData="rowData"
				:sideBar="state.sideBar"
				:domLayout="domLayout"
				:gridOptions="gridOptions"
				:rowSelection="$props.rowSelection"
				:localeText="state.localeText"
				:pagination="!!$props.pageSize"
				:paginationPageSize="$props.pageSize"
				:paginationAutoPageSize="!$props.pageSize"
				:suppressRowClickSelection="$props.suppressRowClickSelection"
				:stopEditingWhenCellsLoseFocus="true"
				@gridReady="onGridReady"
				@cellValueChanged="changeCellValue"
				@columnMoved="onColumnMoved"
				@dragStopped="onColumnMoved(null)"
				@selectionChanged="onSelectionChanged"
				@sortChanged="onSortChanged"
				style="width: 100%; height: 100%"
			/>
		</div>
		<v-row v-if="state.pagination" align="enter" c class="mt-4">
			<v-col cols="3">
				<v-menu anchor="bottom">
					<template #activator="{ props }">
						<v-btn v-bind="props" variant="outlined" color="grey-lighten-2" max-height="32px">
							<span class="text-black">
								{{ $t('Pagination_items_per_page', null, { items_per_page: state.pagination.items_per_page }) }}
							</span>
							<v-icon class="ml-4" :icon="icons['chevronDown']" />
						</v-btn>
					</template>
					<AList :items="itemsPerPageList" @click="onUpdateItemsPerPage($event)" />
				</v-menu>
			</v-col>

			<v-col cols="6">
				<v-pagination
					v-model="state.pagination.page_number"
					size="x-small"
					:total-visible="4"
					:length="pageCount"
					border
					@update:modelValue="onUpdatePageNumber($event)"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script setup>
	import AList from '@/components/atoms/AList.vue'
	import DefaultHeaderComponent from '@/components/datatable/DefaultHeaderComponent.vue'
	import { AgGridVue } from 'ag-grid-vue3'
	import * as UTIL from '@/util'

	import { defineEmits, defineProps, reactive, computed, watch, onBeforeMount } from 'vue'
	import { useGeneral } from '@/hooks'

	const $emit = defineEmits(['gridReady', 'changeCellValue', 'columnMoved', 'updatePageNumber', 'updateItemsPerPage', 'selectionChanged', 'sortChanged'])
	const $props = defineProps({
		height: { type: [String, Number], default: 70 },
		maxHeight: { type: [String, Number], default: null },
		autoHeight: { type: Boolean, default: false },
		sizeColumnsToFit: { type: Boolean, default: true },
		headers: { type: Array, default: () => [], required: true },
		items: { type: Array, default: () => [], required: true },
		rowSelection: { type: String, default: null },
		singleClickEdit: { type: Boolean, default: false },
		suppressRowClickSelection: { type: Boolean, default: false },
		pageSize: { type: [String, Number], default: null },
		pagination: { type: Object, default: null }
	})

	const { $t, icons, locale } = useGeneral()

	const state = reactive({
		UTIL,
		gridApi: null,
		columnApi: null,
		pagination: null,
		sideBar: {},
		localeText: {},
		columns: []
	})

	function loadPagination() {
		state.pagination = $props.pagination ? Object.assign({}, $props.pagination) : null
	}
	loadPagination()

	watch(
		() => $props.pagination,
		() => loadPagination()
	)

	const pageCount = computed(() => {
		let count = 1
		if (!state.pagination) count = 0
		else {
			const newCount = Math.floor(state.pagination.total_rows / state.pagination.items_per_page)
			if (newCount > 0) count = newCount
		}
		return count
	})
	const itemsPerPageList = computed(() => {
		if (!state.pagination) return []
		else {
			return state.pagination.items_per_page_list.map(e => {
				return { text: e.toString(), value: e }
			})
		}
	})

	function onUpdatePageNumber(page_number) {
		$emit('updatePageNumber', page_number)
	}

	function onUpdateItemsPerPage(items_per_page) {
		$emit('updateItemsPerPage', items_per_page)
	}

	onBeforeMount(() => {
		state.localeText = {
			equals: 'es igual',
			notEqual: 'no es igual',
			lessThan: 'es menor',
			greaterThan: 'es mayor',
			inRange: 'esta en el rango',
			contains: 'contiene',
			noRowsToShow: 'No existen datos',
			filters: 'Filtros',
			columns: 'Columnas'
		}
	})

	const rowData = computed(() => $props.items)
	const columnDefs = computed(() => {
		return $props.headers.map(e => {
			if (!e.originalHeaderName && e.headerName) e.originalHeaderName = e.headerName
			if (e.originalHeaderName) e.headerName = $t(e.originalHeaderName)
			e.headerComponent = DefaultHeaderComponent
			// TODO: Add sort to headerComponent
			e.sortable = false
			if (!e.mainTable && !e.editable && e.field !== 'actions') {
				e.cellClass = params => {
					return 'bg-grey-lighten-4'
				}
			}
			return e
		})
	})

	const domLayout = computed(() => ($props.autoHeight ? 'autoHeight' : ''))
	const gridStyle = computed(() => {
		const style = {
			width: '100%'
		}
		if (!$props.autoHeight) style['height'] = $props.height + 'vh'
		else if ($props.maxHeight) style['max-height'] = $props.maxHeight + 'vh'
		return style
	})
	const gridOptions = computed(() => {
		return {
			rowHeight: 60,
			singleClickEdit: $props.singleClickEdit
		}
	})

	function onGridReady(params) {
		state.gridApi = params.api
		state.columnApi = params.columnApi
		setTimeout(() => {
			$props.sizeColumnsToFit ? state.gridApi.sizeColumnsToFit() : state.columnApi.autoSizeAllColumns()
		}, 100)
		$emit('gridReady', {
			gridApi: state.gridApi,
			columnApi: state.columnApi
		})
		// state.gridApi.hideOverlay()
	}
	function changeCellValue(params) {
		/**
		 * @params params.colDef.field // Header
		 * @params params.node.data // Item Object
		 * @params params.newValue // Changed Value
		 */
		$emit('changeCellValue', params)
	}

	function onColumnMoved(params) {
		if (!params) $emit('columnMoved', state.columns)
		else {
			state.columns = params.columnApi.columnModel.displayedColumns.map(e => e.colDef)
		}
	}

	function onSelectionChanged(params) {
		const selectedNodes = Object.values(params.api.selectionService.selectedNodes).filter(e => e !== undefined)
		const selection = Object.values(selectedNodes).map(e => e.data)
		$emit('selectionChanged', selection)
	}

	function onSortChanged(params) {
		const sortColumns = {}
		const columns = params.columnApi.columnModel.displayedColumns.filter(e => !!e.sort)
		columns.forEach(e => {
			const key = e.colDef.field
			const sort = e.sort === 'asc' ? 'ascending' : 'descending'
			sortColumns[key] = sort
		})
		$emit('sortChanged', sortColumns)
	}
</script>
