<template>
	<ALoader v-if="state.loading" />
	<template v-else>
		<div v-if="!state.items.length || loading" class="pa-4">
			{{ $t('General_no_data_available') }}
		</div>
		<v-expand-transition>
			<AgDatatable
				v-if="state.items.length"
				:headers="headers"
				:items="state.items"
				:size-columns-to-fit="false"
				auto-height
				@gridReady="onGridReady($event)"
			/>
		</v-expand-transition>
	</template>
</template>

<script setup>
	import ALoader from '@/components/atoms/ALoader.vue'
	import AgDatatable from '@/components/datatable/AgDatatable.vue'
	import DefaultCellRenderer from '@/components/datatable/cellRenderers/DefaultCellRenderer.vue'
	import DefaultCellEditor from '@/components/datatable/cellEditors/DefaultCellEditor.vue'

	import { defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral, useRole, useDatatable } from '@/hooks'

	const $props = defineProps({
		item: { type: Object, default: null, required: true }
	})

	const state = reactive({
		gridApi: null,
		loading: false,
		tabSelected: 'warehouse_evaluations',
		items: [],
		valueGetterColumns: ['warehouse_evaluation_crop_destrio_quantity', 'warehouse_evaluation_crop_ready_made_units'],
		columns: [
			'warehouse_evaluation_crop_name',
			'warehouse_evaluation_crop_processed_quantity',
			// 'warehouse_evaluation_crop_processed_mu',
			// 'warehouse_evaluation_crop_distribution_supplies_quantity',
			// 'warehouse_evaluation_crop_distribution_supplies_mu',
			'warehouse_evaluation_crop_ready_made_quantity',
			// 'warehouse_evaluation_crop_ready_made_mu',
			'warehouse_evaluation_crop_destrio_quantity',
			// 'warehouse_evaluation_crop_destrio_mu',
			'warehouse_evaluation_crop_finished_product_quantity',
			// 'warehouse_evaluation_crop_finished_product_mu',
			'warehouse_evaluation_crop_ready_made_units',
			'warehouse_evaluation_crop_water_quantity',
			// 'warehouse_evaluation_crop_water_quantity_mu',
			'warehouse_evaluation_crop_electricity_quantity',
			// 'warehouse_evaluation_crop_electricity_mu',
			// 'warehouse_evaluation_crop_refrigeration_quantity'
			// 'warehouse_evaluation_crop_refrigeration_mu',
			'warehouse_evaluation_crop_machinery_quantity'
			// 'warehouse_evaluation_crop_machinery_mu'
		]
	})

	const { $t, $store, icons, mainClick } = useGeneral()
	const { suppressEnter, valueSetter, isCellEditable } = useDatatable()
	const { EDIT_VISIBLE, EDIT_DISABLED } = useRole(state.tabSelected)

	function onGridReady(params) {
		state.gridApi = params.gridApi
	}
	watch(mainClick, () => {
		if (state.gridApi) state.gridApi.stopEditing()
	})

	const warehouse_evaluation_id = computed(() => $props.item.warehouse_evaluation_id)

	async function init() {
		console.log(`🚀 ~ init ~ $props.item`, $props.item)
		if ($props.item) {
			state.loading = true
			try {
				const item = await $store.dispatch(`${state.tabSelected}/get`, warehouse_evaluation_id.value)
				state.items = [item]
			} finally {
				state.loading = false
			}
		}
	}
	init()

	function valueGetter(params) {
		const item = params.data
		const key = params.colDef.field
		let value = item[key]

		const kg_producto = 'warehouse_evaluation_crop_processed_quantity'
		const kg_confeccionados = 'warehouse_evaluation_crop_ready_made_quantity'
		const kg_producto_terminado = 'warehouse_evaluation_crop_finished_product_quantity'

		if (key === 'warehouse_evaluation_crop_destrio_quantity') {
			value = null
			if (item[kg_confeccionados] > 0 && item[kg_producto] > 0) {
				value = ((item[kg_producto] - item[kg_confeccionados]) / item[kg_producto]) * 100
				value = Math.round(value)
			}
		}

		if (key === 'warehouse_evaluation_crop_ready_made_units') {
			value = null
			if (item[kg_confeccionados] > 0 && item[kg_producto_terminado] > 0) {
				value = Math.round(item[kg_confeccionados] / item[kg_producto_terminado])
			}
		}

		return value
	}

	const headers = computed(() => {
		// @ts-ignore
		return state.columns.map(key => {
			return {
				field: key,
				headerName: key,
				editable: isCellEditable(key, state.tabSelected),
				sortable: false,
				resizable: false,
				suppressMovable: true,
				cellEditor: DefaultCellEditor,
				cellRenderer: state.valueGetterColumns.includes(key) ? null : DefaultCellRenderer,
				cellEditorParams: {
					tabSelected: state.tabSelected
				},
				cellRendererParams: {
					items: state.items
				},
				valueGetter: params => valueGetter(params),
				valueSetter: params => valueSetter(params, state.tabSelected)
			}
		})
	})
</script>
