<template>
	<span class="cursor-initial align-center d-flex">
		<span class="cursor-initial">
			<span v-if="$props.required" class="text-error mr-1">*</span>
			{{ $t($props.label) }}
			<v-tooltip v-if="$props.required" activator="parent" :location="$props.location">
				{{ $t('Form_required') }}
			</v-tooltip>
		</span>

		<v-tooltip v-if="$props.hint" :location="$props.location">
			<template v-slot:activator="{ props }">
				<v-icon v-if="$props.hint" v-bind="props" class="ml-1 pt-1" color="secondary" :icon="icons['help']"> </v-icon>
			</template>
			{{ $t($props.hint) }}
		</v-tooltip>
	</span>
</template>

<script setup>
	import { defineProps } from 'vue'
	import { useGeneral } from '@/hooks'

	const { $t, icons } = useGeneral()

	const $props = defineProps({
		label: { type: String, default: '' },
		hint: { type: String, default: '' },
		location: { type: String, default: 'end' },
		required: { type: Boolean, default: false }
	})
</script>
