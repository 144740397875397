<template>
	<span>
		<AInputLabel v-if="label && !$props.cellEditor" :label="label" :required="$props.required" />
		<v-autocomplete
			:id="id"
			v-model="state.item"
			class="hide-label"
			:clearable="!$props.cellEditor"
			:color="baseColor"
			:bg-color="bgColor"
			:variant="variant"
			:density="density"
			:items="items"
			:item-title="$props.itemText"
			:item-value="$props.itemValue"
			:filter="UTIL.customFilter"
			:rules="rules"
			:loading="$props.loading"
			:disabled="$props.loading || $props.disabled"
			:multiple="$props.multiple"
			chips
			:closable-chips="$props.multiple && !$props.filter"
			:return-object="$props.returnObject"
			:prefix="$props.prefix"
			:suffix="$props.suffix"
			:hint="$props.hint"
			:persistent-hint="!!$props.hint"
			:placeholder="$props.placeholder"
			:prepend-inner-icon="prependIcon"
			:append-inner-icon="appendIcon"
			autocomplete="off"
			:autofocus="$props.celllEditor"
			:hide-details="hideDetails"
			:no-data-text="$t('General_no_data_available')"
			@update:modelValue="onUpdateValue()"
			@input="onInput()"
			@change="onChange()"
			@blur="onBlur()"
			@focus="onFocus()"
			@click:clear="onClear()"
		>
			<template v-slot:item="{ item: data, on, attrs }">
				<v-list-item v-on="on" v-bind="attrs">
					<v-list-item-action v-if="$props.multiple">
						<v-checkbox v-model="attrs['inputValue']" :ripple="false" :key="component"></v-checkbox>
					</v-list-item-action>

					<v-list-item-title> {{ $t(data[$props.itemText], $props.keyName) }}</v-list-item-title>

					<v-list-item-subtitle v-if="$props.itemSubtitle || data['description']" class="caption">
						{{ data[$props.itemSubtitle] || data['description'] }}
					</v-list-item-subtitle>
					<v-list-item-subtitle v-else-if="Array.isArray(data.tags)" class="caption">
						<v-chip v-for="tag in data.tags" :key="tag.id" class="mr-1" label x-small light>
							{{ tag }}
						</v-chip>
					</v-list-item-subtitle>
				</v-list-item>
			</template>

			<template v-slot:selection="{ item: data, index }">
				<div>
					<v-chip v-if="index < 3" label small :disabled="$props.disabled">
						{{ $t(data[$props.itemText], $props.keyName) }}
					</v-chip>
					<span v-if="index === 3 && Array.isArray(data)" class="grey--text caption">{{ `(+ ${state.item.length - 3} ${$t('General_others')})` }}</span>
				</div>
			</template>
		</v-autocomplete>

		<!-- Value -->
		<!-- TODO: Fix translations error -->
		<!-- <div :id="$props.id + '_value'" class="d-none" v-text="Array.isArray(state.item) ? state.item.map(e => e[$props.itemText]).join(', ') : $t(state.item)" /> -->
	</span>
</template>

<script setup>
	import AInputLabel from './AInputLabel.vue'
	import { defineEmits, defineProps, ref, reactive, computed, watch } from 'vue'
	import { useGeneral, useInput } from '@/hooks'
	import * as UTIL from '@/util'

	const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'clear'])
	const $props = defineProps({
		modelValue: { type: [String, Number, Boolean, Object, Array] },
		keyName: { type: String, default: '' },
		id: { type: String, default: '' },
		label: { type: String, default: '' },
		hint: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		prefix: { type: String, default: '' },
		suffix: { type: String, default: '' },
		prependIcon: { type: String, default: '' },
		appendIcon: { type: String, default: '' },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
		itemSubtitle: { type: String, default: '' },
		minLength: { type: Number, default: null },
		maxLength: { type: Number, default: null },
		required: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		filter: { type: Boolean, default: false },
		returnObject: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		cellEditor: { type: Boolean, default: false },
		items: { type: Array, default: () => [] },
		allowedIds: { type: Array, default: () => [] },
		customerIds: { type: Array, default: () => [] }
	})

	const state = reactive({
		item: null,
		show: false
	})

	const { $t, checkProxyValue } = useGeneral()
	// const { id, label, focused, bgColor, variant, density, rules } = useInput($props, state)
	// TODO: Fix autocomplete not working with rules correctly
	const { id, label, focused, baseColor, bgColor, variant, density } = useInput($props, state)
	const rules = []

	watch(
		() => $props.modelValue,
		val => {
			// @ts-ignore
			state.item = checkProxyValue(val, null)
		}
	)

	const items = computed(() => {
		if ($props.itemText === 'text') {
			return $props.items.map(e => {
				return ['string', 'boolean'].includes(typeof e) ? { text: $t(e, $props.keyName), value: e } : e
			})
		} else {
			return $props.items.map(e => {
				const obj = {
					...e
				}
				obj[$props.itemText] = $t(e[$props.itemText], $props.keyName)
				return obj
			})
		}
	})

	function init() {
		const value = checkProxyValue($props.modelValue, $props.multiple ? [] : null)
		if (typeof value === 'boolean' || value) state.item = value
	}
	init()

	function onUpdateValue() {
		$emit('update:modelValue', state.item)
	}
	function onInput() {
		onUpdateValue()
		$emit('input', state.item)
	}
	function onChange() {
		onUpdateValue()
		$emit('change', state.item)
	}
	function onBlur() {
		onUpdateValue()
		$emit('blur', state.item)
		focused.value = false
	}
	function onClear() {
		$emit('clear')
	}
	function onFocus() {
		focused.value = true
	}
</script>
