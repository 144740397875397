import type { ObjectContext } from '@/global'
import type { ComputedRef, WritableComputedRef } from 'vue'
import { isProxy, toRaw, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import moment from 'moment'

export default function () {
	// * Moment
	const $moment: any = moment

	// * Store
	const $store: any = useStore()

	// * Router
	const $route: any = useRoute()
	const $router: any = useRouter()

	function goToMainView() {
		const tab = $route.params.tab
		const routeName = $route.name.replace('_edit', '').replace('_copy', '')
		$router.replace({ name: routeName, params: { tab } })
	}

	// * i18n
	const $i18n: any = useI18n()
	// const $t: any = $i18n.t
	const $te: any = $i18n.te
	const t: any = $i18n.t
	const locale: any = computed(() => $i18n.locale)

	function setLocale(lang: string) {
		$i18n.locale.value = lang
	}

	function translationExist(str: string, params = {}) {
		return $te(`${str}`)
			? t(`${str}`, params)
			: // General
			$te(`General_${str}`)
			? t(`General_${str}`, params)
			: // Action
			$te(`Action_${str}`)
			? t(`Action_${str}`, params)
			: // Form
			$te(`Form_${str}`)
			? t(`Form_${str}`, params)
			: // Route
			$te(`Route_${str}`)
			? t(`Route_${str}`, params)
			: str
	}

	function valuesTranslation(keyName: string, value: string, params = {}): string {
		return $te(`_values_translation.${keyName}`) && $te(`_values_translation.${keyName}.${value}`)
			? $t(`_values_translation.${keyName}.${value}`, null, params)
			: translationExist(value, params)
	}

	function $t(value: string, keyName?: string | null, params = {}) {
		return keyName ? valuesTranslation(keyName, value, params) : translationExist(value, params)
	}

	// * Dictionary
	const dictionary = computed(() => $store.getters['dictionary/GET_ITEMS_KEYS'])

	// * Theme
	const density: ComputedRef<any> = computed(() => $store.getters['theme/GET_DENSITY'])

	// * Icons
	const icons: ComputedRef<ObjectContext> = computed(() => $store.getters['GET_ICONS'])

	// * General
	const user: ComputedRef = computed(() => $store.getters['auth/USER'])
	const isNew: ComputedRef<boolean> = computed(() => $route.params.id === 'create')
	const isEdit: ComputedRef<boolean> = computed(() => $route.name.includes('_edit'))
	const isCopy: ComputedRef<boolean> = computed(() => $route.name.includes('_copy'))
	const isDetail: ComputedRef<boolean> = computed(() => {
		const tabs = ['persons']
		return $route.name.includes('_edit') && tabs.includes($route.params.tab)
	})
	const loading: WritableComputedRef<boolean> = computed({
		get: () => $store.getters['GET_LOADING'],
		set: val => $store.commit('SET_LOADING', val)
	})
	const tabSelected: WritableComputedRef<string> = computed({
		get: () => $store.getters['GET_TAB_SELECTED'],
		set: val => $store.commit('SET_TAB_SELECTED', val)
	})

	// * Vuetify
	const display: any = useDisplay()
	const isMobile: ComputedRef<boolean> = computed(() => display.mobile.value)
	const isTablet: ComputedRef<boolean> = computed(() => display.mdAndDown.value)
	const isDisplayXl: ComputedRef<boolean> = computed(() => display.name === 'xlOnly')

	// * Misc
	const mainClick: ComputedRef = computed(() => $store.getters['GET_MAIN_CLICK'])

	function checkProxyValue(value: any, defaultValue?: any) {
		if (!isProxy(value)) return value
		else {
			const val = toRaw(value)
			return JSON.stringify(val) === '{}' ? defaultValue || null : val
		}
	}

	return {
		$moment,
		$store,
		$route,
		$router,
		goToMainView,
		// * Locale
		$i18n,
		$t,
		$te,
		locale,
		setLocale,
		translationExist,
		valuesTranslation,
		// * Dictionary
		dictionary,
		// * Theme
		density,
		// * General
		icons: icons.value,
		user,
		isNew,
		isEdit,
		isCopy,
		isDetail,
		loading,
		tabSelected,
		// * Display
		isMobile,
		isTablet,
		isDisplayXl,
		// * Misc
		mainClick,
		checkProxyValue
	}
}
