<template>
	<v-overlay :modelValue="showLoading" class="align-center justify-center" style="z-index: 99999 !important">
		<v-progress-circular :width="5" :size="150" color="success" indeterminate style="z-index: 99999 !important"></v-progress-circular>
	</v-overlay>
</template>

<script setup>
	import { defineProps, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	const { loading } = useGeneral()

	const $props = defineProps({
		modelValue: { type: Boolean, default: false }
	})

	const showLoading = computed(() => $props.loading || loading.value)
</script>
