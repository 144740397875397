import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

import * as UTIL from '@/util'

export default {
	namespaced: true,
	state: {
		unique_values: {},
		unique_columns: [],
		selected_columns: {},
		selected_filters: {},
		filters: {},
		sort_columns: {}
	},
	getters: {
		GET_UNIQUE_VALUES(state: VuexStateContext) {
			const obj = {}
			Object.keys(state.unique_values).forEach(key => {
				const items = state.unique_values[key].map((e: any) => {
					const obj = {
						text: UTIL.valuesTranslation(key, e),
						value: e
					}
					return e.text ? e : obj
				})
				// @ts-ignore
				obj[key] = items
			})
			return obj
		},
		GET_UNIQUE_COLUMNS(state: VuexStateContext) {
			return state.unique_columns
		},
		GET_AVAILABLE_UNIQUE_COLUMNS(state: VuexStateContext) {
			return state.unique_columns
		},
		GET_SELECTED_COLUMNS(state: VuexStateContext) {
			return state.selected_columns
		},
		GET_SELECTED_FILTERS(state: VuexStateContext) {
			return state.selected_filters
		},
		GET_FILTERS(state: VuexStateContext) {
			return state.filters
		},
		GET_SORT_COLUMNS(state: VuexStateContext) {
			return state.sort_columns
		}
	},
	mutations: {
		SET_UNIQUE_VALUES(state: VuexStateContext, value: ObjectContext) {
			state.unique_values = Object.assign({}, value)
		},
		SET_UNIQUE_COLUMNS(state: VuexStateContext, value: Array<string>) {
			state.unique_columns = JSON.parse(JSON.stringify(value))
		},
		SET_SELECTED_COLUMNS(state: VuexStateContext, value: ObjectContext) {
			state.selected_columns = Object.assign({}, value)
		},
		SET_SELECTED_FILTERS(state: VuexStateContext, value: ObjectContext) {
			state.selected_filters = Object.assign({}, value)
		},
		SET_FILTERS(state: VuexStateContext, value: ObjectContext) {
			state.filters = Object.assign({}, value)
		},
		INIT_SORT_COLUMNS(state: VuexStateContext) {
			state.sort_columns = {}
		},
		SET_SORT_COLUMNS(state: VuexStateContext, value: ObjectContext) {
			state.sort_columns = Object.assign({}, value)
		}
	},
	actions: {
		async getUniqueValues({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			const obj: ObjectContext = {
				...params,
				return_id_label: true
			}
			if (!obj.query) obj.query = {}

			try {
				const body: ObjectContext = {
					...obj
				}
				commit('SET_UNIQUE_VALUES', {})
				// @ts-ignore
				const res = await this.$axios.post('unique_values', body)
				const data = res.data.response_json
				commit('SET_UNIQUE_VALUES', data)
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { action: 'get', error }, { root: true })
			}
		}
	}
}
