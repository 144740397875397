import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

import mixpanel from 'mixpanel-browser'
import fb from '@/config/firebase.js'
import * as UTIL from '@/util'
import { logRocket } from '@/plugins/logRocket'
import { i18n } from '@/plugins/vuetify-i18n'
const $t = i18n.global.t
const mergeLocaleMessage = i18n.global.mergeLocaleMessage

declare global {
	interface Window {
		analytics: any
	}
	interface Window {
		stonlyTrack: any
	}
}

export default {
	namespaced: true,
	state: {
		user: {},
		config: {}
	},
	getters: {
		USER(state: VuexStateContext) {
			return state.user || {}
		}
	},
	mutations: {
		SET_VAR(state: VuexStateContext, params: ObjectContext) {
			const key = params.key
			const value = params.value
			state[key] = value
		},
		SET_USER(state: VuexStateContext, value: ObjectContext) {
			state.user = value
		},
		SET_CONFIG(state: VuexStateContext, value: ObjectContext) {
			state.config = value
		},
		clearLocalStorage(state: VuexStateContext) {
			state.user = {}
			state.config = {}
			let formInputsValues: string | null = localStorage.getItem('formInputsValues')
			localStorage.clear()
			if (formInputsValues) {
				formInputsValues = JSON.parse(formInputsValues)
				localStorage.setItem('formInputsValues', JSON.stringify(formInputsValues))
			}
		}
	},
	actions: {
		async getUser({ rootState, commit, dispatch }: VuexActionContext) {
			const currentUser = localStorage.getItem('uid')
			if (!currentUser) dispatch('logout')
			else {
				try {
					// @ts-ignore
					const res = await this.$axios.get('me')
					const user = res.data.response_json || {}

					commit('SET_USER', user)

					const config: ObjectContext = {}

					// Logout Inactive User
					if (user.is_active_user === false) {
						dispatch('logout')
						return
					}

					// Set Config
					localStorage.setItem('config', JSON.stringify(config))
					commit('SET_CONFIG', config)
					// Load User Roles Translations
					const roles = config.new_role_list || {}
					Object.keys(roles).forEach(role => {
						const translations = roles[role]
						Object.keys(translations).forEach(locale => {
							const name: ObjectContext = {}
							name[role] = translations[locale].name
							const description: ObjectContext = {}
							description[role + '_description'] = translations[locale].description
							mergeLocaleMessage(locale, name)
							mergeLocaleMessage(locale, description)
						})
					})

					// Set Filters
					const filters = user['_filters']
					const selected_filters = user['_selected_filters']
					if (filters) commit('filters/SET_FILTERS', filters, { root: true })
					if (selected_filters) commit('filters/SET_SELECTED_FILTERS', selected_filters, { root: true })

					// Set Columns
					const columns = user['_columns']
					if (columns) commit('filters/SET_SELECTED_COLUMNS', columns, { root: true })

					// Route
					// @ts-ignore
					const currentRoute: ObjectContext = this.$router?.currentRoute?.value

					// Load Locale
					await dispatch('loadLocale', null, { root: true })

					// Load Time Zone
					await dispatch('loadTimeZone', null, { root: true })

					// Login / SignUp
					const isLogin = currentRoute.name === 'login'
					const isSignUp = currentRoute.name === 'signup'
					const isResetPassword = currentRoute.name === 'reset-password'
					if (isLogin || isSignUp || isResetPassword) {
						// @ts-ignore
						this.$router.push({ name: 'sustainability', params: { tab: 'warehouse_evaluations' } })
						// this.$router.push({ name: 'records', params: { tab: 'group_reports' } })
					}

					// Enable User Tracking
					// if (UTIL.isTrackingEnabled()) {
					// mixpanel.reset()
					// // LogRocket
					// logRocket.identify(user.username, user)
					// // Mixpanel
					// mixpanel.people.set(user)
					// // identify must be called along with every instance of people.set
					// mixpanel.identify(user.username)
					// // Segment
					// const segment = window.analytics
					// if (segment) segment.identify(user.username, user)
					// // Stonly
					// const stonly = window.stonlyTrack
					// if (stonly) stonly('identify', user.username, user)
					// }
				} catch (error) {
					console.log(`🚀 ~ error`, error)
				}
			}
		},
		async signUp({ rootState, commit, dispatch }: VuexActionContext, params: VuexParamsContext) {
			commit('SET_LOADING', true, { root: true })

			const user = params.user
			const toLogin = params.toLogin
			const body = {
				...user,
				signup_origin: 'web' // 'app' / 'web'
			}

			try {
				// @ts-ignore
				await this.$axios.post('signup_v2', body)
				dispatch('alert/SET_ALERT', { color: 'success', text: $t('Login_success_signup_message') }, { root: true })

				// Enable User Tracking
				// if (UTIL.isTrackingEnabled()) {
				// 	// Mixpanel
				// 	mixpanel.track('new_user_web')

				// 	// Segment
				// 	const segment = window.analytics
				// 	if (segment) segment.track('new_user_web')

				// 	// Stonly
				// 	const stonly = window.stonlyTrack
				// 	if (stonly) stonly('new_user_web')
				// }

				toLogin()
				dispatch(
					'alert/SET_ALERT',
					{
						color: 'success',
						close: true,
						text: $t('Login_success_signup_notify_message_html')
					},
					{ root: true }
				)
			} catch (error) {
				dispatch(
					'alert/SET_ALERT',
					{
						text: $t('Error_user_already_exist', { email: body.email }),
						error: error,
						close: true
					},
					{ root: true }
				)
				params.step = 1
			} finally {
				commit('SET_LOADING', false, { root: true })
			}
		},
		async login({ rootState, commit, dispatch }: VuexStateContext, params: VuexParamsContext) {
			const email: string = params.email
			const password: string = params.password
			commit('SET_LOADING', true, { root: true })
			try {
				const user: ObjectContext = await fb.auth.signInWithEmailAndPassword(email, password)
				const currentUser = fb.auth.currentUser
				if (currentUser) {
					const token = user?.user?.Aa
					localStorage.setItem('user', JSON.stringify(user))
					localStorage.setItem('token', token)
					localStorage.setItem('uid', user?.user?.uid)

					const version = rootState.version
					localStorage.setItem('version', JSON.stringify(version))

					await dispatch('getUser')
				}
			} catch (error: any) {
				const code = error?.code
				const message = error?.message
				const alert: ObjectContext = { text: message || code }
				if (code === 'auth/wrong-password') alert.text = $t('Error_user_or_password_incorrect')
				else if (code === 'auth/user-disabled') alert.text = $t('Error_user_not_active_html')
				else if (!code && !message) {
					delete alert.text
					alert.error = error
				}

				dispatch('alert/SET_ALERT', alert, { root: true })
				dispatch('logout')
			} finally {
				commit('SET_LOADING', false, { root: true })
			}
		},
		async logout({ commit, dispatch }: VuexActionContext) {
			commit('SET_LOADING', true, { root: true })
			fb.auth.signOut()
			commit('clearLocalStorage')

			// Init Store
			// commit('INIT_STATE', null, { root: true })
			dispatch('alert/SHOW_ALERT', false, { root: true })

			// Check route
			// @ts-ignore
			const currentRoute: ObjectContext = this.$router?.currentRoute?.value
			const route: ObjectContext = currentRoute?.name?.toLowerCase()
			// @ts-ignore
			if (route && !route.includes('login')) this.$router.replace({ name: 'login' })
			commit('SET_LOADING', false, { root: true })
		}
	}
}
