import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		list: []
	},
	getters: {},
	mutations: {
		SET_LIST(state: VuexStateContext, value: Array<object>) {
			state.list = JSON.parse(JSON.stringify(value))
		}
	},
	actions: {
		async getList({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			const obj: ObjectContext = params || {}
			if (!obj.query) obj.query = {}

			try {
				const body: ObjectContext = {
					...obj,
					return_type: 'customers'
				}
				commit('SET_LIST', [])
				// @ts-ignore
				const res = await this.$axios.post('query/customers', body)
				const data = res.data.response_json
				const items = data['customers']
				commit('SET_LIST', items)
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { action: 'get', error }, { root: true })
			}
		},
		async get(ctx: VuexActionContext, id: number): Promise<ObjectContext> {
			// @ts-ignore
			const res = await this.$axios.get(`customers_v2/${id}`)
			return res.data.response_json
		},
		async create(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const item = params._item
			const body: ObjectContext = Object.assign({}, item || params)
			// @ts-ignore
			return await this.$axios.post('customers', body)
		},
		async update(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = params._item
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : item ? (body = Object.assign({}, item)) : (body = Object.assign({}, params))
			delete body._item
			delete body.associated_files
			// @ts-ignore
			return await this.$axios.patch(`customers_v2/${params.customer_id}`, body)
		},
		async delete(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`customers_v2/${params.customer_id}`)
		}
	}
}
