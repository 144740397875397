<template>
	<ODialog v-model="state.dialog" :title="dialogTitle" small @update:modelValue="onUpdateValue($event)">
		<slot v-if="!!$slots.default" />
		<template v-else>
			<div v-html="bodyText"></div>
			<div v-if="$props.confirmDelete" class="error--text">
				<div v-if="isUserProfile" v-html="$t('Confirmation_delete_account_html')" />
			</div>
		</template>

		<template #actions>
			<v-spacer />
			<AButtonSecondary :text="buttonTextSecondary" :color="buttonColorSecondary" @click="onConfirmSecondary()" />
			<AButtonPrimary :text="buttonText" :color="buttonColor" @click="onConfirm()" />
		</template>
	</ODialog>
</template>

<script setup>
	import AButtonPrimary from '@/components/atoms/AButtonPrimary.vue'
	import AButtonSecondary from '@/components/atoms/AButtonSecondary.vue'
	import ODialog from '@/components/organism/ODialog.vue'

	import { defineEmits, defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral } from '@/hooks'

	const { $t, $route, tabSelected } = useGeneral()

	const $emit = defineEmits(['update:modelValue', 'close', 'copy', 'delete', 'confirm', 'confirmSecondary'])
	const $props = defineProps({
		modelValue: { type: Boolean, default: false },
		title: { type: String, default: '' },
		text: { type: String, default: 'Confirmation_action_html' },
		element: { type: String, default: 'record' },
		action: { type: String, default: '' },
		buttonText: { type: String, default: '' },
		buttonColor: { type: String, default: 'success' },
		buttonTextSecondary: { type: String, default: '' },
		buttonColorSecondary: { type: String, default: 'error' },
		editMode: { type: Boolean, default: null },
		copyItem: { type: Boolean, default: false },
		multiple: { type: Boolean, default: false },
		confirmCopy: { type: Boolean, default: false },
		confirmDelete: { type: Boolean, default: false },
		confirmSave: { type: Boolean, default: false },
		confirmCreate: { type: Boolean, default: false },
		tabFields: { type: Boolean, default: false },
		historyTable: { type: Boolean, default: false }
	})

	const state = reactive({
		dialog: false
	})

	watch(
		() => $props.modelValue,
		val => (state.dialog = val)
	)
	watch(
		() => $props.dialog,
		val => {
			if (!val) close()
		}
	)

	function onUpdateValue(val) {
		$emit('update:modelValue', val)
	}
	function onClose() {
		onUpdateValue(false)
		$emit('close')
	}
	function onConfirm() {
		$props.confirmCopy ? $emit('copy') : $props.confirmDelete ? $emit('delete') : $emit('confirm')
		onClose()
	}
	function onConfirmSecondary() {
		$emit('confirmSecondary')
		onClose()
	}

	const isUserProfile = computed(() => !$route.name === 'user-profile')

	const actionText = computed(() => {
		let action = ''
		if ($props.confirmSave) {
			action = 'Action_save'
			if (typeof $props.editMode === 'boolean') {
				action = $props.editMode ? 'Action_edit' : 'Action_create'
			}
			if ($props.copyItem) action = 'Action_copy'
		}
		if ($props.confirmDelete) action = 'Action_delete'
		if ($props.confirmCopy) action = 'Action_copy'
		if ($props.confirmCreate) action = 'ConfirmationDialog_created_action_primary'
		if ($props.action) action = $props.action
		return action
	})

	const buttonText = computed(() => {
		let text = $props.buttonText || actionText.value
		if ($props.confirmDelete) {
			text = $props.multiple ? 'delete_current_record' : 'Action_delete'
		}
		return $t(text)
	})
	const buttonColor = computed(() => {
		let color = $props.buttonColor
		if ($props.confirmDelete) color = 'error'
		return color
	})

	const buttonTextSecondary = computed(() => {
		let text = 'Action_cancel'
		if ($props.confirmCreate) text = 'ConfirmationDialog_created_action_secondary'
		if ($props.buttonTextSecondary) text = $props.buttonTextSecondary
		return $t(text)
	})
	const buttonColorSecondary = computed(() => {
		let color = $props.buttonColorSecondary
		return color
	})

	const dialogTitle = computed(() => {
		let element = $t($props.element).toLowerCase()
		let title = $t('Action_confirm')
		if ($props.title) title = $props.title
		else if ($props.confirmCreate) {
			element = element.charAt(0).toUpperCase() + element.slice(1)
			title = $t('ConfirmationDialog_created_title', null, { element })
		} else if (actionText.value) title = $t(actionText.value) + ' ' + element
		return $t(title)
	})

	const bodyText = computed(() => {
		const action = $t(actionText.value)
		const element = $t($props.element).toLowerCase()
		let text = $t($props.text, null, { action, element })
		if ($props.confirmCreate) text = $t('ConfirmationDialog_created_body_html', null, { element })
		return text
	})
</script>
