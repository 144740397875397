<template>
	<div>
		<v-menu anchor="bottom">
			<template #activator="{ props }">
				<v-icon v-bind="props" :icon="icons['table_options']" />
				<!--  TODO: Use AButtonIcon component -->
				<!-- <v-btn v-bind="props" variant="text" icon>
					<v-icon :icon="icons['table_options']" />
				</v-btn> -->
			</template>
			<!-- TODO: Fix AList component not working -->
			<!-- <AList :items="items" @click="onClick($event)" /> -->
			<v-list :density="density">
				<v-list-item
					:title="EDIT_VISIBLE ? $t('Action_view') + '/' + $t('Action_edit') : $t('Action_view')"
					:prepend-icon="icons['summary']"
					:disabled="VIEW_DISABLED"
					@click="onClick('view')"
				/>

				<v-list-item v-if="showCopy" :title="$t('Action_copy')" :prepend-icon="icons['copy']" :disabled="CREATE_DISABLED" @click="onClick('copy')" />

				<v-list-item :title="$t('Action_delete')" :prepend-icon="icons['delete']" :disabled="DELETE_DISABLED" @click="onClick('delete')" />
			</v-list>
		</v-menu>
	</div>
</template>

<script setup>
	import AList from '@/components/atoms/AList.vue'

	import { defineProps, computed } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	const { $t, icons, tabSelected, density } = useGeneral()
	const { EDIT_VISIBLE, VIEW_DISABLED, CREATE_DISABLED, DELETE_DISABLED } = useRole(tabSelected.value)

	const $props = defineProps({
		params: { type: Object, default: null, required: true }
	})

	const showCopy = computed(() => !['group_reports', 'persons'].includes(tabSelected.value))

	// const cellFiled = computed(() => $props.params?.colDef.field || '')
	// const editable = computed(() => $props.params?.colDef.editable || false)
	const item = computed(() => $props.params?.data || {})

	// const items = computed(() => {
	// 	return [
	// 		{
	// 			text: EDIT_VISIBLE.value ? $t('Action_view') + '/' + $t('Action_edit') : $t('Action_view'),
	// 			icon: icons.value['summary'],
	// 			disabled: VIEW_DISABLED.value,
	// 			value: 'view'
	// 		},
	// 		{
	// 			text: $t('Action_copy'),
	// 			icon: icons.value['copy'],
	// 			disabled: CREATE_DISABLED.value,
	// 			value: 'copy'
	// 		},
	// 		{
	// 			text: $t('Action_delete'),
	// 			icon: icons.value['delete'],
	// 			disabled: DELETE_DISABLED.value,
	// 			value: 'delete'
	// 		}
	// 	]
	// })

	function onClick(value) {
		if (value === 'view') viewClick()
		if (value === 'copy') copyClick()
		if (value === 'delete') deleteClick()
	}
	function viewClick() {
		if ($props.params?.viewClick instanceof Function) {
			$props.params.viewClick(item.value)
		}
	}
	function copyClick() {
		if ($props.params?.copyClick instanceof Function) {
			$props.params.copyClick(item.value)
		}
	}
	function deleteClick() {
		if ($props.params?.deleteClick instanceof Function) {
			$props.params.deleteClick(item.value)
		}
	}
</script>
