<template>
	<div class="pa-4">
		<v-form ref="refForm" @submit.prevent>
			<MDynamicInput
				v-model="state.item[cellField]"
				cellEditor
				:key-name="cellField"
				:tabSelected="tabSelected"
				@clear="onEnter($event)"
				@enter="onEnter($event)"
			/>
		</v-form>
	</div>
</template>

<script>
	import MDynamicInput from '@/components//molecules/MDynamicInput.vue'

	import * as UTIL from '@/util'

	import { defineComponent, getCurrentInstance, ref, reactive, computed } from 'vue'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'DefaultCellEditor',
		components: { MDynamicInput },
		setup() {
			const instance = getCurrentInstance()
			const props = instance.props

			const state = reactive({
				item: {}
			})

			const params = computed(() => props.params || {})
			const tabSelected = computed(() => params.value?.tabSelected || '')
			const cellField = computed(() => params.value.colDef?.field || '')
			const editable = computed(() => params.value.colDef?.editable || false)
			const item = computed(() => params.value.data || {})
			const value = computed(() => item.value[cellField.value])

			function init() {
				state.item = Object.assign({}, item.value)
			}
			init()

			function getValue() {
				return state.item[cellField.value]
			}

			const refForm = ref(null)

			async function onEnter() {
				// @ts-ignore
				const res = await refForm.value.validate()
				const isValid = res.valid
				params.value.data._isValid = isValid
			}

			return {
				UTIL,
				state,
				tabSelected,
				cellField,
				editable,
				item,
				value,
				getValue,
				refForm,
				onEnter
			}
		}
	})
</script>
