import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		list: []
	},
	getters: {},
	mutations: {
		SET_LIST(state: VuexStateContext, value: Array<object>) {
			state.list = JSON.parse(JSON.stringify(value))
		}
	},
	actions: {
		async getList({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			const obj: ObjectContext = params || {}
			if (!obj.query) obj.query = {}
			try {
				const body: ObjectContext = {
					...obj
				}
				commit('SET_LIST', [])
				// @ts-ignore
				const res = await this.$axios.post('query/documents', body)
				const data = res.data.response_json
				const items = data['persons']
				commit('SET_LIST', items)
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { action: 'get', error }, { root: true })
			}
		}
	}
}
