<template>
	<AButtonSecondary :icon="icons['columns']" :disabled="loading" @click="onToggleDrawer()" />
	<v-navigation-drawer v-model="state.drawer" class="text-primary" location="right" :width="344" temporary>
		<v-card v-if="state.drawer" flat>
			<v-container>
				<v-row align="center">
					<v-col cols="9" class="text-left text-h6 text-primary pl-4">
						{{ $t('ColumnsDrawer_title') }}
					</v-col>

					<v-col cols="3" class="text-right">
						<AButtonIcon size="small" :icon="icons['close']" @click="onClose()" />
					</v-col>
				</v-row>

				<v-divider color="primary_alt" />

				<template v-for="section in state.sections" :key="section.id">
					<v-row v-if="columns[section.key].length > 0" no-gutters align="center">
						<v-col cols="12" class="text-left font-weight-bold text-primary pt-6 pl-2">
							{{ $t(section.title) }}
						</v-col>

						<template v-for="key in columns[section.key]" :key="key.id">
							<v-col cols="10" class="text-left pl-2">
								{{ $t(key) }}
							</v-col>

							<v-col cols="2">
								<ASwitch v-model="state.selected_columns[key]" density="compact" />
							</v-col>
						</template>
					</v-row>
				</template>
			</v-container>
		</v-card>

		<template #append>
			<v-container>
				<AButtonPrimary :text="$t('Action_apply')" block @click="saveColumns()" />
			</v-container>
		</template>
	</v-navigation-drawer>
</template>

<script setup>
	import ASwitch from '@/components/atoms/ASwitch.vue'
	import AButtonIcon from '@/components/atoms/AButtonIcon.vue'
	import AButtonPrimary from '@/components/atoms/AButtonPrimary.vue'
	import AButtonSecondary from '@/components/atoms/AButtonSecondary.vue'

	import { defineEmits, defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'change'])
	const $props = defineProps({
		tabSelected: { type: String, default: '', required: true },
		modelValue: { type: Boolean, default: false }
	})

	const { $t, $store, icons, loading, dictionary } = useGeneral()

	const state = reactive({
		drawer: false,
		selected_columns: {},
		sections: [
			{ title: 'ColumnsDrawer_default_columns', key: 'default' },
			{ title: 'ColumnsDrawer_custom_columns', key: 'custom' }
		]
	})

	watch(
		() => $props.modelValue,
		val => (state.drawer = val)
	)

	const uniqueColumns = computed(() => $store.getters['filters/GET_AVAILABLE_UNIQUE_COLUMNS'])

	const columns = computed(() => {
		const items = {
			default: [],
			custom: []
		}
		uniqueColumns.value.forEach(key => {
			const dictionaryItem = dictionary.value[key]
			if (!dictionaryItem) items.custom.push(key)
			else {
				let isVisible = false
				let isTableColumn = false

				const defaultValidation = dictionaryItem.validation.default
				const tabValidation = dictionaryItem.validation[$props.tabSelected] || {}

				if (tabValidation.visible === true) isVisible = true
				else if (defaultValidation.visible === true) isVisible = true

				if (tabValidation.table_column === true) isTableColumn = true
				else if (defaultValidation.table_column === true) isTableColumn = true

				if (isVisible && isTableColumn) items.default.push(key)
			}
		})
		return items
	})

	function onClose() {
		state.drawer = false
		$emit('update:modelValue', false)
	}

	watch(
		() => state.drawer,
		val => {
			if (!val) onClose()
		}
	)

	function onToggleDrawer() {
		state.drawer = !state.drawer
	}

	const user = computed(() => $store.getters['auth/USER'])
	const userColumns = computed(() => user.value['_columns'] || {})

	function loadColumns() {
		state.selected_columns = {}
		const tabColumns = userColumns.value[$props.tabSelected] || []
		tabColumns.forEach(key => (state.selected_columns[key] = true))
	}

	watch(
		() => state.drawer,
		val => {
			if (val) loadColumns()
		}
	)

	async function saveColumns() {
		const columns = []
		const keys = Object.keys(state.selected_columns)
		keys.forEach(key => {
			if (state.selected_columns[key] === true) columns.push(key)
		})
		const newColumns = Object.assign({}, userColumns.value || {})
		newColumns[$props.tabSelected] = columns
		loading.value = true
		const newUser = Object.assign({}, user.value)
		newUser['_columns'] = newColumns
		const body = {
			person_id: newUser.person_id,
			_item: newUser,
			_keys: ['_columns']
		}
		$store.commit('auth/SET_USER', newUser)
		$store.commit('filters/SET_SELECTED_COLUMNS', newColumns)
		await $store.dispatch('persons/update', body)
		onClose()
		$emit('change', newColumns)
	}
</script>
