<template>
	<span>
		<AInputLabel v-if="label && !$props.cellEditor" :label="label" :required="$props.required" />
		<v-text-field
			:id="id"
			v-model="state.item"
			:color="baseColor"
			:bg-color="bgColor"
			:variant="variant"
			:density="density"
			:rules="rules"
			:loading="$props.loading"
			:disabled="$props.disabled"
			:prefix="$props.prefix"
			:suffix="$props.suffix"
			:hint="$props.hint"
			:persistent-hint="!!$props.hint"
			:placeholder="$props.placeholder"
			:prepend-inner-icon="$props.prependIcon"
			:append-inner-icon="inputAppendIcon"
			:type="inputType"
			:autocomplete="inputAutocomplete"
			:autofocus="$props.celllEditor"
			:hide-details="hideDetails"
			@update:modelValue="onUpdateValue()"
			@click:append-inner="toggleShow()"
			@input="onInput()"
			@change="onChange()"
			@blur="onBlur()"
			@focus="onFocus()"
			@keydown.enter="onEnter()"
		/>

		<!-- Value -->
		<div :id="$props.id + '_value'" class="d-none" v-text="state.item" />
	</span>
</template>

<script setup>
	import AInputLabel from './AInputLabel.vue'
	import { defineEmits, defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral, useInput } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'clear', 'enter'])
	const $props = defineProps({
		modelValue: { type: [String, Object] },
		keyName: { type: String, default: '' },
		id: { type: String, default: '' },
		label: { type: String, default: '' },
		hint: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		prefix: { type: String, default: '' },
		suffix: { type: String, default: '' },
		prependIcon: { type: String, default: '' },
		appendIcon: { type: String, default: '' },
		type: { type: String, default: '' },
		minLength: { type: Number, default: null },
		maxLength: { type: Number, default: null },
		required: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		cellEditor: { type: Boolean, default: false },
		equalTo: { type: [String, Number], default: null }
	})

	const state = reactive({
		item: null,
		show: false
	})

	const { checkProxyValue } = useGeneral()
	const { id, label, focused, baseColor, bgColor, variant, density, rules } = useInput($props, state)

	watch(
		() => $props.modelValue,
		val => (state.item = checkProxyValue(val, null))
	)

	function init() {
		const value = checkProxyValue($props.modelValue, null)
		if (value) state.item = value
	}
	init()

	const inputAutocomplete = computed(() => ($props.type === 'email' ? 'new-password' : ''))
	const inputType = computed(() => ($props.type === 'password' ? (state.show ? 'text' : 'password') : $props.type))
	const inputAppendIcon = computed(() => ($props.type === 'password' ? (state.show ? 'mdi-eye' : 'mdi-eye-off') : $props.appendIcon))

	function toggleShow() {
		state.show = !state.show
	}

	function onUpdateValue() {
		$emit('update:modelValue', state.item)
	}
	function onInput() {
		onUpdateValue()
		$emit('input', state.item)
	}
	function onChange() {
		onUpdateValue()
		$emit('change', state.item)
	}
	function onBlur() {
		onUpdateValue()
		$emit('blur', state.item)
		focused.value = false
	}
	function onFocus() {
		focused.value = true
	}
	function onEnter() {
		$emit('enter')
	}
</script>
