<template>
	<v-card variant="contained-flat" color="grey-lighten-4" min-height="500">
		<v-skeleton-loader v-if="state.loading" class="profile-picture" type="image" min-height="500"></v-skeleton-loader>
		<v-img v-else cover height="500px" :loading="state.loading" :src="personPicture" :key="state.component">
			<input ref="refUploadInput" class="d-none" type="file" accept="image/*" @change="handleUpload($event)" />
			<!-- TODO: Fix button position absolute -->
			<AButtonIcon
				v-if="EDIT_VISIBLE"
				class="mb-8"
				:icon="icons['edit']"
				:disabled="loading || EDIT_DISABLED"
				filled
				@click="handleUpload()"
				style="position: absolute; left: 90% !important; bottom: 0"
			/>
		</v-img>
	</v-card>
	<v-card class="mt-4" variant="outlined">
		<v-row align="center" justify="center">
			<v-col cols="9" class="pl-12">
				<v-skeleton-loader v-if="loading" type="text"></v-skeleton-loader>
				<span v-else>{{ $t('can_work') }}</span>
			</v-col>
			<v-col cols="3">
				<v-row align="center" justify="center" no-gutters>
					<ASwitch v-model="state.item.can_work" :disabled="loading || EDIT_DISABLED || !EDIT_VISIBLE" @update:modelValue="toggleCanWork($event)" />
				</v-row>
			</v-col>
		</v-row>
	</v-card>

	<TConfirmation
		v-model="state.dialog"
		confirm-save
		button-text="Action_replace"
		button-text-secondary="Action_delete"
		button-color-secondary="error"
		title="PersonsDialog_edit_master_picture"
		text="PersonsDialog_edit_master_picture_text"
		@confirm="replacePicture()"
		@confirmSecondary="deletePicture()"
	/>
</template>

<script setup>
	import ASwitch from '@/components/atoms/ASwitch.vue'
	import AButtonIcon from '@/components/atoms/AButtonIcon.vue'
	import TConfirmation from '@/components/templates/TConfirmation.vue'

	import imageCompression from 'browser-image-compression'
	import * as UTIL from '@/util'

	import { defineProps, ref, reactive, computed, watch } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	const { $t, $store, tabSelected, loading, icons } = useGeneral()
	const { EDIT_VISIBLE, EDIT_DISABLED } = useRole(tabSelected.value)

	const $props = defineProps({
		item: { type: Object, default: null, required: true }
	})

	const state = reactive({
		component: 0,
		item: {},
		dialog: false,
		rawFile: null,
		base64File: null,
		fileToUpload: null,
		loading: false,
		dialogConfirm: false,
		canReplacePicture: false
	})

	function init() {
		state.item = Object.assign($props.item || {})
	}
	init()

	watch(
		() => $props.item,
		() => init()
	)

	// @ts-ignore
	const masterPicture = computed(() => state.item.main_picture_object?.base64_content || '')
	const defaultPicture = computed(() => $store.state.default_picture_avatar)

	const personPicture = computed(() => (masterPicture.value ? `data:image/jpeg;base64,${masterPicture.value}` : defaultPicture.value))

	watch(
		() => personPicture.value,
		() => state.component++
	)

	const refUploadInput = ref(null)

	function setPerson(item, key) {
		console.log(`🚀 ~ setPerson ~ item`, item)
		key ? (state.item[key] = item[key]) : (state.item = Object.assign({}, item))
	}

	async function getPerson(key) {
		const person_id = state.item.person_id
		const item = await $store.dispatch(`persons/get`, person_id)
		setPerson(item, key)
	}

	async function updatePerson(params, updatePicture = true, key) {
		console.log(`🚀 ~ updatePerson ~ params`, params)
		state.dialog = false
		try {
			const body = {
				...params
			}
			const action = updatePicture ? 'updatePicture' : 'update'
			body.person_id = state.item.person_id
			const res = await $store.dispatch(`persons/${action}`, body)
			const item = res.data.response_json
			key ? setPerson(item, key) : await getPerson(key)
		} catch (error) {
			if (updatePicture) throw error
			$store.dispatch('alert/SET_ALERT', { error })
			await getPerson(key)
		}
	}

	async function toggleCanWork(event) {
		await updatePerson({ can_work: event }, false, 'can_work')
	}

	async function deletePicture() {
		await updatePerson({ main_picture_object: null })
	}

	async function upload() {
		state.loading = true
		console.log(
			`🚀 ~ upload ~ {
				main_picture_object: {
					file_name: state.rawFile.name,
					base64_content: state.base64File
				}
			}`,
			{
				main_picture_object: {
					file_name: state.rawFile.name,
					base64_content: state.base64File
				}
			}
		)
		try {
			await updatePerson({
				main_picture_object: {
					file_name: state.rawFile.name,
					base64_content: state.base64File
				}
			})
		} catch (error) {
			if (state.canReplacePicture) throw error
			$store.dispatch('alert/SET_ALERT', { error })
		} finally {
			state.loading = false
		}
	}

	async function replacePicture(upload) {
		state.dialog = false
		if (!upload) {
			state.canReplacePicture = true
			handleUpload()
		} else {
			state.loading = true
			try {
				await deletePicture()
				await upload()
			} catch (error) {
				$store.dispatch('alert/SET_ALERT', { error })
				state.loading = false
			} finally {
				state.canReplacePicture = false
			}
		}
	}

	async function handleUpload(e) {
		if (!e) {
			// TODO: Change for production
			masterPicture.value && !state.canReplacePicture ? (state.dialog = !state.dialog) : refUploadInput.value.click()
		} else {
			const files = e.target.files
			const rawFile = files[0] // only use files[0]

			if (!rawFile) return
			else if (!rawFile.type.includes('image')) {
				$store.dispatch('alert/SET_ALERT', { text: $t('Error_file_must_be_an_image') })
				return
			}
			state.rawFile = rawFile

			const imageFile = rawFile
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			const compressedFile = await imageCompression(imageFile, options)

			state.base64File = await UTIL.toBase64(compressedFile)

			// Get File Preview
			const reader = new FileReader()
			reader.onload = event => {
				state.fileToUpload = event.target.result
				refUploadInput.value = null
				console.log(`🚀 ~ handleUpload ~ state.canReplacePicture`, state.canReplacePicture)
				state.canReplacePicture ? replacePicture(true) : upload()
			}
			reader.readAsDataURL(rawFile)
		}
	}
</script>
