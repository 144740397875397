import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

import * as UTIL from '@/util'

import { i18n } from '@/plugins/vuetify-i18n'
const i18nPlugin = i18n
const $i18n = i18nPlugin.global

import { createStore } from 'vuex'
import modules from './modules'

export default createStore({
	modules: modules,
	state: {
		mainClick: 0,
		loading: false,
		tabSelected: 'customers',
		locales: [
			{ text: 'Español', value: 'es-ES', shortand: 'ES' },
			{ text: 'Català', value: 'es-CA', shortand: 'CA' },
			{ text: 'English', value: 'en-GB', shortand: 'EN' },
			{ text: 'Türk', value: 'tr-TR', shortand: 'TR' }
		],
		keyToParse: {
			role_id: 'role',
			customer: 'customer',
			customer_id: 'customer',
			customers: 'customers',
			customer_ids: 'customers',
			person: 'person',
			person_id: 'person',
			persons: 'persons',
			person_ids: 'persons',
			default_group: 'default_group',
			default_group_id: 'default_group',
			group: 'group',
			group_id: 'group',
			groups: 'groups',
			group_ids: 'groups',
			action_defintion: 'action_defintion',
			action_defintion_id: 'action_defintion',
			action_defintions: 'action_defintions',
			action_defintion_ids: 'action_defintions',
			machine: 'machine',
			machine_id: 'machine',
			machine_ids: 'machines',
			machines: 'machines',
			field: 'field',
			field_id: 'field',
			field_ids: 'fields',
			fields: 'fields',
			warehouse: 'warehouse',
			warehouse_id: 'warehouse',
			warehouse_ids: 'warehouses',
			warehouses: 'warehouses'
		},
		keyToParseLabel: {
			role: 'role_name',
			customer: 'customer_name',
			customers: 'customer_name',
			person: 'person_name',
			persons: 'person_name',
			default_group: 'group_name',
			group: 'group_name',
			groups: 'group_name',
			action_defintion: 'action_defintion_name',
			action_defintions: 'action_defintion_name',
			machine: 'machine_name',
			machines: 'machine_name',
			field: 'field_label_id',
			fields: 'field_label_id',
			warehouse: 'warehouse_name',
			warehouses: 'warehouse_name'
		},
		itemIdKey: {
			tasks: 'task_id',
			groups: 'group_id',
			persons: 'person_id',
			machines: 'machine_id',
			customers: 'customer_id',
			action_definitions: 'action_definition_id',
			group_reports: 'group_report_id',
			warehouses: 'warehouse_id',
			warehouse_evaluations: 'warehouse_evaluation_id'
		},
		items: [],
		pagination: {
			page_number: 1,
			items_per_page: 25,
			items_per_page_list: [5, 15, 25],
			total_rows: 0,
			sort_by: [],
			sort_desc: []
		},
		icons: {
			// Actions
			create: 'mdi-plus',
			create_alt: 'mdi-plus-circle',
			edit: 'mdi-pencil',
			copy: 'mdi-file-multiple',
			delete: 'mdi-delete',
			close: 'mdi-close',
			logout: 'mdi-logout',
			restart: 'mdi-restart',
			// Keys
			email: 'mdi-email',
			password: 'mdi-lock',
			// Misc
			openInNewTab: 'mdi-open-in-new',
			summary: 'mdi-clipboard-text',
			customer: 'mdi-domain',
			person: 'mdi-account',
			whatsapp: 'mdi-whatsapp',
			table_options: 'mdi-dots-horizontal',
			googleMaps: 'mdi-google-maps',
			download: 'mdi-download',
			filters: 'mdi-filter',
			columns: 'mdi-view-column',
			import: 'mdi-file-import',
			export: 'mdi-file-export',
			help: 'mdi-help-circle',
			// Arrows
			chevronRight: 'mdi-chevron-right',
			chevronLeft: 'mdi-chevron-left',
			chevronDown: 'mdi-chevron-down',
			chevronUp: 'mdi-chevron-up',
			arrowLeft: 'mdi-arrow-left',
			arrowRight: 'mdi-arrow-right',
			arrowUp: 'mdi-arrow-left',
			arrowDown: 'mdi-arrow-down',
			// Documents
			file: 'mdi-file',
			filePending: 'mdi-file-clock',
			filePDF: 'mdi-file-check',
			attachment: 'mdi-paperclip',
			signed: 'mdi-file-check',
			pending_signature: 'mdi-file-clock',
			pending_upload: 'mdi-file-cancel'
		},
		default_picture_avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Placeholder_no_text.svg/1200px-Placeholder_no_text.svg.png'
	},
	getters: {
		GET_MAIN_CLICK(state: VuexStateContext) {
			return state.mainClick
		},
		GET_LOADING(state: VuexStateContext) {
			return state.loading
		},
		GET_TAB_SELECTED(state: VuexStateContext) {
			return state.tabSelected
		},
		GET_LOCALES(state: VuexStateContext) {
			return state.locales
		},
		GET_KEY_TO_PARSE(state: VuexStateContext) {
			return state.keyToParse
		},
		GET_KEY_TO_PARSE_LABEL(state: VuexStateContext) {
			return state.keyToParseLabel
		},
		GET_ITEM_ID_KEY(state: VuexStateContext) {
			return state.itemIdKey
		},
		GET_VAR_LOCALES(state: VuexStateContext) {
			return state.locales.reduce((obj: ObjectContext, e: ObjectContext) => {
				obj[e.value] = e.text
				return obj
			}, {})
		},
		GET_LOCALES_SHORTAND(state: VuexStateContext) {
			return state.locales.reduce((obj: ObjectContext, e: ObjectContext) => {
				obj[e.value] = e.shortand
				return obj
			}, {})
		},
		GET_ITEMS(state: VuexStateContext) {
			return state.items
		},
		GET_PAGINATION(state: VuexStateContext) {
			return state.pagination
		},
		GET_ICONS(state: VuexStateContext) {
			return state.icons
		}
	},
	mutations: {
		SET_MAIN_CLICK(state: VuexStateContext) {
			state.mainClick++
		},
		SET_LOADING(state: VuexStateContext, value: boolean) {
			state.loading = value
		},
		SET_TAB_SELECTED(state: VuexStateContext, value: string) {
			state.tabSelected = value
		},
		SET_ITEMS(state: VuexStateContext, value: Array<ObjectContext>) {
			state.items = JSON.parse(JSON.stringify(value))
		},
		INIT_PAGINATION(state: VuexStateContext, value: ObjectContext) {
			state.pagination = {
				page_number: 1,
				items_per_page: 25,
				items_per_page_list: [5, 15, 25],
				total_rows: 0,
				sort_by: [],
				sort_desc: []
			}
		},
		SET_PAGINATION(state: VuexStateContext, value: ObjectContext) {
			state.pagination = Object.assign({}, value)
		}
	},
	actions: {
		onMainClick({ commit }: VuexActionContext) {
			commit('SET_MAIN_CLICK')
		},
		loadLocale({ rootGetters }: VuexActionContext) {
			const user: ObjectContext = rootGetters['auth/USER']
			const locale: string = user._locale
			if (locale) $i18n.locale = locale
		},
		loadTimeZone({ rootGetters }: VuexActionContext) {
			const user: ObjectContext = rootGetters['auth/USER']
			const time_zone: string = user.time_zone
			// @ts-ignore
			if (time_zone) this.$moment.tz.setDefault(time_zone)
		},
		async cloneItem({ state, getters }: VuexActionContext, params: VuexParamsContext) {
			const body: ObjectContext = {}
			const itemIdKey = getters['GET_ITEM_ID_KEY'][state.tabSelected]
			const key = itemIdKey + 's'
			body[key] = [params[itemIdKey]]
			// @ts-ignore
			const res = await this.$axios.post(`clone/${state.tabSelected}`, body)
			return res
		},
		async getData({ rootGetters, state, getters, commit, dispatch }: VuexActionContext, params: VuexParamsContext) {
			commit('SET_ITEMS', [])
			// @ts-ignore
			const tabSelected = params.tabSelected || state.tabSelected
			const body: ObjectContext = {
				...params,
				return_all_column_names: true,
				items_per_page: state.pagination.items_per_page,
				page_number: state.pagination.page_number,
				columns: []
			}
			delete body.tabSelected
			delete body.filters
			delete body._is_local_items
			if (!params.query) {
				const filters = rootGetters['filters/GET_FILTERS'][tabSelected] || {}
				const sort_columns = rootGetters['filters/GET_SORT_COLUMNS'] || {}
				const query = await UTIL.getFiltersQuery(params.filters || filters, params.sort_columns || sort_columns)
				body.query = query
			}
			await dispatch('dictionary/get', { tables: [tabSelected] })
			body.columns = params.columns || getters['filters/GET_SELECTED_COLUMNS'][tabSelected] || []
			const data = await dispatch(`${tabSelected}/getList`, body, { root: true })
			let key: string = tabSelected
			if (tabSelected === 'documents') key = 'persons'
			let items = []
			// if (state.tabSelected === tabSelected || params._is_local_items) {
			items = data[key]
			commit('SET_ITEMS', items)
			const unique_columns = data.all_column_names || []
			commit('filters/SET_UNIQUE_COLUMNS', unique_columns)
			const pagination = Object.assign({}, state.pagination)
			pagination.total_rows = data.total_rows
			commit('SET_PAGINATION', pagination)
			// }
			return items
		}
	}
})
