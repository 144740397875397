import type { ObjectContext } from '@/global'
import type { ComputedRef } from 'vue'
import { computed, reactive, toRefs } from 'vue'
import useGeneral from './general'

export default function ($props: ObjectContext, ctxState: ObjectContext) {
	const { $t, $store, icons, density } = useGeneral()

	// * Input
	const baseColor = 'success'
	const bgColor = 'grey-lighten-4'
	const state = reactive({
		focused: false
	})

	// * Misc
	const id: ComputedRef<string> = computed(() => $props.id || 'input_' + $props.label)
	const label: ComputedRef<string> = computed(() => {
		let label = $props.keyName
		if ($props.label) label = $props.label
		else if (ctxState.label) {
			label = $props.multiple ? ctxState.label.multiple : ctxState.label.default
		}
		return label
	})
	// const variant: ComputedRef<string> = computed(() => (state.focused ? 'outlined' : 'contained'))
	const variant: ComputedRef<string> = computed(() => ($props.cellEditor ? 'plain' : 'outlined'))
	const icon: ComputedRef<string> = computed(() => {
		const str: string = ctxState.icon
		// @ts-ignore
		return icons[str]
	})
	const rules: ComputedRef = computed(() => {
		const rules = []
		const minLength = $props.type === 'password' ? 6 : $props.minLength
		if (minLength) {
			// @ts-ignore
			rules.push((v: string) => (v && v.toString().length ? v.toString().length >= minLength : true) || $t('Form_min_length_required', { min: minLength }))
		}
		if ($props.equalTo) rules.push((v: string | number) => v === $props.equalTo || $t('Form_password_not_equals'))
		if ($props.required) {
			$props.multiple ? rules.push((v: any) => (!!v && !!v.length) || $t('Form_required')) : rules.push((v: any) => !!v || $t('Form_required'))
		}
		if ($props.type === 'email') {
			const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
			rules.push((v: string) => !v || regex.test(v) || $t('Form_invalid_email'))
		}
		return rules
	})

	// * Items
	function text(item: any, key?: string) {
		const val = item ? ($props.itemText && item[$props.itemText]) || item['text'] || item : ''
		return $t(val, key)
	}

	async function getItems(storeModule: string, customerIds: Array<number> = []) {
		const body: ObjectContext = {}
		if (Array.isArray(customerIds) && customerIds.length) {
			body.query = {
				customers: {
					in: customerIds
				}
			}
		}
		const res = await $store.dispatch(`${storeModule}/getList`, body)
		return res[storeModule] || []
	}

	async function init(init = false) {
		ctxState.loading = true
		if (init) ctxState.item = $props.value ? $props.value : $props.multiple ? [] : {}
		ctxState.items = await getItems(ctxState.storeModule, $props.customerIds)
		ctxState.loading = false
	}

	// @ts-ignore
	// watch($props.value, val => (ctxState.item = val))
	// watch($props.customerIds, () => init())

	return {
		...toRefs(state),
		id,
		label,
		baseColor,
		bgColor,
		density,
		variant,
		icon,
		rules,
		text,
		getItems,
		init
	}
}
