<template>
	<AButtonPrimary v-if="isFiltersApplied" :badge="filtersBadgeCount" color="success" :icon="icons['filters']" :disabled="loading" @click="onToggleDrawer()" />
	<AButtonSecondary v-else :badge="filtersBadgeCount" :icon="icons['filters']" :disabled="loading" @click="onToggleDrawer()" />
	<v-navigation-drawer v-model="state.drawer" class="text-primary" location="right" :width="344" temporary>
		<v-card v-if="state.drawer" flat>
			<v-container>
				<v-row align="center">
					<v-col v-if="state.window === 1" cols="12" class="text-left text-h6 text-primary">
						<v-btn icon size="small" variant="plain" @click="onShowFilters()">
							<v-icon :icon="icons['arrowLeft']" />
						</v-btn>
						{{ $t('General_advanced_filters') }}
					</v-col>

					<template v-if="state.window === 0">
						<v-col cols="9" class="text-left text-h6 text-primary">
							{{ $t('FiltersDialog_title_filters') }}
						</v-col>

						<v-col cols="3" class="text-right">
							<AButtonIcon size="small" :icon="icons['close']" @click="onClose()" />
						</v-col>
					</template>
				</v-row>

				<v-divider color="primary_alt" />

				<v-window v-model="state.window">
					<v-window-item>
						<v-row v-if="state.window === 0" align="center" class="pt-6">
							<v-col cols="12" class="text-left" v-for="key in selectedFiltersKeys" :key="key.id">
								<AInputAutocomplete
									:label="$t(key)"
									v-model="state.filters[key]"
									:items="uniqueValues[key] || []"
									:loading="state.loading_unique_values"
									:disabled="!uniqueValues[key]?.length"
									:placeholder="!uniqueValues[key]?.length ? $t('General_no_data_available') : ''"
									filter
									multiple
									hide-details
									@blur="getUniqueValues()"
								/>
							</v-col>

							<v-col cols="12" class="pt-0">
								<v-row align="center" class="cursor-pointer font-weight-bold text-info pa-4" @click="onShowAdvancedOptions()">
									{{ $t('General_advanced_options') }}
								</v-row>
							</v-col>
						</v-row>
					</v-window-item>

					<v-window-item>
						<template v-if="state.window === 1">
							<template v-for="section in state.sections" :key="section.id">
								<v-row v-if="filters[section.key].length > 0" no-gutters align="center">
									<v-col cols="12" class="text-left font-weight-bold text-primary pt-6 pl-2">
										{{ $t(section.title) }}
									</v-col>

									<template v-for="key in filters[section.key]" :key="key.id">
										<v-col cols="10" class="text-left pl-2">
											{{ $t(key) }}
										</v-col>

										<v-col cols="2">
											<ASwitch v-model="state.selected_filters[key]" density="compact" />
										</v-col>
									</template>
								</v-row>
							</template>
						</template>
					</v-window-item>
				</v-window>
			</v-container>
		</v-card>

		<template #append>
			<v-container>
				<v-row no-gutters>
					<v-col class="mr-4">
						<AButtonSecondary color="error" :text="buttonTextSecondary" block @click="onClickSecondary()" />
					</v-col>
					<v-col>
						<AButtonPrimary :text="buttonText" block @click="onClick()" />
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-navigation-drawer>
</template>

<script setup>
	import ASwitch from '@/components/atoms/ASwitch.vue'
	import AButtonIcon from '@/components/atoms/AButtonIcon.vue'
	import AButtonPrimary from '@/components/atoms/AButtonPrimary.vue'
	import AButtonSecondary from '@/components/atoms/AButtonSecondary.vue'
	import AInputAutocomplete from '@/components/atoms/AInputAutocomplete.vue'

	import * as UTIL from '@/util'

	import { defineEmits, defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'change'])
	const $props = defineProps({
		tabSelected: { type: String, default: '', required: true },
		modelValue: { type: Boolean, default: false },
		saveFilters: { type: Boolean, default: true }
	})

	const { $t, $store, icons, loading, dictionary, checkProxyValue } = useGeneral()

	const state = reactive({
		window: 0,
		drawer: false,
		loading_unique_values: false,
		filters: {},
		selected_filters: {},
		sections: [
			{ title: 'FiltersDrawer_default_filters', key: 'default' },
			{ title: 'FiltersDrawer_custom_filters', key: 'custom' }
		]
	})

	const filtersCount = computed(() => {
		let count = 0
		Object.keys(state.filters).forEach(key => {
			if (Array.isArray(state.filters[key]) && state.filters[key].length) count++
		})
		return count
	})

	const isFiltersApplied = computed(() => filtersCount.value > 0)

	const filtersBadgeCount = computed(() => (isFiltersApplied.value ? filtersCount.value.toString() : ''))

	function resetFilters() {
		state.filters = {}
	}

	const selectedFiltersKeys = computed(() => {
		const keys = []
		Object.keys(state.selected_filters).forEach(key => {
			if (state.selected_filters[key] === true) keys.push(key)
		})
		return keys
	})

	watch(
		() => $props.modelValue,
		val => (state.drawer = val)
	)

	const uniqueValues = computed(() => $store.getters['filters/GET_UNIQUE_VALUES'])
	const uniqueColumns = computed(() => $store.getters['filters/GET_AVAILABLE_UNIQUE_COLUMNS'])

	const filters = computed(() => {
		const items = {
			default: [],
			custom: []
		}
		uniqueColumns.value.forEach(key => {
			const dictionaryItem = dictionary.value[key]
			if (!dictionaryItem) items.custom.push(key)
			else {
				let isVisible = false
				let isFilter = false

				const defaultValidation = dictionaryItem.validation.default
				const tabValidation = dictionaryItem.validation[$props.tabSelected] || {}

				if (tabValidation.visible === true) isVisible = true
				else if (defaultValidation.visible === true) isVisible = true

				if (![undefined, null].includes(tabValidation.filter)) isFilter = true
				else if (![undefined, null].includes(defaultValidation.filter)) isFilter = true

				if (isVisible && isFilter) items.default.push(key)
			}
		})
		return items
	})

	function onClose() {
		state.drawer = false
		$emit('update:modelValue', false)
		state.window = 0
	}

	function onToggleDrawer() {
		state.drawer = !state.drawer
	}

	function onShowAdvancedOptions() {
		state.window = 1
	}

	async function onShowFilters() {
		onSaveUserFilters()
		state.window = 0
	}

	async function getUniqueValues(query) {
		const columns = selectedFiltersKeys.value
		if (columns.length > 0) {
			state.loading_unique_values = true
			try {
				const filters_val = checkProxyValue(state.filters, {})
				const query = UTIL.getFiltersQuery(filters_val)
				const body = {
					columns,
					query,
					table_name: $props.tabSelected
				}
				await $store.dispatch('filters/getUniqueValues', body)
			} catch (error) {
				$store.dispatch('alert/SET_ALERT', { action: 'get', error })
			} finally {
				state.loading_unique_values = false
			}
		}
	}

	watch(
		() => state.window,
		val => {
			if (val === 0) getUniqueValues()
		}
	)

	const user = computed(() => $store.getters['auth/USER'])
	const userFilters = computed(() => user.value['_filters'] || {})
	const userSelectedFilters = computed(() => user.value['_selected_filters'] || {})

	function loadFilters() {
		state.filters = {}
		state.selected_filters = {}
		state.filters = Object.assign({}, userFilters.value[$props.tabSelected] || {})
		const selected_filters = userSelectedFilters.value[$props.tabSelected] || []
		selected_filters.forEach(key => (state.selected_filters[key] = true))
		getUniqueValues()
	}

	watch(
		() => state.drawer,
		val => (val ? loadFilters() : onClose())
	)

	watch(
		() => loading.value,
		val => {
			if (!val && !state.drawer) loadFilters()
		}
	)

	function onSaveUserFilters() {
		if ($props.saveFilters) {
			const filters = {}
			const newFilters = Object.assign({}, userFilters.value || {})
			selectedFiltersKeys.value.forEach(key => (filters[key] = state.filters[key]))
			newFilters[$props.tabSelected] = Object.assign({}, filters)

			const newSelectedFilters = Object.assign({}, userSelectedFilters.value)
			newSelectedFilters[$props.tabSelected] = JSON.parse(JSON.stringify(selectedFiltersKeys.value))

			const newUser = Object.assign({}, user.value)
			newUser['_filters'] = newFilters
			newUser['_selected_filters'] = newSelectedFilters
			const body = {
				person_id: newUser.person_id,
				_item: newUser,
				_keys: ['_filters', '_selected_filters']
			}
			$store.commit('auth/SET_USER', newUser)
			$store.commit('filters/SET_FILTERS', newFilters)
			$store.commit('filters/SET_SELECTED_FILTERS', newSelectedFilters)
			$store.dispatch('persons/update', body)
		}
	}

	async function saveFilters() {
		onSaveUserFilters()
		onClose()
		$emit('change', state.filters)
	}

	const buttonText = computed(() => (state.window === 0 ? $t('Action_apply') : $t('Action_save')))
	const buttonTextSecondary = computed(() => (state.window === 0 ? $t('Action_reset') : $t('Action_cancel')))

	function onClick() {
		if (state.window === 0) saveFilters()
		else {
			onSaveUserFilters()
			onShowFilters()
		}
	}

	function onClickSecondary() {
		state.window === 0 ? resetFilters() : onClose()
	}
</script>
