import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

import axios from 'axios'
import * as UTIL from '@/util/index.js'

const client = axios.create()

export default {
	namespaced: true,
	state: {
		status: ['pending_upload', 'pending_signature', 'signed', 'without_signature'],
		status_color: {
			signed: 'success',
			pending_signature: 'warning',
			pending_upload: 'error',
			without_signature: ''
		}
	},
	getters: {
		STATUS_ITEMS(state: VuexStateContext) {
			return state.status.map((e: string) => {
				return { text: UTIL.valuesTranslation('status', e), value: e }
			})
		},
		NEW_FILE_STATUS_ITEMS(state: VuexStateContext) {
			return state.status
				.filter((e: string) => e != 'pending_upload')
				.map((e: string) => {
					return { text: UTIL.valuesTranslation('status', e), value: e }
				})
		}
	},
	mutations: {},
	actions: {
		async uploadFile(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const tabSelected = params.tabSelected
			const itemId = params.itemId
			const file = params.file
			const rawFile = file.rawFile

			const base64_content = await UTIL.toBase64(rawFile)

			const body: ObjectContext = {
				status: file.status,
				file_name: rawFile.name,
				file_type: rawFile.type,
				base64_content: base64_content,
				generate_presigned_url: false
			}
			if (file.document_definition_id) {
				body.document_definition_id = file.document_definition_id
			} else if (file.status === 'pending_signature') {
				body.generate_presigned_url = true
			}
			// @ts-ignore
			const res = await this.$axios.post(`/associated_files/upload/${tabSelected}/${itemId}`, body)
			const data = res.data.response_json
			return data
		},
		async download(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const tabSelected = params.tabSelected
			const itemId = params.itemId
			const documentId = params.documentId
			// @ts-ignore
			const res = await this.$axios.get(`associated_files/${tabSelected}/${itemId}/${documentId}`)
			const data = res.data.response_json
			const link = document.createElement('a')
			link.href = data.download_signed_url
			link.download = data.file_name
			link.click()
			return res
		},
		async delete(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const tabSelected = params.tabSelected
			const itemId = params.itemId
			const documentId = params.documentId
			// @ts-ignore
			return await this.$axios.delete(`associated_files/${tabSelected}/${itemId}/${documentId}`)
		},
		async sendWhatsApp({ rootState }: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const documentId = params.documentId
			const body = {
				number: '',
				person_id: params.person_id,
				receiver_name: params.person_name,
				sender_name: rootState.auth.user.person_name
			}
			const phone = {
				phone_prefix: UTIL.getPhonePrefix(params.phone_prefix),
				phone_number: params.phone_number
			}
			// @ts-ignore
			body.number = UTIL.getPhoneNumber(phone)
			// @ts-ignore
			return await this.$axios.post(`send_message/sign_documents/${documentId}`, body)
		},
		async sendEmail(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const config = {
				headers: {
					authorization: localStorage.getItem('token') || ''
				}
			}
			const body = {
				...params,
				channels: ['email']
			}
			return await client.post(`https://api3.rawdata.es:444/notifications`, body, config)
		}
	}
}
