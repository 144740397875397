<template>
	<v-footer app padless>
		<v-row>
			<v-col class="text-center" cols="12">
				<!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong>  -->
				<span class="font-weight-bold mr-1">
					{{ $t('Login_powered_by') }}
				</span>
				<a target="_blank" href="https://www.alltech.com/">
					<img src="https://raw-data-public.s3.eu-west-3.amazonaws.com/customization/alltech_logo.png" height="20" />
				</a>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script setup></script>
