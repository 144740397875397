import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
import moment from './plugins/moment'
import { vuetify, i18n } from './plugins/vuetify-i18n'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

// @ts-ignore
store.$router = router
// @ts-ignore
store.$axios = axios
// @ts-ignore
store.$moment = moment

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .mount('#app')
