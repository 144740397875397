<template>
	<template v-if="$props.table">
		<AgDatatable
			v-if="headers.length && itemRow.length && !loading"
			:headers="headers"
			:items="itemRow"
			:max-height="15"
			auto-height
			:size-columns-to-fit="$props.sizeColumnsToFit"
			@gridReady="onGridReady($event)"
		/>
	</template>
	<v-row v-else align="end">
		<v-col v-for="key in $props.keys" :cols="$props.cols" :xl="$props.xl" :key="key.id">
			<MDynamicInput
				v-model="state.item[key]"
				:key-name="key"
				:tabSelected="tabSelected"
				@update:modelValue="onUpdateValue($event)"
				@input="onInput()"
				@change="onChange()"
				@blur="onBlur()"
				@focus="onFocus()"
				@enter="onEnter()"
			/>
		</v-col>
	</v-row>
</template>

<script setup>
	import AgDatatable from '@/components/datatable/AgDatatable.vue'
	import MDynamicInput from '@/components/molecules/MDynamicInput.vue'
	import DefaultCellEditor from '@/components/datatable/cellEditors/DefaultCellEditor.vue'
	import DefaultCellRenderer from '@/components/datatable/cellRenderers/DefaultCellRenderer.vue'

	import { defineEmits, defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral, useDatatable } from '@/hooks'

	const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'clear', 'enter'])
	const $props = defineProps({
		modelValue: { type: Object, default: null, required: true },
		tabSelected: { type: String, default: '', required: true },
		keys: { type: Array, default: () => [], required: true },
		sizeColumnsToFit: { type: Boolean, default: true },
		table: { type: Boolean, default: false },
		cols: { type: [String, Number], default: 3 },
		xl: { type: [String, Number], default: 3 }
	})

	const state = reactive({
		gridApi: null,
		item: {}
	})

	const { loading, mainClick } = useGeneral()
	const { suppressEnter, isCellEditable, valueSetter } = useDatatable()

	function onGridReady(params) {
		state.gridApi = params.gridApi
	}
	watch(mainClick, () => {
		if (state.gridApi) state.gridApi.stopEditing()
	})

	const itemRow = computed(() => [state.item])

	watch(
		() => $props.modelValue,
		val => (state.item = Object.assign({}, val || {}))
	)

	function init() {
		state.item = $props.modelValue || {}
	}
	init()

	const headers = computed(() => {
		// @ts-ignore
		return $props.keys.map(key => {
			return {
				headerName: key,
				field: key,
				sortable: false,
				resizable: true,
				suppressMovable: true,
				editable: isCellEditable(key, $props.tabSelected),
				cellEditor: DefaultCellEditor,
				cellRenderer: DefaultCellRenderer,
				suppressKeyboardEvent: params => suppressEnter(params),
				valueSetter: params => valueSetter(params, $props.tabSelected)
			}
		})
	})

	function onUpdateValue(val) {
		const value = val || state.item
		$emit('update:modelValue', value)
	}
	function onInput() {
		onUpdateValue()
		$emit('input', state.item)
	}
	function onChange() {
		onUpdateValue()
		$emit('change', state.item)
	}
	function onBlur() {
		onUpdateValue()
		$emit('blur', state.item)
	}
	function onEnter() {
		$emit('enter')
	}
</script>
