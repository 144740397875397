import type { ObjectContext, VuexStateContext, VuexActionContext } from '@/global'

export default {
	namespaced: true,
	state: {
		snackbar: false,
		text: '',
		tab: '',
		action: '',
		result: '',
		icon: '',
		image: '',
		color: '',
		url: '',
		timeout: 6000,
		close: false,
		error: {
			// error_code: 15,
			// message:
			// 	'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tempor consequat dolor eu hendrerit. Ut in commodo ligula. Etiam quis enim dictum, venenatis ligula at, pharetra magna. Praesent laoreet tellus sed elit mollis, sed tincidunt tellus mattis. Cras vel sollicitudin massa, vel imperdiet ex. Donec consequat neque in est gravida vestibulum. Donec dolor tortor, mattis at magna in, posuere viverra odio. Nulla facilisi. Sed at metus imperdiet, molestie metus eu, gravida urna. Suspendisse vestibulum quis mauris quis eleifend. Nullam ac condimentum nibh. Integer vitae metus sagittis, venenatis felis vitae, accumsan velit. Fusce blandit porttitor enim, eget hendrerit ex rutrum ut. Quisque id tellus sagittis, venenatis massa et, ornare dui.'
		}
	},
	mutations: {
		SHOW_ALERT(state: VuexStateContext, value: boolean) {
			state.snackbar = value
		},
		SET_VAR(state: VuexStateContext, { key, value }: ObjectContext) {
			state[key] = value
		},
		SET_ALERT(state: VuexStateContext, alert: ObjectContext) {
			state.snackbar = false
			state.text = alert.text || ''
			state.tab = alert.tab || ''
			state.color = alert.color || 'error'
			state.action = alert.action || ''
			state.result = alert.result || ''
			state.color = alert.color || 'error'
			state.icon = alert.icon ? alert.icon : alert.color === 'success' ? 'mdi-emoticon-outline' : 'mdi-emoticon-sad-outline'
			state.url = alert.url || ''
			// Error
			const error = (alert.error && alert.error.response && alert.error.response.data) || alert.error
			state.error = error || {}
			state.close = error ? true : alert.close || false
			state.timeout = error || alert.close ? 0 : alert.timeout >= 0 ? alert.timeout : 6000
			setTimeout(() => (state.snackbar = true), 100)
		}
	},
	actions: {
		SHOW_ALERT({ commit }: VuexActionContext, value: boolean) {
			commit('SHOW_ALERT', value)
		},
		SET_ALERT({ commit }: VuexActionContext, alert: ObjectContext) {
			commit('SET_ALERT', alert)
		}
	}
}
