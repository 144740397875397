import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// * Prod
const prodConfig = {
	apiKey: 'AIzaSyA-b2Aq5WC2oA3IjV_J79Xtzo1gwv2KjB8',
	authDomain: 'apperviti.firebaseapp.com',
	databaseURL: 'https://raw_data.firebaseio.com',
	projectId: 'rising-armor-204515',
	storageBucket: 'rawdata.appspot.com',
	messagingSenderId: '714602041056'
}

// ! Staging
const devConfig = {
	apiKey: 'AIzaSyCUyTLdHz9X9tUwZu4J7Vqps5W7sImksoo',
	authDomain: 'rc-migration-994a3.firebaseapp.com',
	projectId: 'rc-migration-994a3',
	storageBucket: 'rc-migration-994a3.appspot.com',
	messagingSenderId: '933972347285',
	appId: '1:933972347285:web:cc1a785a6b5aa795dd8e9c'
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig
firebase.initializeApp(config)

const auth2 = firebase.auth
const auth = firebase.auth()
const db = firebase.firestore()

export default { auth, auth2, db }
