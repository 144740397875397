<template>
	<ODialog :id="alertID" v-model="snackbar" :width="400" no-actions>
		<v-container class="px-0">
			<v-row class="pb-4" align="center" justify="center">
				<v-col v-if="icon" cols="12" class="text-center py-0">
					<v-icon :id="alertID + '_icon'" size="150" :color="color" :icon="icon" />
				</v-col>

				<v-col cols="12" class="text-center text-primary">
					<p v-if="color === 'success'" class="text-h5">
						{{ $t('Alert_success_message') }}
					</p>
					<div v-else-if="error_code || error_message">
						<p class="text-h5">
							{{ $t('Alert_error_message') }}
						</p>
						<p class="pt-2" v-html="$t('Alert_error_subtitle_html')" />
					</div>
					<p v-else class="text-h5">
						{{ $t('Alert_error_message') }}
					</p>
				</v-col>

				<v-col v-if="!error_code && !error_message" cols="12" class="text-center pt-0">
					<div :id="alertID + '_text'" v-html="text"></div>
				</v-col>

				<v-col v-if="url" cols="12" class="text-center">
					<AButtonPrimary block :text="$t('Action_open_in_new_tab')" @click="openInNewTab()" />
				</v-col>

				<v-col v-if="error_code || error_message" cols="12" class="text-caption">
					<v-expansion-panels v-model="state.panel">
						<v-expansion-panel class="elevation-0 bg-grey-lighten-4">
							<v-expansion-panel-title>
								{{ $t('General_more_information') }}
							</v-expansion-panel-title>
							<v-expansion-panel-text style="word-break: break-all">
								<div style="max-height: 50vh; overflow: auto">
									<div v-if="error_code" :id="alertID + '_error'" class="text-capitalize font-weight-bold">
										{{ $t('General_error') + ' ' + $t('General_code') + ': ' + error_code }}
									</div>
									<br />
									<div :id="alertID + '_message'" v-html="error_message" />
								</div>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</v-container>
	</ODialog>
	<!-- <v-snackbar :id="alertID" v-model="snackbar" :timeout="timeout" :color="color" location="top" style="z-index: 1000000 !important">


		<template v-slot:action="{ attrs }">
			<v-btn v-if="close" :id="alertID + '_button_close'" variant="text" icon v-bind="attrs" @click="snackbar = false">
				<v-icon v-if="icon" :id="alertID + '_button_close_icon'" :icon="icons['close']" />
			</v-btn>
		</template>
	</v-snackbar> -->
</template>

<script setup>
	import AButtonPrimary from '@/components/atoms/AButtonPrimary.vue'
	import ODialog from '@/components/organism/ODialog.vue'

	import { reactive, computed, watch } from 'vue'
	import { useGeneral, useMainView } from '@/hooks'

	const { $store, $t, locale, icons, tabSelected } = useGeneral()
	const { menuItems } = useMainView()

	const state = reactive({
		timeout: null,
		panel: null,
		translations: {
			associatedFiles: 'General_associated_files',
			file: 'General_file',
			report: 'General_report',
			userProfile: 'Route_user_profile',
			previewMessage: 'ShareDialog_preview_message',
			recommendation_treatment: 'General_recommendation_treatment',
			sigpacLayer: 'MapAlerts_sigpac_layer',
			account: 'General_account',
			summary: 'Form_step_summary',
			satelite_images: 'MapAlerts_satelite_images'
		}
	})

	const error = computed(() => $store.state.alert.error)
	const error_code = computed(() => error.value.error_code || '')
	const url = computed(() => $store.state.alert.url)
	const icon = computed(() => $store.state.alert.icon)
	const color = computed(() => $store.state.alert.color)
	const timeout = computed(() => $store.state.alert.timeout)
	const close = computed(() => $store.state.alert.close)

	const snackbar = computed({
		get() {
			return $store.state.alert.snackbar
		},
		set(val) {
			$store.dispatch('alert/SHOW_ALERT', val)
		}
	})

	watch(
		() => snackbar.value,
		val => {
			if (!val) {
				clearTimeout(state.timeout)
				$store.commit('alert/SET_VAR', { key: 'action', value: '' })
			} else {
				state.panel = null
				if (timeout.value > 0) {
					state.timeout = setTimeout(() => {
						$store.dispatch('alert/SHOW_ALERT', false)
					}, timeout.value)
				}
			}
		}
	)

	const tab = computed(() => $store.state.alert.tab || tabSelected.value)
	watch(
		() => tab.value,
		() => (snackbar.value = false)
	)

	const error_message = computed(() => {
		const error_localized_message = error.value.localized_message?.[locale.value]
		const error_message = error.value.message?.[locale.value] || error.value.message
		return $t(error_localized_message || error_message) || ''
	})

	const alertID = computed(() => {
		let id = 'alert_dialog_'
		id += error_code.value || error_message.value || color.value === 'error' ? 'error' : 'success'
		return id
	})

	const action = computed(() => {
		let action = ''
		const storeAction = $store.state.alert.action
		const isError = color.value === 'error'
		if (storeAction === 'get') action = 'Action_getting'
		if (storeAction === 'create') action = isError ? 'Action_creating' : 'Action_created'
		if (storeAction === 'update') action = isError ? 'Action_updating' : 'Action_updated'
		if (storeAction === 'delete') action = isError ? 'Action_deleting' : 'Action_deleted'
		if (storeAction === 'save') action = isError ? 'Action_saving' : 'Action_saved'
		if (storeAction === 'download') action = isError ? 'Action_downloading' : 'Action_downloaded'
		if (storeAction === 'change') action = isError ? 'Action_changing' : 'Action_changed'
		if (storeAction === 'share') action = isError ? 'Action_sharing' : 'Action_shared'
		return action ? $t(action) : action
	})

	const tabSelectedElement = computed(() => {
		if (!menuItems.value) return {}
		else {
			return menuItems.value.reduce((obj, e) => {
				obj[e.tab] = e.element
				return obj
			}, {})
		}
	})

	const text = computed(() => {
		const text = $store.state.alert.text
		const tab = $store.state.alert.tab || $store.state.tabSelected
		const currenTab = tabSelectedElement.value[tab] || state.translations[tab]
		// || $store.getters['roles/tabsTranslations'](tab.value)
		let error = $t('Error_an_error_has_ocurred') + ' ' + action.value + ': '
		let success = ''
		error += $t(currenTab)
		success += $t(currenTab) + ' ' + action.value + ' ' + $t('General_successfully')
		const isError = color.value === 'error'
		const message = isError ? error : success
		return text[locale.value] || text || message
	})

	function openInNewTab() {
		window.open(url.value)
	}
</script>
