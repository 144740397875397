import type { VuexStateContext } from '@/global'

export default {
	namespaced: true,
	state: {
		density: 'compact'
	},
	getters: {
		GET_DENSITY(state: VuexStateContext) {
			return state.density
		}
	},
	mutations: {},
	actions: {}
}
