import snakeCase from 'lodash/snakeCase'

const skip: Array<string> = ['index.ts']

const requireFiles = require.context('./', true, /\.ts$/)

function importFiles() {
	const files: { [index: string]: any } = {}
	requireFiles.keys().forEach(fileName => {
		const file = fileName.replace('./', '')
		if (!skip.includes(file)) {
			const arr = fileName.split('/modules/')
			let newFileName = arr.length > 1 ? arr[1] : arr[0]
			newFileName = newFileName.replace('/index.ts', '')
			// Get PascalCase name of component
			const formatedFileName = snakeCase(newFileName.replace(/(\.\/|\.ts)/g, ''))
			// Add File
			files[formatedFileName] = requireFiles(fileName).default
		}
	})
	return files
}

export default importFiles()
