<template>
	<v-row>
		<template v-for="section in sections" :key="section.id">
			<v-col v-if="sections.length > 1" cols="12">
				{{ $t(section.title) }}
				<v-divider color="primary_alt" />
			</v-col>

			<v-col v-if="loading" cols="12">
				<v-row class="py-2 px-4">
					<v-col cols="6" sm="4" md="3" lg="2" v-for="n in 4" :key="n.id">
						<v-skeleton-loader type="text"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>
			<template v-else>
				<v-expand-transition>
					<AgDatatable v-if="!loading" :headers="headers(section.type)" :items="items[section.type]" auto-height />
				</v-expand-transition>
				<AButtonGridAdd
					v-if="EDIT_VISIBLE && section.type === 'additional'"
					element="General_document"
					:disabled="loading || !EDIT_DISABLED"
					@click="onToggleDialog()"
				/>
			</template>
		</template>
	</v-row>

	<TDialogUploadFile v-model="state.dialog" :tabSelected="$props.tabSelected" :item-id="itemId" :document="state.document" @init="init()" @close="onClose()" />
</template>

<script setup>
	import AButtonGridAdd from '@/components/atoms/AButtonGridAdd.vue'
	import TDialogUploadFile from '@/components/templates/TDialogUploadFile.vue'
	import AgDatatable from '@/components/datatable/AgDatatable.vue'
	import DocumentationCellRenderer from '@/components/datatable/cellRenderers/DocumentationCellRenderer.vue'
	// DefaultCellRenderer

	import { defineProps, reactive, computed, watch } from 'vue'
	import { useGeneral, useRole } from '@/hooks'

	const $props = defineProps({
		item: { type: Object, default: null, required: true },
		tabSelected: { type: String, default: '', required: true }
	})

	const state = reactive({
		dialog: false,
		item: {},
		document: {}
	})

	const { $t, $store, loading } = useGeneral()
	const { VIEW_VISIBLE, EDIT_VISIBLE, EDIT_DISABLED } = useRole($props.tabSelected)

	const itemIdKey = computed(() => $store.getters['GET_ITEM_ID_KEY'][$props.tabSelected] || '')
	const itemId = computed(() => $props.item[itemIdKey.value])

	function onClose() {
		state.document = {}
	}

	function onToggleDialog() {
		state.dialog = !state.dialog
	}

	function attachDocument(document) {
		state.document = Object.assign({}, document || {})
		onToggleDialog()
	}

	async function init(propValue) {
		loading.value = true
		const item = propValue ? $props.item : await $store.dispatch(`${$props.tabSelected}/get`, itemId.value)
		state.item = Object.assign({}, item)
		loading.value = false
	}
	init()

	watch(
		() => $props.item,
		() => init()
	)

	const documents = computed(() => state.item.associated_files || [])

	const sections = computed(() => {
		const items = [{ title: 'Documentation_additional_documents', type: 'additional' }]
		if ($props.tabSelected === 'persons') {
			items.unshift({ title: 'Documentation_required_documents', type: 'required' }, { title: 'Documentation_optional_documents', type: 'optional' })
		}
		return items
	})

	const items = computed(() => {
		const associated_files = {
			required: [],
			optional: [],
			additional: []
		}

		const items = documents.value
		items.forEach(e => {
			const obj = {
				...e
			}
			obj[itemIdKey] = itemId.value
			const type = obj.document_required_type
			if (associated_files[type]) associated_files[type].push(obj)
			else if (e['file_type'] !== 'main_picture') associated_files['additional'].push(obj)
		})

		return associated_files
	})

	function headers(documentType) {
		const keys = ['last_status_at', 'document_name', 'status']
		const arr = keys.map(e => {
			return {
				headerName: e,
				field: e === 'document_name' && documentType === 'additional' ? 'file_name' : e,
				resizable: true,
				sortable: false,
				editable: false,
				cellRenderer: DocumentationCellRenderer
			}
		})

		if (documentType !== 'additional') {
			const last_email_sent_at = {
				headerName: 'last_email_sent_at',
				field: 'last_email_sent_at',
				resizable: true,
				sortable: false,
				editable: false,
				cellRenderer: DocumentationCellRenderer
			}

			arr.splice(1, 0, last_email_sent_at)
		}

		if (VIEW_VISIBLE.value) {
			arr.push({
				headerName: 'General_actions',
				field: 'actions',
				width: 100,
				resizable: true,
				sortable: false,
				pinned: 'right',
				cellRenderer: DocumentationCellRenderer,
				cellRendererParams: {
					init,
					attachDocument,
					documentType,
					items: items.value[documentType],
					tabSelected: $props.tabSelected,
					entityId: itemId.value,
					person: $props.item
				}
			})
		}

		return arr
	}
</script>
