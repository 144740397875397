import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		list: []
	},
	getters: {},
	mutations: {
		SET_LIST(state: VuexStateContext, value: Array<object>) {
			state.list = JSON.parse(JSON.stringify(value))
		}
	},
	actions: {
		async getList(ctx: VuexActionContext, warehouse_evaluation_id: number): Promise<ObjectContext> {
			// @ts-ignore
			const res = await this.$axios.get(`warehouse_evaluations/${warehouse_evaluation_id}/warehouse_evaluation_packages`)
			return res.data.response_json
		},
		async create(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = Object.assign({}, params)
			delete body.warehouse_evaluation_id
			// @ts-ignore
			return await this.$axios.post(`warehouse_evaluations/${params.warehouse_evaluation_id}/warehouse_evaluation_packages`, body)
		},
		async update(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : (body = Object.assign({}, params))
			delete body._item
			delete body.associated_files
			// @ts-ignore
			return await this.$axios.patch(
				`warehouse_evaluations/${item.warehouse_evaluation_id}/warehouse_evaluation_packages/${item.warehouse_evaluation_package_id}`,
				body
			)
		},
		async delete(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(
				`warehouse_evaluations/${params.warehouse_evaluation_id}/warehouse_evaluation_packages/${params.warehouse_evaluation_package_id}`
			)
		}
	}
}
