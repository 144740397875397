import type { ObjectContext, VuexStateContext, VuexActionContext, VuexParamsContext } from '@/global'

export default {
	namespaced: true,
	state: {
		list: []
	},
	getters: {},
	mutations: {
		SET_LIST(state: VuexStateContext, value: Array<object>) {
			state.list = JSON.parse(JSON.stringify(value))
		}
	},
	actions: {
		async getList({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			const obj: ObjectContext = params || {}
			if (!obj.query) obj.query = {}
			try {
				const body: ObjectContext = {
					...obj,
					return_type: 'records'
				}
				commit('SET_LIST', [])
				// @ts-ignore
				const res = await this.$axios.post('query/persons', body)
				const data = res.data.response_json
				const items = data['persons']
				commit('SET_LIST', items)
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { action: 'get', error }, { root: true })
			}
		},
		async get(ctx: VuexActionContext, id: number): Promise<ObjectContext> {
			// @ts-ignore
			const res = await this.$axios.get(`persons/${id}`)
			return res.data.response_json
		},
		async create(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const item = params._item
			const body: ObjectContext = Object.assign({}, item || params)
			// @ts-ignore
			return await this.$axios.post('persons', body)
		},
		async update(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const keys: Array<string> = params._keys
			const item = Object.assign({}, params._item)
			let body: ObjectContext = {}
			keys ? keys.forEach(e => (body[e] = item[e])) : item ? (body = Object.assign({}, item)) : (body = Object.assign({}, params))
			delete body._item
			delete body.associated_files
			// @ts-ignore
			return await this.$axios.patch(`persons/${params.person_id}`, body)
		},
		async updatePicture(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			const body: ObjectContext = Object.assign({}, params)
			delete body.person_id
			// @ts-ignore
			return await this.$axios.patch(`persons/${params.person_id}`, body)
		},
		async delete(ctx: VuexActionContext, params: VuexParamsContext): Promise<ObjectContext> {
			// @ts-ignore
			return await this.$axios.delete(`persons/${params.person_id}`)
		},
		// Documents
		async sendSignDocument({ commit, dispatch }: VuexActionContext, params: VuexParamsContext): Promise<any> {
			try {
				const body: ObjectContext = {
					person_id: params.personId,
					document_definition_id: params.documentId,
					subject: params.documentName
					// 'message': '',
					// 'cc_list': ['bperez@agrawdata.com'],
					// 'auth_password':'123',
					// 'expire_date':-1,
					// 'reminder':-1
				}
				// @ts-ignore
				const res = await this.$axios.post('signature/sign_email', body)
				const data = res.data.response_json
				return data
			} catch (error) {
				dispatch('alert/SET_ALERT', { error }, { root: true })
			}
		}
	}
}
