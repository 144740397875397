/* eslint-disable */
import { computed } from 'vue'
import { useStore } from 'vuex'

// nameAndCountry: ComputedRef<string> = computed((): string => `The movie name is ${movieName.value} from ${country.value}`);

// nameAndCountry: WritableComputedRef<string> = computed({
//   get(): string {
//     return 'somestring'
//   },
//   set(newValue: string): void {
//     // set something
//   },
// });

export default function (tabSelected: string) {
	const $store = useStore()

	return {
		isSuperAdmin: computed((): boolean => $store.getters['isSuperAdmin']),
		// View
		VIEW_VISIBLE: computed((): boolean => $store.getters['roles/VIEW_PERMISSIONS'](tabSelected).VISIBLE),
		VIEW_DISABLED: computed((): boolean => $store.getters['roles/VIEW_PERMISSIONS'](tabSelected).DISABLED),
		VIEW_PREMIUM: computed((): boolean => $store.getters['roles/VIEW_PERMISSIONS'](tabSelected).PREMIUM),
		// Create
		CREATE_VISIBLE: computed((): boolean => $store.getters['roles/CREATE_PERMISSIONS'](tabSelected).VISIBLE),
		CREATE_DISABLED: computed((): boolean => $store.getters['roles/CREATE_PERMISSIONS'](tabSelected).DISABLED),
		CREATE_PREMIUM: computed((): boolean => $store.getters['roles/CREATE_PERMISSIONS'](tabSelected).PREMIUM),
		// Edit
		EDIT_VISIBLE: computed((): boolean => $store.getters['roles/EDIT_PERMISSIONS'](tabSelected).VISIBLE),
		EDIT_DISABLED: computed((): boolean => $store.getters['roles/EDIT_PERMISSIONS'](tabSelected).DISABLED),
		EDIT_PREMIUM: computed((): boolean => $store.getters['roles/EDIT_PERMISSIONS'](tabSelected).PREMIUM),
		// Delete
		DELETE_VISIBLE: computed((): boolean => $store.getters['roles/DELETE_PERMISSIONS'](tabSelected).VISIBLE),
		DELETE_DISABLED: computed((): boolean => $store.getters['roles/DELETE_PERMISSIONS'](tabSelected).DISABLED),
		DELETE_PREMIUM: computed((): boolean => $store.getters['roles/DELETE_PERMISSIONS'](tabSelected).PREMIUM),
		// Export
		EXPORT_VISIBLE: computed((): boolean => $store.getters['roles/EXPORT_PERMISSIONS'](tabSelected).VISIBLE),
		EXPORT_DISABLED: computed((): boolean => $store.getters['roles/EXPORT_PERMISSIONS'](tabSelected).DISABLED),
		EXPORT_PREMIUM: computed((): boolean => $store.getters['roles/EXPORT_PERMISSIONS'](tabSelected).PREMIUM),
		// Confirm
		CONFIRM_VISIBLE: computed((): boolean => $store.getters['roles/CONFIRM_PERMISSIONS'](tabSelected).VISIBLE),
		CONFIRM_DISABLED: computed((): boolean => $store.getters['roles/CONFIRM_PERMISSIONS'](tabSelected).DISABLED),
		CONFIRM_PREMIUM: computed((): boolean => $store.getters['roles/CONFIRM_PERMISSIONS'](tabSelected).PREMIUM),
		// Confirm
		DOWNLOAD_VISIBLE: computed((): boolean => $store.getters['roles/DOWNLOAD_PERMISSIONS'](tabSelected).VISIBLE),
		DOWNLOAD_DISABLED: computed((): boolean => $store.getters['roles/DOWNLOAD_PERMISSIONS'](tabSelected).DISABLED),
		DOWNLOAD_PREMIUM: computed((): boolean => $store.getters['roles/DOWNLOAD_PERMISSIONS'](tabSelected).PREMIUM),
		// Share
		SHARE_VISIBLE: computed((): boolean => $store.getters['roles/SHARE_PERMISSIONS'](tabSelected).VISIBLE),
		SHARE_DISABLED: computed((): boolean => $store.getters['roles/SHARE_PERMISSIONS'](tabSelected).DISABLED),
		SHARE_PREMIUM: computed((): boolean => $store.getters['roles/SHARE_PERMISSIONS'](tabSelected).PREMIUM),
		// Send Email Copy
		SEND_EMAIL_COPY_VISIBLE: computed((): boolean => $store.getters['roles/SEND_EMAIL_COPY_PERMISSIONS'](tabSelected).VISIBLE),
		SEND_EMAIL_COPY_DISABLED: computed((): boolean => $store.getters['roles/SEND_EMAIL_COPY_PERMISSIONS'](tabSelected).DISABLED),
		SEND_EMAIL_COPY_PREMIUM: computed((): boolean => $store.getters['roles/SEND_EMAIL_COPY_PERMISSIONS'](tabSelected).PREMIUM),
		// Send for sign
		SEND_FOR_SIGN_VISIBLE: computed((): boolean => $store.getters['roles/SEND_FOR_SIGN_PERMISSIONS'](tabSelected).VISIBLE),
		SEND_FOR_SIGN_DISABLED: computed((): boolean => $store.getters['roles/SEND_FOR_SIGN_PERMISSIONS'](tabSelected).DISABLED),
		SEND_FOR_SIGN_PREMIUM: computed((): boolean => $store.getters['roles/SEND_FOR_SIGN_PERMISSIONS'](tabSelected).PREMIUM),
		// Attach
		ATTACH_VISIBLE: computed((): boolean => $store.getters['roles/ATTACH_PERMISSIONS'](tabSelected).VISIBLE),
		ATTACH_DISABLED: computed((): boolean => $store.getters['roles/ATTACH_PERMISSIONS'](tabSelected).DISABLED),
		ATTACH_PREMIUM: computed((): boolean => $store.getters['roles/ATTACH_PERMISSIONS'](tabSelected).PREMIUM),
		// Costs
		COSTS_VISIBLE: computed((): boolean => $store.getters['roles/COSTS_PERMISSIONS'](tabSelected).VISIBLE),
		// Recipe
		RECIPE_EDIT_ENABLED: computed((): boolean => {
			// ? ---------------------------------------------------------------------> why??
			const EDIT_VISIBLE = computed((): boolean => $store.getters['roles/EDIT_PERMISSIONS'](tabSelected).VISIBLE)
			const EDIT_DISABLED = computed((): boolean => $store.getters['roles/EDIT_PERMISSIONS'](tabSelected).DISABLED)
			return EDIT_VISIBLE && !EDIT_DISABLED
		}),
		RECIPE_DELETE_ENABLED: computed((): boolean => {
			// ? -------------------------------------------------------------------> why??
			const DELETE_VISIBLE = computed((): boolean => $store.getters['roles/DELETE_PERMISSIONS'](tabSelected).VISIBLE)
			const DELETE_DISABLED = computed((): boolean => $store.getters['roles/DELETE_PERMISSIONS'](tabSelected).DISABLED)
			return DELETE_VISIBLE && !DELETE_DISABLED
		}),
		// Associated Files View
		ASSOCIATED_FILES_VIEW_VISIBLE: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('VIEW', tabSelected).VISIBLE),
		ASSOCIATED_FILES_VIEW_DISABLED: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('VIEW', tabSelected).DISABLED),
		// Associated Files Create
		ASSOCIATED_FILES_CREATE_VISIBLE: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('CREATE', tabSelected).VISIBLE),
		ASSOCIATED_FILES_CREATE_DISABLED: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('CREATE', tabSelected).DISABLED),
		// Associated Files Delete
		ASSOCIATED_FILES_DELETE_VISIBLE: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('DELETE', tabSelected).VISIBLE),
		ASSOCIATED_FILES_DELETE_DISABLED: computed((): boolean => $store.getters['roles/ASSOCIATED_FILES_ENABLED']('DELETE', tabSelected).DISABLED)
	}
	// ASSOCIATED_FILES_VIEW_VISIBLE() {
	// 	isConfig = route.name === 'config'
	// 	return !isConfig && $store.getters['roles/ASSOCIATED_FILES_ENABLED']('VIEW', tabSelected).VISIBLE
	// },
	// ASSOCIATED_FILES_VIEW_DISABLED() {
	// 	tabs = ['fields', 'observations']
	// 	return !tabs.includes(tabSelected) || $store.getters['roles/ASSOCIATED_FILES_ENABLED']('VIEW', tabSelected).DISABLED
	// }
}
