
	import AInputLabel from '@/components/atoms/AInputLabel.vue'

	import * as UTIL from '@/util'

	import { ObjectContext } from '@/global'
	import { ComputedRef } from 'vue'

	import { defineComponent, getCurrentInstance, reactive, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'DefaultHeaderComponent',
		components: { AInputLabel },
		setup() {
			const instance = getCurrentInstance()
			const props = instance?.props || {}

			const { $t, $store, icons, loading, dictionary, tabSelected, checkProxyValue } = useGeneral()

			const state = reactive({})
			const params: ObjectContext = computed(() => props.params || {})
			// console.log(`🚀 ~ setup ~ params`, params.value)
			const displayName: ComputedRef<string> = computed(() => params.value.displayName || '')
			const cellField: ComputedRef<string> = computed(() => params.value.column?.colDef?.field || '')
			const editable: ComputedRef<boolean> = computed(() => params.value.column?.colDef?.editable || false)
			const mainTable: ComputedRef<boolean> = computed(() => params.value.column?.colDef?.mainTable || false)

			const dictionaryItem: ComputedRef<ObjectContext> = computed(() => dictionary.value[cellField.value] || {})
			const validation: ComputedRef<ObjectContext> = computed(() => dictionaryItem.value.validation || {})
			// TODO: Use tabSelected as param
			// @ts-ignore
			const required: ComputedRef<boolean> = computed(() => validation.value[tabSelected.value]?.required || validation.value.default?.required || false)
			// @ts-ignore
			const hint: ComputedRef<string> = computed(() => validation.value[tabSelected.value]?.hint || validation.value.default?.hint || '')

			const sortColumns: ObjectContext = computed(() => $store.getters['filters/GET_SORT_COLUMNS'] || {})
			const sortKey: ComputedRef<string> = computed(() => sortColumns.value[cellField.value] || '')
			const sortIcon: ComputedRef<string> = computed(() =>
				sortKey.value.includes('asc') ? icons.value['arrowUp'] : sortKey.value.includes('desc') ? icons.value['arrowDown'] : ''
			)

			// TODO: Add sort to headerComponent
			// const column: ObjectContext = computed(() => params.value.column)

			// column.value.addEventListener('sortChanged', function () {
			// 	// get sort state from column
			// 	const sort = column.value.getSort()
			// 	console.log('sort state of column is ' + sort) // prints one of ['asc',desc',null]

			// 	// then do what you need, eg set relevant icons visible
			// 	const sortingAscending = sort === 'asc'
			// 	const sortingDescending = sort === 'desc'
			// 	const notSorting = !sortingAscending && !sortingDescending
			// 	// how you update your GUI accordingly is up to you
			// })

			return {
				UTIL,
				t: $t,
				icons,
				loading,
				state,
				displayName,
				cellField,
				editable,
				mainTable,
				required,
				hint,
				sortIcon
			}
		}
	})
