<template>
	<div class="pa-4">
		<v-form ref="form" @submit.prevent>
			<!-- TODO: Fix comobobox not working -->
			<!-- <AInputAutocomplete -->
			<AInputCombobox
				v-if="cellField === 'key' && optionalsItems.length > 0"
				v-model="state.item[cellField]"
				:items="optionalsItems"
				item-text="text"
				item-value="value"
				cell-editor
				:key-name="cellField"
				:tab-selected="tabSelected"
				@enter="onEnter($event)"
				@update:modelValue="onUpdateValue($event)"
			/>
			<AInputText
				v-else-if="cellField === 'key'"
				v-model="state.item[cellField]"
				cell-editor
				:key-name="cellField"
				:tab-selected="tabSelected"
				@enter="onEnter($event)"
				@update:modelValue="onUpdateValue($event)"
			/>
			<MDynamicInput v-else v-model="state.item[cellField]" cell-editor :key-name="optionalKey" :tab-selected="tabSelected" @enter="onEnter($event)" />
		</v-form>
	</div>
</template>

<script>
	import AInputText from '@/components/atoms/AInputText.vue'
	import AInputAutocomplete from '@/components/atoms/AInputAutocomplete.vue'
	import AInputCombobox from '@/components/atoms/AInputCombobox.vue'
	import MDynamicInput from '@/components/molecules/MDynamicInput.vue'

	import * as UTIL from '@/util'

	import { defineComponent, getCurrentInstance, reactive, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	// Ag grid cell renderer support for Vue 3 still isn't quite right.
	// Globally registered components also do not work
	// params is added to the Component instance (getCurrentInstance) data and is where the cell values are stored.
	// params.key is where 'cellRendererParams' defined in a column's columnDef reside
	// params.data contains the values for all columns/fields on this cell's row.
	export default defineComponent({
		name: 'OptionalsCellEditor',
		components: { AInputText, AInputCombobox, MDynamicInput },
		setup() {
			const instance = getCurrentInstance()
			const props = instance.props

			const { $store, tabSelected } = useGeneral()

			const state = reactive({
				item: {}
			})

			const params = computed(() => props.params || {})
			const keysToDisable = computed(() => params.value.keysToDisable || [])
			const keysToAvoid = computed(() => params.value.keysToAvoid || [])
			console.log(`🚀 ~ setup ~ keysToAvoid`, keysToAvoid)
			// const tabSelected = computed(() => props.params.value?.tabSelected || '')
			const cellField = computed(() => params.value.colDef?.field || '')
			const editable = computed(() => params.value.colDef?.editable || false)
			const item = computed(() => params.value.data || {})
			// const value = computed(() => item.value[cellField.value])

			function init() {
				state.item = Object.assign({}, item.value)
			}
			init()

			const optionalKey = computed(() => state.item['key'] || '')
			const optionalsItems = computed(() => {
				const items = Object.values($store.getters['dictionary/GET_OPTIONALS'][tabSelected.value] || {})
				return items.filter(e => !keysToAvoid.value.includes(e) && !keysToDisable.value.includes(e))
			})
			console.log(`🚀 ~ setup ~ optionalsItems`, optionalsItems.value)

			function getValue() {
				return state.item[cellField.value]
			}

			function onUpdateValue(val) {
				state.item[cellField.value] = val
			}

			// TODO: Finish This
			function onEnter() {
				params.value.stopEditing()
			}

			return {
				UTIL,
				state,
				tabSelected,
				cellField,
				editable,
				item,
				// value,
				optionalKey,
				optionalsItems,
				getValue,
				onUpdateValue,
				onEnter
			}
		}
	})
</script>
