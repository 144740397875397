<template>
	<v-btn v-bind="$props" icon :size="size" :color="buttonColor" :variant="variant" :position="position" :minWidth="minWidth" @click="onClick()">
		<v-icon :icon="$props.icon" :color="iconColor" />
		<v-tooltip v-if="$props.tooltip" activator="parent" :text="$props.tooltip" :location="$props.location" />
	</v-btn>
</template>

<script setup>
	import { defineEmits, defineProps, computed } from 'vue'

	const $emit = defineEmits(['click'])
	const $props = defineProps({
		icon: { type: String, default: '', required: true },
		size: { type: String, default: 'default' },
		tooltip: { type: String, default: '' },
		location: { type: String, default: 'bottom' },
		disabled: { type: Boolean, default: false },
		filled: { type: Boolean, default: false },
		absolute: { type: Boolean, default: false }
	})

	const buttonColor = computed(() => ($props.filled ? 'white' : ''))
	const iconColor = computed(() => ($props.filled ? 'success' : ''))
	const variant = computed(() => ($props.filled ? 'contained-flat' : 'text'))
	const position = computed(() => ($props.absolute ? 'absolute' : 'relative'))
	const minWidth = computed(() => ($props.filled ? 32 : null))

	function onClick() {
		$emit('click')
	}
</script>
