<template>
	<div class="text-center">
		<!-- <v-btn variant="text" color="error" icon @click="deleteClick()">
			<v-icon :icon="icons['delete']" />
		</v-btn> -->
		<v-icon color="error" :icon="icons['delete']" @click="deleteClick()" />
	</div>
</template>

<script setup>
	import { defineProps } from 'vue'
	import { useGeneral } from '@/hooks'

	const { icons } = useGeneral()

	const $props = defineProps({
		params: { type: Object, default: null, required: true }
	})

	function deleteClick() {
		if ($props.params?.deleteClick instanceof Function) {
			const rowIndex = $props.params?.rowIndex
			$props.params.deleteClick(rowIndex)
		}
	}
</script>
