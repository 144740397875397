import type { ObjectContext } from '@/global'
import { defineEmits } from 'vue'
import useGeneral from './general'
import useRole from './role'
import * as UTIL from '@/util'

export default function () {
	const { $store } = useGeneral()

	const $emit = defineEmits(['reload', 'change'])

	function emitReload() {
		$emit('reload')
	}
	function emitChange() {
		$emit('change')
	}

	function getItemIndex(item: ObjectContext, items: Array<any>) {
		return UTIL.getItemIndex(item, items)
	}

	function isCellEditable(key: string, tabSelected: string) {
		const { EDIT_VISIBLE } = useRole(tabSelected)
		const dictionaryItem = $store.state.dictionary.item_keys[key] || {}
		const validation = dictionaryItem.validation || {}
		const editable = validation[tabSelected]?.editable || validation.default?.editable || false
		return key != 'actions' && editable && EDIT_VISIBLE.value
	}

	function suppressEnter(params: ObjectContext) {
		const KEY_ENTER = 13

		const event = params.event
		const key = event.which
		const suppress = key === KEY_ENTER

		const _isValid = !!params.data._isValid

		delete params.data._isValid

		return params.editing && suppress && !_isValid
	}

	async function valueSetter(params: ObjectContext, tabSelected: string) {
		const key = params.colDef.field
		if (params.newValue === undefined) return false
		else {
			let isValid = true
			let updateValue = true

			const dictionaryItem = $store.state.dictionary.item_keys[key] || {}
			const validation = dictionaryItem.validation || {}
			const type = validation[tabSelected]?.type || validation.default?.type || 'string'
			const required = validation[tabSelected]?.required || validation.default?.required || false

			if (required) {
				isValid = type === 'list' ? Array.isArray(params.newValue) && !!params.newValue.length : !!params.newValue
				if (!isValid) updateValue = false
			}

			if (isValid) {
				params.data[key] = !params.newValue && ![false, 0].includes(params.newValue) ? null : params.newValue
				try {
					const body: ObjectContext = {
						_item: params.data,
						_keys: [key]
					}
					const res = await $store.dispatch(`${tabSelected}/update`, body)
					const item = res.data.response_json
					params.data = item
				} catch (error) {
					updateValue = false
					$store.dispatch('alert/SET_ALERT', { error })
				}
			}

			return updateValue
		}
	}

	return {
		emitReload,
		emitChange,
		getItemIndex,
		isCellEditable,
		suppressEnter,
		valueSetter
	}
}
