// * i18n
import { createI18n, useI18n } from 'vue-i18n'

const i18n = createI18n({
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	formatFallbackMessages: true,
	locale: process.env.VUE_APP_I18N_LOCALE || 'es-ES',
	messages: {}
})

const { mergeLocaleMessage } = i18n.global
let { locale } = i18n.global

function setLocale(lang) {
	locale = lang
}

function fetchLocaleMessages() {
	fetch('https://raw-data-public.s3.eu-west-3.amazonaws.com/translations/translations.json', { cache: 'no-store' })
		.then(res => res.json())
		.then(out => Object.keys(out).forEach(locale => mergeLocaleMessage(locale, out[locale])))
		.catch(error => console.log('error', error))
		.finally(() => {
			const currentLocale = locale
			const toggleLocale = currentLocale.includes('es') ? 'en-GB' : 'es-ES'
			setLocale(toggleLocale)
			setLocale(currentLocale)
		})
}
fetchLocaleMessages()

// * Vuetify
// Styles
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import { createVueI18nAdapter } from 'vuetify/lib/locale/adapters/vue-i18n'

// Theme
const lightTheme = {
	dark: false,
	colors: {
		// background: '#FFFFFF',
		// surface: '#FFFFFF',
		primary: '#10413F',
		primary_alt: '#1D7661',
		// 'primary-darken-1': '#3700B3',
		secondary: '#FDC500',
		tertiary: '#E88D67',
		highlight: '#00AA5C',
		success: '#00AA5C',
		error: '#E95F59'
		// 'secondary-darken-1': '#018786',
		// error: '#B00020',
		// info: '#2196F3',
		// success: '#4CAF50',
		// warning: '#FB8C00',
	}
}
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

const vuetify = createVuetify({
	locale: createVueI18nAdapter({
		i18n,
		useI18n
	}),
	theme: {
		defaultTheme: 'lightTheme',
		themes: {
			lightTheme
		}
	},
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi
		}
	}
})

export { i18n, vuetify }
