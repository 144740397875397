<template>
	<v-hover #default="{ isHovering, props }">
		<v-img
			:src="thumbnail"
			:class="{
				'cursor-initial': disabled,
				'cursor-pointer': isHovering && !disabled,
				'elevation-1': isHovering && !disabled
			}"
			height="50"
			width="50"
			contain
			v-bind="props"
			@click="loadMainPicture()"
		/>
	</v-hover>
	<ODialog v-model="state.dialog" :title="title" small no-actions>
		<v-skeleton-loader v-if="state.loading" class="profile-picture mb-4" type="image" min-height="500"></v-skeleton-loader>
		<v-img v-else class="mb-4" :src="picture" contain />
	</ODialog>
</template>

<script setup>
	import ODialog from '@/components/organism/ODialog.vue'

	import { defineProps, reactive, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	const { $store } = useGeneral()

	const $props = defineProps({
		item: { type: Object, default: null },
		isPersonRecord: { type: Boolean, default: false }
	})

	const state = reactive({
		dialog: false,
		loading: false,
		associated_file: '',
		main_picture_object: {}
	})

	const disabled = computed(() => !$props.item?.main_picture_preview && !$props.item?.s3_key)
	const defaultPicture = computed(() => $store.state.default_picture_avatar)
	const title = computed(() => $props.item?.person_name)
	const thumbnail = computed(() => {
		const main_picture_preview = $props.item?.main_picture_preview || ''
		return main_picture_preview ? 'data:image/jpeg;base64,' + main_picture_preview : defaultPicture.value
	})
	const picture = computed(() => {
		const main_picture = state.main_picture_object?.base64_content || ''
		const main_picture_preview = $props.item?.main_picture_preview || ''
		const picture = main_picture || main_picture_preview
		return state.associated_file ? state.associated_file : picture ? 'data:image/jpeg;base64,' + picture : defaultPicture.value
	})

	async function loadMainPicture() {
		if (!disabled.value) {
			state.dialog = !state.dialog
			if (!state.main_picture_object.base64_content && !state.associated_file) {
				const person_id = $props.item?.person_id
				if (person_id) {
					state.loading = true
					try {
						if ($props.isPersonRecord) {
							const body = {
								tabSelected: 'person_records',
								itemId: state.value.person_record_id,
								documentId: 0
							}
							const res = await $store.dispatch('associated_files/get', body)
							state.associated_file = res
						} else {
							const item = await $store.dispatch('persons/get', person_id)
							state.main_picture_object = item.main_picture_object
						}
					} catch (error) {
						$store.dispatch('alert/SET_ALERT', { tab: 'General_picture', action: 'get', error })
					} finally {
						state.loading = false
					}
				}
			}
		}
	}
</script>
