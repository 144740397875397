<template>
	<v-navigation-drawer permanent :rail="state.rail" rail-width="80">
		<v-btn
			flat
			absolute
			:top="20"
			:right="-18"
			color="secondary"
			min-width="24"
			max-width="24"
			min-height="24"
			max-height="24"
			width="24"
			height="24"
			@click="toggleRail()"
			style="position: absolute; top: 20px; right: -18px"
		>
			<v-icon :icon="railButtonIcon" />
		</v-btn>

		<v-container class="text-center">
			<img
				:width="state.rail ? 35 : 200"
				:src="
					state.rail
						? 'https://raw-data-public.s3.eu-west-3.amazonaws.com/customization/rawdata_logo_sin_letra.png'
						: 'https://raw-data-public.s3.eu-west-3.amazonaws.com/customization/rawdata_logo_color.svg'
				"
			/>
		</v-container>

		<v-divider class="mx-5" />

		<v-container v-for="item in menuItems" :key="item.id" class="text-center drawer-menu">
			<v-btn
				:block="!state.rail"
				:value="item.route"
				:active="item.route === activeRoute"
				:color="getColor(item)"
				:variant="getVariant(item)"
				:min-width="menuItemSize"
				:max-width="menuItemSize"
				:min-height="menuItemSize"
				:max-height="menuItemSize"
				class="text-left drawer-menu-item"
				@click="changeRoute(item)"
			>
				<v-icon size="x-large" :icon="item.icon" :class="{ 'mr-2': !state.rail }" />
				<span v-if="!state.rail" v-text="$t(item.title)" />
				<v-tooltip v-if="state.rail" activator="parent" location="end">
					{{ $t(item.title) }}
				</v-tooltip>
			</v-btn>
		</v-container>

		<template #append>
			<v-container class="text-center">
				<v-menu anchor="bottom">
					<template #activator="{ props }">
						<v-btn
							v-bind="props"
							class="mb-4"
							variant="text"
							:block="!state.rail"
							:min-width="menuItemSize"
							:max-width="menuItemSize"
							:min-height="menuItemSize"
							:max-height="menuItemSize"
						>
							{{ localeText }}
						</v-btn>
					</template>
					<AList :items="locales" @click="onClickLocale" />
				</v-menu>

				<v-menu anchor="end">
					<template #activator="{ props }">
						<v-btn v-bind="props" variant="contained-flat" color="primary" :icon="state.rail" :block="!state.rail">
							{{ userButtonText }}
						</v-btn>
					</template>
					<v-list density="comfortable">
						<!-- User -->
						<v-list-item class="pb-2" :title="user.person_name" :subtitle="user.email">
							<template #prepend>
								<v-list-item-action class="mr-3">
									<v-avatar color="primary" size="40" class="white--text title text-uppercase">
										<span>{{ userInitials }}</span>
									</v-avatar>
								</v-list-item-action>
							</template>
						</v-list-item>

						<v-divider />

						<!-- User Profile -->
						<!-- <v-list-item
							id="user_settings_list_item_profile"
							:title="$t('Route_user_profile')"
							:prepend-icon="icons['person']"
							@click="$router.push({ name: 'user-profile' })"
						/>

						<v-divider /> -->

						<!-- Logout -->
						<v-list-item id="user_settings_list_item_logout" :title="$t('General_logout')" :prepend-icon="icons['logout']" @click="logout()" />
					</v-list>
				</v-menu>
			</v-container>
		</template>
	</v-navigation-drawer>
</template>

<script setup>
	import AList from '@/components/atoms/AList.vue'

	import { reactive, computed } from 'vue'
	import { useGeneral } from '@/hooks'

	const { $t, $router, $route, $store, icons, loading, locale, setLocale } = useGeneral()

	const state = reactive({
		rail: true,
		currentRoute: ''
	})

	function init() {
		state.currentRoute = $route.name
	}
	init()

	const activeRoute = computed(() => $route.name.replace('_edit', '').replace('_copy', ''))

	const railButtonIcon = computed(() => (state.rail ? icons['chevronRight'] : icons['chevronLeft']))
	function toggleRail() {
		state.rail = !state.rail
	}

	// * Menu
	const menuItemSize = computed(() => (state.rail ? 40 : ''))
	const menuItems = computed(() => $store.getters['roles/MENU_SIDEBAR'])
	function getColor(item) {
		return item.route === activeRoute.value ? 'primary' : ''
	}
	function getVariant(item) {
		return $route.name === item.route ? 'contained-text' : 'text'
	}

	// * User
	const user = computed(() => $store.state.auth.user)
	const userInitials = computed(() => {
		let initials = ''
		const person_name = user.value.person_name || ''
		const array = person_name.split(' ')
		if (array.length) initials = array.map(e => e[0]).join('')
		return state.rail ? initials : person_name
	})
	const userButtonText = computed(() => (state.rail ? userInitials.value : user.value.person_name))

	function logout() {
		$store.dispatch('auth/logout')
	}

	// * Router
	function changeRoute(item) {
		if (item.tab) {
			$router.push({ name: item.route, params: { tab: item.tab } })
		} else {
			const route = item.route.toUpperCase()
			const items = $store.getters[`roles/MENU_${route}`]
			const tab = items[0].tab
			$router.push({ name: item.route, params: { tab } })
		}
	}

	const locales = computed(() => $store.getters['GET_LOCALES'])
	const varLocales = computed(() => $store.getters['GET_VAR_LOCALES'])
	const localesShortand = computed(() => $store.getters['GET_LOCALES_SHORTAND'])
	// TODO: Fix .value.value??
	const localeText = computed(() => (state.rail ? localesShortand.value[locale.value.value] : varLocales.value[locale.value.value]))

	function onClickLocale(lang) {
		setLocale(lang)
		const newUser = Object.assign({}, user.value)
		newUser['_locale'] = lang
		const body = {
			person_id: newUser.person_id,
			_item: newUser,
			_keys: ['_locale']
		}
		$store.commit('auth/SET_USER', newUser)
		$store.dispatch('persons/update', body)
	}
</script>
