<template>
	<v-row class="font-weight-bold text-success pa-6">
		<span class="cursor-pointer align-center d-flex" @click="onClick()">
			<v-icon class="mr-2" :icon="icons['create_alt']" />
			{{ $t('Action_add') }}
			<span v-if="$props.element" class="text-lowercase">&nbsp;{{ $t($props.element) }}</span>
		</span>
	</v-row>
</template>

<script setup>
	import { defineEmits, defineProps } from 'vue'
	import { useGeneral } from '@/hooks'

	const $emit = defineEmits(['click'])
	const $props = defineProps({
		element: { type: String, default: '', required: true }
	})

	const { $t, icons } = useGeneral()

	function onClick() {
		$emit('click')
	}
</script>
