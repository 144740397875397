<template>
	<span>
		<AInputLabel v-if="label && !$props.cellEditor" :label="label" :required="$props.required" />
		<v-text-field
			:id="id"
			v-model.number="state.item"
			:type="inputType"
			:color="baseColor"
			:bg-color="bgColor"
			:variant="variant"
			:density="density"
			:rules="rules"
			:loading="$props.loading"
			:disabled="$props.disabled"
			:prefix="$props.prefix"
			:suffix="$props.suffix"
			:hint="$props.hint"
			:persistent-hint="!!$props.hint"
			:placeholder="$props.placeholder"
			:prepend-inner-icon="$props.prependIcon"
			:append-inner-icon="$props.appendIcon"
			:autofocus="$props.celllEditor"
			:hide-details="hideDetails"
			@update:modelValue="onUpdateValue()"
			@input="onInput($event)"
			@change="onChange()"
			@blur="onBlur()"
			@focus="onFocus()"
			@keypress="keypressDisabled()"
			@keydown.enter="onEnter()"
		>
			<template v-slot:message="{ message }">
				<span v-html="message"></span>
			</template>
		</v-text-field>

		<!-- Value -->
		<div :id="$props.id + '_value'" class="d-none" v-text="state.item" />
	</span>
</template>

<script setup>
	import AInputLabel from './AInputLabel.vue'
	import { defineEmits, defineProps, computed, reactive, watch } from 'vue'
	import { useGeneral, useInput } from '@/hooks'
	import { Decimal } from 'decimal.js'
	import * as UTIL from '@/util'

	const $emit = defineEmits(['update:modelValue', 'input', 'change', 'blur', 'clear', 'enter'])
	const $props = defineProps({
		modelValue: { type: [Number, Object] },
		keyName: { type: String, default: '' },
		id: { type: String, default: '' },
		label: { type: String, default: '' },
		hint: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		prefix: { type: String, default: '' },
		suffix: { type: String, default: '' },
		prependIcon: { type: String, default: '' },
		appendIcon: { type: String, default: '' },
		type: { type: String, default: '' },
		minLength: { type: Number, default: null },
		maxLength: { type: Number, default: null },
		required: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		hideDetails: { type: Boolean, default: false },
		cellEditor: { type: Boolean, default: false },
		equalTo: { type: [String, Number], default: null }
	})

	const state = reactive({
		item: null
	})

	const { checkProxyValue } = useGeneral()
	const { id, label, focused, baseColor, bgColor, variant, density, rules } = useInput($props, state)

	watch(
		() => $props.modelValue,
		val => (state.item = checkProxyValue(val, null))
	)

	// const formatedNumber = computed(() => UTIL.formatedNumber(state.item))
	// const inputType = computed(() => (focused.value ? 'number' : 'text'))
	// const inputValue = computed(() => (focused.value ? state.item : formatedNumber.value))
	const inputType = computed(() => 'number')
	const inputValue = computed(() => state.item)

	// watch(state.item, val => {
	// 	if (!val && val !== 0) {
	// 		state.item = 0
	// 		$emit('input', state.item)
	// 	}
	// })

	function init() {
		const value = checkProxyValue($props.modelValue, null)
		const item = value || value === 0 ? Number(new Decimal(value || 0).toDP(3, 0)) : value
		state.item = item
	}
	init()

	function getValue(event) {
		const val = event?.data || event || state.item
		const value = val ? Number(val) : null
		return value || value === 0 ? Number(new Decimal(value || 0).toDP(3, 0)) : value
	}

	function onUpdateValue(event) {
		const value = getValue(event)
		state.item = value
		$emit('update:modelValue', state.item)
	}
	function onInput() {
		onUpdateValue()
		$emit('input', state.item)
	}
	function onChange() {
		onUpdateValue()
		$emit('change', state.item)
	}
	function onBlur() {
		onUpdateValue()
		$emit('blur', state.item)
		focused.value = false
	}
	function onFocus() {
		focused.value = true
	}
	function onEnter() {
		$emit('enter', state.item)
	}
	function keypressDisabled(event) {
		const keys = []
		// Dictionary Validations
		// TODO: Add dictionary validation
		// const type = this.dictionaryValidations.type
		const type = 'integer'
		if (type === 'integer') {
			keys.push('.')
			UTIL.keypressDisabled(event, keys)
		}
	}
</script>
